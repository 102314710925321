/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import { connect } from 'react-redux';
import deps from 'dependencies';
import React, { Component, Fragment } from 'react';
import ReactHtmlParser from 'react-html-parser';


/**
 * -----------------------------------------------------------------------------
 * Functional Component: StubMessage CMS
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
    return{
        ...state['StubBoxCms'],
        ...props,
    };
};

const mapDispatchToProps = (dispatch, props) => ({
    mount: () => dispatch(deps.actions.StubBoxCms.mount()),
});

class StubBoxCms extends Component{
    constructor(props){
        super(props);
        this.state = {
            ...this.props,
		};
		logger.log('[StubBoxCms] constructor - props:%o', props);
    }

    componentDidMount(){
        this.props.mount();
    }

    render(){
        
        logger.log('[StubBoxCms] render - state:%o, props:%o', this.state, this.props);
        let stubData = [];
        if(this.props.stubPages){
            stubData = this.props.stubPages[this.props.page];
        }
        
        return (
            stubData.stub == "stub"  ?
                <Fragment>
                    <section className={`page-content stub ${this.props.page}`}>
                        <section className="page-grid cols-1">
                            <div className="hero">
                                <div className="stub_message">
                                    {
                                        <Fragment>
                                            <div className="stub_title">{ReactHtmlParser(stubData.title)}</div>
                                            <div className="stub_date">{ReactHtmlParser(stubData.text)}</div>
                                        </Fragment>
                                    }
                                </div>
                            </div>
                        </section>
                    </section>
                </Fragment>            
            : null
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(StubBoxCms);
/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import Template from 'components/Template';
import LoadingIndicator from 'shared/general/LoadingIndicator';
import Button from 'shared/general/Button';
import VisitButton from 'shared/general/VisitButton';
import LogoutButton from 'shared/general/LogoutButton';
import EventsLink from 'shared/general/EventsLink';
import CmsMessageContent from 'shared/general/CmsMessageContent';
import ContentHero from 'shared/cms/ContentHero';
import op from 'object-path';
import isEmpty from 'lodash/isEmpty';
import { getApplicationPhase, getHomePage, openEmailWindow, getPaymentSteps} from 'shared/general/Util';
// import KeepNotifiedForm from 'shared/forms/KeepNotifiedForm';
import { getQuerystringValues } from 'appdir/components/general/Util';
import axios from 'axios';
import MeasurementUtils from 'appdir/lib/analytics';
import SystemErrorContent from 'shared/general/SystemErrorContent';
import DeleteAccountForm from 'shared/forms/DeleteAccountForm';
import VerifyIdentityForm from 'shared/forms/VerifyIdentityForm';
import CalendarButton from 'shared/general/CalendarButton';

import StepIndicator from 'shared/general/StepIndicator';


/**
 * -----------------------------------------------------------------------------
 * React Component: ConfirmationContent
 * -----------------------------------------------------------------------------
 */
export default class ConfirmationContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			emailValidated: false,
			mounted: false,
		};

		this.onLogout = this.onLogout.bind(this);
		this.completeEmailCalled = false;

		this.parsedQs = getQuerystringValues();

		logger.log('[ConfirmationContent] - constructor this:%o', this);
	}

	componentDidMount() {
		logger.log('[ConfirmationContent] - componentDidMount this:%o', this);
		if (this.props.siteConfigStatus == 'loaded') {
			this.setState({
				mounted: true,
			});
		} else {
			this.props.loadConfig();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log('[ConfirmationContent] - componentDidUpdate this:%o', this);
		if (prevProps.siteConfigStatus !== this.props.siteConfigStatus && this.props.siteConfigStatus == 'loaded') {
			this.setState({
				mounted: true,
			});
		}

		if (prevState.mounted !== this.state.mounted) {
			MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
				pageTitle: this.getMeasurementTitle(),
				userID: this.props.userData.userID,
				site: this.props?.site,
			});
		}

		if (
			this.state.mounted &&
			!this.state.emailValidated &&
			this.props?.ticketsData?.completeEmailChange &&
			this.parsedQs.emailAddressChangeToken &&
			!this.completeEmailCalled
		) {
			this.completeEmailCalled = true;
			this.props.logout();
			//this.props.completeEmailChange(this.parsedQs.emailAddressChangeToken);

			let url = this.props.ticketsData.completeEmailChange;
			axios({
				method: 'post',
				url: `${url}token=${this.parsedQs.emailAddressChangeToken}`,
				data: {
					provider: this.props.ticketsData.provider,
				},
			})
				.then(resp => {
					logger.log('[ConfirmationContent] componentDidUpdate -  completeEmailChange resp:%o', resp);
					if (resp.status == 200) {
						this.setState({
							emailValidated: true,
							error: false,
						});
					} else {
						this.setState({
							emailValidated: false,
							error:
								'Validation of your new email address has failed.  It is possible you have already completed the validation process.  Please try logging in with your new email.',
						});
					}
				})
				.catch(e => {
					logger.log('[ConfirmationContent] componentDidUpdate -  completeEmailChange Error:%o', e);
					this.setState({
						emailValidated: false,
						error:
							'Validation of your new email address has failed.  It is possible you have already completed the validation process.  Please try logging in with your new email.',
					});
				});
		}
    // only do for logged in users
    else if (
    	this.state.mounted &&
		this.props?.userData && 
      	!this.state.tempPatronData
    ) {
        if (this.props?.site == 'masters') {
            // secure token, site name, site url
          this.checkPatronData(this.props?.userData?.secureJwt, 'anwa', this.props?.ticketsData?.anwaAppUrl);
          this.checkPatronData(this.props?.userData?.secureJwt, 'dcp', this.props?.ticketsData?.dcpAppUrl);
        }
        if (this.props?.site == 'anwa') {
          this.checkPatronData(this.props?.userData?.secureJwt, 'masters', this.props?.ticketsData?.mastersAppUrl);
          this.checkPatronData(this.props?.userData?.secureJwt, 'dcp', this.props?.ticketsData?.dcpAppUrl);
        }
        if (this.props?.site == 'dcp') {
          this.checkPatronData(this.props?.userData?.secureJwt, 'masters', this.props?.ticketsData?.mastersAppUrl);
          this.checkPatronData(this.props?.userData?.secureJwt, 'anwa', this.props?.ticketsData?.anwaAppUrl);
        }
    }
	}

	onLogout(status) {
		logger.log('[ConfirmationContent] - onLogout status%o', status);

		if (status) {
			this.onNotification(status);
		}
		this.props.logout();
	}

	getPageTitle() {
		let category = op.get(this.props, 'Router.params.category', false);
		let action = op.get(this.props, 'Router.params.action', false);
		let applicationPhase = getApplicationPhase(op.get(this.props, 'userData.application'), this.props.eventConfig);

		logger.log(
			'[ConfirmationContent] - getPageTitle category%o, action:%o, applicationPhase:%o',
			category,
			action,
			applicationPhase
		);

		if (action == 'confirmation' && !category) {
			return 'Confirmation';
		} else if (
			action == 'notification' &&
			applicationPhase == 'applicationWinner' &&
			this.props.eventConfig.allowPayment
		) {
			return this?.props?.messageSettings['payment.winner_splash_title'];
		} else if (
			action == 'notification' &&
			applicationPhase == 'applicationWinner' &&
			!this.props.eventConfig.allowPayment
		) {
			return this?.props?.messageSettings['payment.splash_payments_expired_title'];
		} else if (action == 'notification' && applicationPhase == 'applicationLoser') {
			return this?.props?.messageSettings['application.splash_not_selected_title'];
		} else if (action == 'reject' && category == 'terms') {
			return this?.props?.messageSettings['payment.terms_reject_title'];
		} else if (action == 'confirmation' && category == 'optin') {
			return 'Thank You';
		} else if (action == 'confirmation' && category == 'optout') {
			return 'Thank You';
		} else if (category == 'newaccount' && action == 'confirmation') {
			return 'Sign Up Confirmation';
		} else if ((category == 'account' || category == 'application') && action == 'confirmation') {
			return 'Confirmation';
		} else if (category == 'email' && action == 'confirmation') {
			if (this.state.emailValidated) {
				return 'Email Change Confirmation';
			} else if (this.state.error) {
				return 'Email Change Unsuccessful';
			} else {
				return 'Please wait, while we process your email change';
			}
		} else if (category == 'deleteaccount' && action == 'confirmation') {
			return this?.props?.messageSettings['account.delete_prompt_title'];
		} else if (category == 'deleted' && action == 'confirmation') {
			return this?.props?.messageSettings['account.delete_confirmation_title'];
		} else if (category == 'requestdelete' && action == 'confirmation') {
			return this?.props?.messageSettings['account.delete_prompt_title'];
		} else if (category == 'verifyidentity' && action == 'confirmation') {
			return this?.props?.messageSettings['verification.title'];
		}
	}

	onNotification(which) {
		let data = {
			hasViewedLost: which === 'lose' ? true : false,
			hasViewedWin: which === 'win' ? true : false,
		};

		if (which === 'lose') {
			this.props.updateApplicationData(data).then(response => {
				if (response.status == 200) {
					this.setState({
						errors:{}
					})
				} else {
					this.setState({
						errors:{submitError:this?.props?.messageSettings['general.service_error']?.replace("{0}", "N1")}
					})
				}
			}).catch(e => {
				this.setState({
					errors:{submitError:this?.props?.messageSettings['general.service_error']?.replace("{0}", "N2")}
				})
			})
		}

		if (which === 'contact') {
			let to = this.props.messageSettings['account.contact_angc_email_address'];
			let subject = this.props.messageSettings['account.contact_angc_email_subject'];
			let body = [`${this.props.messageSettings['account.contact_angc_email_body']} ${this.props.userData.email}`];

			openEmailWindow(to, subject, body);
		}
	}

	renderPageActions() {
		let category = op.get(this.props, 'Router.params.category', false);
		let action = op.get(this.props, 'Router.params.action', false);
		let applicationPhase = getApplicationPhase(op.get(this.props, 'userData.application'), this.props.eventConfig);
		let homePage = getHomePage(this.props.userData, this.props.eventConfig, this.props.flowData);
		let paymentSteps = getPaymentSteps(this?.props?.userData, this?.props?.messageSettings?.['system.mobile_verification'], this?.props?.eventConfig, this?.props?.requiresVerifyId);

		console.log('category: ' + category);
		console.log('action: ' + action);
		if (isEmpty(this.props.flowData)) {
			return null;
		}
		/* application confirmation */
		if (
			category == 'account' &&
			action == 'confirmation' &&
			(applicationPhase == 'registration' || applicationPhase == 'applicationAdd')
		) {
			return (
				<div className="page-actions">
					<VisitButton type="button" />
					<div className="action-link-container">
						<EventsLink to={homePage} style="action-link">
							View Account Information
						</EventsLink>
					</div>
					<LogoutButton />
				</div>
			);
		} else if (
			category == 'account' &&
			action == 'confirmation' &&
			applicationPhase !== 'registration' &&
			applicationPhase !== 'applicationAdd' &&
			applicationPhase !== 'applicationPaid'
		) {
			return (
				<div className="page-actions">
					<VisitButton type="button" />
					<div className="action-link-container">
						{applicationPhase == 'applicationWinner' ? (
							<EventsLink to={this.props.flowData.login.unpaidWinnerHome} style="action-link">
								View My Application
							</EventsLink>
						) : (
							<EventsLink to={this.props.flowData.actions.notification.application} style="action-link">
								View My Application
							</EventsLink>
						)}
					</div>
					<LogoutButton />
				</div>
			);
		} else if (category == 'account' && action == 'confirmation' && applicationPhase == 'applicationPaid') {
			return (
				<div className="page-actions">
					<VisitButton type="button" />
					<div className="action-link-container">
						<EventsLink to={this.props.flowData.login.paidWinnerHome} style="action-link">
							View My Application
						</EventsLink>
					</div>
					<LogoutButton />
				</div>
			);
		} else if (
			/* new account confirmation - the page that says check email */
			category == 'newaccount' &&
			action == 'confirmation' &&
			op.get(this.props, 'Router.state.email', false)
		) {
			return (
				<div className="page-actions">
					<VisitButton type="button" />
					<div className="action-link-container">
						<EventsLink to={this.props.flowData.actions.general.signin} style="action-link">
							Sign In
						</EventsLink>
					</div>
				</div>
			);
		} else if (
			/* application submit confirmation */
			category == 'application' &&
			action == 'confirmation'
		) {
			return (
        <div className='page-actions-container'>
          <CmsMessageContent id="application.thank_you_for_applying" />
          <div id='confirmation-page-action' className="page-actions">
            <VisitButton type="button" />
            <div className="action-link-container">
              <EventsLink to={this.props.flowData.login.returnUserSubmittedApplication} style="action-link">
                View My Application
              </EventsLink>
            </div>
            <LogoutButton />
          </div>
        </div>
				
			);
		} else if (
			/* Application selected - Winner! */
			category == 'application' &&
			action == 'notification' &&
			!isEmpty(this.props.flowData) &&
			applicationPhase == 'applicationWinner'
		) {
			return (
				<div className="page-actions">
					<Button
						buttonText={this.props.eventConfig.allowPayment ? 'Claim Your Tickets' : 'View My Application'}
						to={
							this?.props?.flowData?.actions?.notification?.[`payment${paymentSteps.page}`]
							// if mobile verify is OFF or int'l user then just go thru the normal claim flow
							// if it is ON and valid country, check to see if they have failed phone verify.
							// this.props?.messageSettings?.['system.mobile_verification'] === "false" 
							// 	|| !this.props.requiresVerifyId[this.props.userData.country] 
							// ? this.props.flowData.actions.notification.claim
							// : this?.props?.userData?.application?.hasAcceptedTicketAgreement 
							// 	&& this?.props?.userData?.addressValidationStatusId == 12
							// 	&& this?.props?.userData?.phoneVerifiedCount > 0
							// 	&& !this?.props?.userData?.datePhoneVerified
							// 	&& this.props.requiresVerifyId[this.props.userData.country] 
							// 	? this.props.flowData.actions.notification.verifyidentity
							// 	: this.props.flowData.actions.notification.claim
						}
						onClick={() => this.onNotification('win')}
					/>
					<LogoutButton />
				</div>
			);
		} else if (
			/* Application not selected - Loser! */
			category == 'application' &&
			action == 'notification' &&
			!isEmpty(this.props.flowData) &&
			applicationPhase == 'applicationLoser'
		) {
			return (
				<div className="page-actions">
					<Button
						buttonText='View My Application'
						onClick={() => this.onNotification('lose')}
						to={this.props.flowData.actions.notification.application}
					/>
					<LogoutButton />
				</div>
			);
		} else if (
			/* payment confirmation*/
			category == 'payment' &&
			action == 'confirmation' &&
			!isEmpty(this.props.flowData) &&
			applicationPhase == 'applicationWinner'
		) {
			return (
				<div className="page-actions">
					<Button buttonText="View My Reciept" to={this.props.flowData.actions.notification.receipt} />
					<div className="action-link-container">
						<EventsLink style="action-link" onClick={() => this.onLogout('win')}>
							Logout
						</EventsLink>
					</div>
					<VisitButton />
				</div>
			);
		} else if (
			/* terms reject */
			category == 'terms' &&
			action == 'reject'
		) {
			return (
				<div className="page-actions">
					<Button buttonText="View Terms" to={this.props.flowData.actions.notification.terms} />
					<div className="action-link-container">
						<EventsLink style="action-link" onClick={() => this.onLogout('win')}>
							Logout
						</EventsLink>
					</div>
					<VisitButton />
				</div>
			);
		} else if (
			/* Notification option */
			(category == 'optout' || category == 'optin') &&
			action == 'confirmation'
		) {
			return (
				<div className="page-actions">
					<Button
						buttonText="Continue"
						onClick={() => this.onNotification('lose')}
						to={this.props.flowData.actions.notification.application}
					/>
					<div className="action-link-container">
						<EventsLink to={this.props.flowData.actions.notification.cancel} style="action-link">
							Cancel
						</EventsLink>
					</div>
				</div>
			);
		} else if (
			/* Email Change Confirmation */
			category == 'email' &&
			action == 'confirmation'
		) {
			return (
				<div className="page-actions">
					{this.props.userData.token ? (
						<>
							<Button
								buttonText="View My Application"
								// if application submitted go to application review
								// if application not submitted go to account review
								// if payment completed go to payment complete?
								to={getHomePage(this.props.userData, this.props.eventConfig, this.props.flowData)}
							/>
							<LogoutButton />
						</>
					) : (
						<Button
							buttonText="Sign In"
							to={this.props.flowData.actions.general.signin}
							style="action-link"
						/>
					)}
				</div>
			);
		} else if (
			/* Delete Account Confirmation */
			category == 'deleted' &&
			action == 'confirmation'
		) {
			return (
				<div className="page-actions">
					<Button buttonText="Return to Masters.com" to={this.props.flowData.actions.general.visit} />
				</div>
			);
		} else if (
			/* Delete Account Request Confirmation */
			category == 'requestdelete' &&
			action == 'confirmation'
		) {
			return (
				<div className="page-actions">
					<Button 
						buttonText="Contact ANGC" 
						onClick={() => this.onNotification('contact')}
						//to={this.props.flowData.actions.notification.application} 
						//to={`mailto:toombsevents+1@gmail.com?subject=Delete account request`} 
					/>
					<div className="action-link-container">
						<EventsLink to={this.props.flowData.actions.notification.requestDeletePrev} style="action-link">
							Previous
						</EventsLink>
					</div>
					<div className="action-link-container">
						<EventsLink to={this.props.flowData.actions.notification.claim} style="action-link">
							Cancel
						</EventsLink>
					</div>
				</div>
			);
		}
	}

	renderPageMessage() {
		let category = op.get(this.props, 'Router.params.category', false);
		let action = op.get(this.props, 'Router.params.action', false);
		let applicationPhase = getApplicationPhase(op.get(this.props, 'userData.application'), this.props.eventConfig);
		let application = this?.props?.userData?.application;
		let paymentSteps = getPaymentSteps(this?.props?.userData, this?.props?.messageSettings?.['system.mobile_verification'], this?.props?.eventConfig, this?.props?.requiresVerifyId);
		logger.log('[renderPageMessage] - paymentSteps:%o', paymentSteps);

		return (
			<>
				{/* application confirmation */}
				{category == 'application' && action == 'confirmation' ? (
					<>
						<CmsMessageContent id="application.confirmation" />
						{this.props.pendingEmail ? (
							<>
								<CmsMessageContent id="account.email_chg_init" />
							</>
						) : null}
            {
			(this.state?.tempPatronData?.masters?.patronId || this.state?.tempPatronData?.anwa?.patronId || this.state?.tempPatronData?.dcp?.patronId ) && (
              <div className='page-actions-container'>
                <CmsMessageContent id="application.apply_for_other_events" />
                <div id='confirmation-page-action' className="page-actions">
                  {this.state?.tempPatronData?.masters?.patronId ? 
                    <Button 
                      buttonText={this?.props?.messageSettings?.['application.access_masters_button']}
                      to={this.props.flowData.actions.general.mastersSiteUrl+`\?email=${this.props?.userData?.email}`} 
                    />
                  : null }
                  {this.state?.tempPatronData?.anwa?.patronId ? 
                    <Button 
                      buttonText={this?.props?.messageSettings?.['application.access_anwa_button']}
                      to={this.props.flowData.actions.general.anwaSiteUrl+`\?email=${this.props?.userData?.email}`} 
                      />
                  : null }
                  {this.state?.tempPatronData?.dcp?.patronId ? 
                    <Button 
                      buttonText={this?.props?.messageSettings?.['application.access_dcp_button']}
                      to={this.props.flowData.actions.general.dcpSiteUrl+`\?email=${this.props?.userData?.email}`} 
                    />
                  : null }
                </div>

                
              </div>
			)
              
            }
					</>
				) : null}

				{/* new account confirmation - the page that says check email */}
				{category == 'newaccount' &&
				action == 'confirmation' &&
				op.get(this.props, 'Router.state.email', false) ? (
					<CmsMessageContent id="account.create.confirmation" data={[this.props.Router.state.email]} />
				) : null}

				{/* registration confirmation */}
				{category == 'account' &&
				action == 'confirmation' &&
				op.get(this.props, 'userData.email', false) &&
				(applicationPhase == 'registration' || applicationPhase == 'applicationAdd') &&
				this.props.site !== 'psb' ? (
					<>
						{
							// show different messaging if it is a bad address and applications are on
							//this.props.invalidStatusIds[this.props.userData.addressValidationStatusId] && applicationPhase == 'applicationAdd'
							this.props.invalidStatusIds[this.props.userData.addressValidationStatusId] // awt:  removed check for applicationAdd
							? <CmsMessageContent id="account.reg_plea_confirmation" />
							: <>
								<CmsMessageContent id="account.regonly_confirmation" />
								{this.renderCalendarButton}
							</>
						}
						
					
						{ // show the pending email if user changed email from what they registered with
							this.props.pendingEmail 
							? <CmsMessageContent id="account.email_chg_init" />
							: null
						}

						{this.props.invalidStatusIds[this.props.userData.addressValidationStatusId] ? (
							<div className="return-error">
								{
									// show different message if applications are on
									// applicationPhase == 'applicationAdd' 
									// ? <CmsMessageContent id="account.invalid_address_plea" />
									// : <CmsMessageContent id="account.invalid_address" />
									
									// awt:  removed check for applicationAdd
									<CmsMessageContent id="account.invalid_address_plea" />
								}
								
							</div>
						) : null}
					</>
				) : null}

				{/* registration update confirmation */}
				{category == 'account' &&
				action == 'confirmation' &&
				op.get(this.props, 'userData.email', false) &&
				((applicationPhase !== 'registration' && 
					applicationPhase !== 'applicationAdd' && 
					this.props.site !== 'psb')  || 
				 (this.props.site == 'psb')) ? (
					<>
						<CmsMessageContent id="account.reg_updated_message" />
						{this.props.pendingEmail ? (
							<>
								<CmsMessageContent id="account.email_chg_init" />
							</>
						) : null}
						{this.props.invalidStatusIds[this.props.userData.addressValidationStatusId] ? (
							<div className="return-error">
								{
									// show different message if applications are on
									// applicationPhase == 'applicationAdd' 
									// ? <CmsMessageContent id="account.invalid_address_plea" />
									// : <CmsMessageContent id="account.invalid_address" />

									// awt:  remove check for applicationAdd
									<CmsMessageContent id="account.invalid_address_plea" />
								}
								
							</div>
						) : null}
						{this.props.userData.addressValidationStatusId == 12 && ["applicationWinner", "applicationPaid"].includes(applicationPhase) ? (
							<div className="return-error"><CmsMessageContent id="payment.coa_homepage_instructions_12" /></div>
						) : null}
					</>
				) : null}

				{/* Winner Notification */}
				{category == 'application' &&
				action == 'notification' &&
				applicationPhase == 'applicationWinner' &&
				this.props.eventConfig.allowPayment 
					? (<>
						<StepIndicator
							type="Winner"
							showWinnerSplash={true}
							currentStep={paymentSteps?.currentStep}
							stepsToComplete={
								this.props.requiresVerifyId[this.props.userData.country] && this?.props?.messageSettings?.['system.mobile_verification'] === "true"
								// country requires verify and verify is on in cms
								? application?.amtDue > 0 
									? this?.props?.messageSettings['payment.splash_instructions'] 
									: this?.props?.messageSettings['payment.splash_instructions_free'] 

								// country does not require verify OR verify is off in cms
								: application?.amtDue > 0
									? this?.props?.messageSettings['payment.splash_instructions_international']
									: this?.props?.messageSettings['payment.splash_instructions_free_international']
								}
						/>
					</>) 
					: null}
				{/* Winner Notification - deadline passed*/}
				{category == 'application' &&
				action == 'notification' &&
				applicationPhase == 'applicationWinner' &&
				!this.props.eventConfig.allowPayment ? (
					<>
						<CmsMessageContent id="payment.splash_payments_expired" />
					</>
				) : null}

				{/* Loser Notification */}
				{category == 'application' && action == 'notification' && applicationPhase == 'applicationLoser' ? (
					<>
						<CmsMessageContent id="application.splash_not_selected" />
					</>
				) : null}

				{/* term reject */}
				{category == 'terms' && action == 'reject' ? (
					<>
						<CmsMessageContent id="payment.terms_reject_instructions" />
					</>
				) : null}

				{/* email confirmation */}
				{category == 'email' && action == 'confirmation' ? (
					<>
						{!this.state.emailValidated && !this.state.error ? <LoadingIndicator /> : null}
						{this.state.emailValidated ? (
							<CmsMessageContent id="account.email_confirm" data={[`you requested`]} />
						) : null}
						{this.state.error ? (
							<CmsMessageContent id="account.email_confirm_failure" data={[`you requested`]} />
						) : null}
					</>
				) : null}

				{/* Delete Account Confirmation */}
				{category == 'deleteaccount' && action == 'confirmation' ? (
					<>
						
						<DeleteAccountForm
							ticketsData={this.props.ticketsData}
							applicationPhase={applicationPhase}
							category={category}
							action={action}
						/>
					</>
				) : null}

				{/* Deleted Account Notification */}
				{op.get(this.props, 'Router.params.category', false) == 'deleted' &&
				op.get(this.props, 'Router.params.action', false) == 'confirmation' ? (
					<>
						<CmsMessageContent id="account.delete_confirmation_instructions" />
					</>
				) : null}

				{/* Delete Account Request Notification */}
				{op.get(this.props, 'Router.params.category', false) == 'requestdelete' &&
				op.get(this.props, 'Router.params.action', false) == 'confirmation' ? (
					<>
						<CmsMessageContent id="account.delete_winner_instructions" />
					</>
				) : null}

        		{/* Verify Identity Flow/Confirmation */}
				{category == 'verifyidentity' && action == 'confirmation' 
					? (<>
						<StepIndicator
							// stepLabels={this?.props?.messageSettings['payment.steps_title']}
							type="winner"
							showWinnerSplash={false}
							stepLabels={this?.props?.messageSettings?.[paymentSteps?.id]}
							//stepLabels={this?.props?.messageSettings['payment.steps_title']}
							currentStep={paymentSteps?.currentStep}
							category={category}
							action={action}
							handleAction={this.handleAction}
						/>
						<VerifyIdentityForm />
					</>) 
					: null}
			</>
		);
	}

	renderCalendarButton() {
		return (<CalendarButton
			data={{
				type: 'CalendarButton',
				name: this.props.messageSettings?.['general.event_reminder_title'],
				description: this.props.messageSettings?.[
					'general.event_reminder_description'
				],
				startDate: moment
					.tz(
						this.props.messageSettings?.[
							'general.event_reminder_startTime'
						],
						'America/New_York'
					)
					.format('YYYY-MM-DD'),
				endDate: moment
					.tz(
						this.props.messageSettings?.[
							'general.event_reminder_endTime'
						],
						'America/New_York'
					)
					.format('YYYY-MM-DD'),
				startTime: moment
					.tz(
						this.props.messageSettings?.[
							'general.event_reminder_startTime'
						],
						'America/New_York'
					)
					.format('HH:mm'),
				endTime: moment
					.tz(
						this.props.messageSettings?.[
							'general.event_reminder_startTime'
						],
						'America/New_York'
					)
					.format('HH:mm'),
				location: this.props.messageSettings?.[
					'general.event_reminder_location'
				],
				label: this.props.messageSettings?.[
					'general.event_reminder_button_label'
				],
				identifier: 'tickets',
				options:this.props.messageSettings?.['general.list_of_email_vendors']?.replace(', ', ',')?.split(',')
			}}
		/>)
	}

	getMeasurementTitle() {
		let category = op.get(this.props, 'Router.params.category', '').replace(/^\w/, c => c.toUpperCase());
		let action = op.get(this.props, 'Router.params.action', '').replace(/^\w/, c => c.toUpperCase());

		return category + action;
	}

	handleAction(which) {
		MeasurementUtils.dispatchMeasurementCall(this.getMeasurementTitle(), {
			action: which,
			userID: this.props.userData.userID,
			site: this.props?.site,
		});
	}

  checkPatronData = (jwt, site, siteUrl) => {
    logger.log('[ConfirmationContent] checkPatronData:%o');

    axios({
      method: 'get',
			url: siteUrl,
			headers: {Authorization: `Bearer ${jwt}` },
    })
    .then((response) => {
      if (response.status == 200) {
        logger.log('[ConfirmationContent] checkPatronData - response:%o', response.data);

        this.setState({
          tempPatronData: {
            ...this.state.tempPatronData,
            [site]: response.data
          },
        })
      }
    })
    .catch((error) => {
      logger.error('[ConfirmationContent] checkPatronData error:%o', error);
    })
  }

	render() {
		logger.log('[ConfirmationContent] render - this:%o', this);
		let applicationPhase = getApplicationPhase(op.get(this.props, 'userData.application'), this.props.eventConfig);
		let category = op.get(this.props, 'Router.params.category', false);
		let action = op.get(this.props, 'Router.params.action', false);
		let paymentSteps = getPaymentSteps(this?.props?.userData, this?.props?.messageSettings?.['system.mobile_verification'], this?.props?.eventConfig, this?.props?.requiresVerifyId);
		return (
			<Template>
				{
					this.props.siteConfigStatus == 'loaded' && this.props.messageSettings ? (
					<section id="account-confirmation" className="page-content">
					<section className="page-title">
						<h1>
							<CmsMessageContent id="general.page_header_title" />
						</h1>
					</section>
					<section className="hero">
						<ContentHero />
					</section>
					{category !== 'uhOh' ? (
						<>
							<section className="section-title-wrapper">
								<h2>{this.getPageTitle()}</h2>
							</section>
							<section className="page-grid cols-1">
								<div className="page-grid-item">
									{this.props?.flowData ? (
										<>
											{this.renderPageMessage()}
											{this.renderPageActions()}
										</>
									) : null}
								</div>
							</section>
						</>
					) : (
						<SystemErrorContent data={{ ...this?.props?.Router?.state?.data }} />
					)}
				</section>
					) : null
				}
				
			</Template>
		);
	}
}

ConfirmationContent.defaultProps = {};

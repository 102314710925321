import React, { Component, Fragment } from 'react';
import ProgressNavigation from './progressNavigation';

const mapStateToProps = (state, props) => ({
	...state.StepIndicator,
  site: state?.Config?.dataSettings?.site,
	...props,
});

class StepIndicator extends Component{
  constructor(props) {
    super(props);    
    logger.log('[StepIndicator] props:%o', this.props);
    this.state = {
			completed: false,
			isActive: false,
      currentStep: this.props?.currentStep,
		};
  }

  componentDidUpdate(prevProps, prevState) {
    logger.log('[StepIndicator] componentDidUpdate:%o', this.props, prevProps);
    if(prevProps.currentStep !== this.props.currentStep){
      this.updateStep;
    }
  }
//cat-terms act-agree
  updateStep = (step) => {
    //if(this.props.category == "payment" && this.props.action == "review")
    this.setState({
      currentStep: step,
    })
  }
  
  render() {
    logger.log('[StepIndicator] render - this.props: %o, this.state',this.props, this.state);

    if (this.props?.site == 'psb') {
      return null
    } else {
      return(
        <>
          {(this.props.showWinnerSplash == true) ? 
            <div className={`stepSplash step${this?.props?.currentStep}`} data-step={this?.props?.currentStep}>
              <ProgressNavigation 
                stepLabels={this.props.stepsToComplete}
                showWinnerSplash={this.props?.showWinnerSplash}
                currentStep={this.props.currentStep}
              />
            </div>
          : 
          <div className="stepsOverview">
            <ProgressNavigation 
              stepLabels={this.props?.stepLabels}
              currentStep={this.props.currentStep}
              paymentSteps={this.props.paymentSteps}
            />
          </div>}
        </>
      )
    }
  }
}

export default StepIndicator;
/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import ApplicationContent from './ApplicationContent';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';

/**
 * -----------------------------------------------------------------------------
 * React Component: ApplicationContent
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state.ApplicationContent,
		eventConfig: op.get(state['Config'], 'eventConfig.data', {}),
		eventDays: op.get(state['Config'], 'application.days'),
		ticketsData: op.get(state['Config'], 'ticketsData'),
		userData: op.get(state['UserData'], 'userData', {}),
		flowData: op.get(state['Config'], 'staticData.flow.data', {}),
		pageTitle: state?.Config?.otherData?.pageTitle,
		site: state?.Config?.dataSettings?.site,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	loadConfig: () => dispatch(deps.actions.Config.loadConfig()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationContent);

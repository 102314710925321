/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { Router } from 'react-router';
import { checkJwtStatus } from './Util';
import Cookies from 'js-cookie'

/**
 * -----------------------------------------------------------------------------
 * React Component: UserData
 * -----------------------------------------------------------------------------
 */
export default class UserData extends Component {
	constructor(props) {
		super(props);

		this.state= {};
		logger.log('[UserData] constructor - props:%o', this.props);
		this.init = false;
		this.profileTimer = null;
	}

	componentDidMount() {
		logger.log('[UserData] componentDidMount - props:%o', this.props);
		this.props.mount();
	}

	componentWillUnmount() {
		let domain = location.href.indexOf('localhost') == -1 ? 'masters.com' : 'localhost';
		//let domain = location.href.indexOf('localhost') == -1 ? this?.props?.dataSettings?.siteDomain : 'localhost';
		Cookies.remove('tickets-auth', {domain});
	}

	componentDidUpdate(prevProps) {
		//logger.log('[UserData] componentDidUpdate - prevProps:%o, props:%o', prevProps, this.props);'

		let isHomePage = this.props?.Router?.pathname == '/' || this.props?.Router?.params?.homePage ? true : false;

		if (!this.init && this.props.flowData.actions) {
			this.init = true;
			if (!this.props?.userData?.token) {
				logger.log('[UserData] componentDidUpdate - init, go to signin page');
				this.handleRedirect()
			} 
			// if user jumps from patron to non patron page and vice versa, log them out
			else if (this.props?.userData?.token && (
				(this.props.userData.eventName == 'psb' && this.props.Router.pathname.indexOf('patron') == -1) ||
				(this.props.userData.eventName !== 'psb' && this.props.Router.pathname.indexOf('patron') !== -1)
			)
			) {
				this.props.logout();
			} 
			else if (this.props?.userData?.token && checkJwtStatus(this.props?.userData?.token, this.props.threshold) !== false) {
				logger.log('[UserData] componentDidUpdate - init, jwtStatus: refresh or expired');
				this.props.updateUserData(this.props.userData)
			}
		} else if (this.init) {
			// check if route changed
			if (prevProps.Router.pathname !== this.props.Router.pathname) {
				logger.log('[UserData] componentDidUpdate - route changed %o', this.props.Router);

				// if new path jumps from patron to non-patron or vice-versa, log the user out
				if ((prevProps.Router.pathname.indexOf('patron') !== -1 && this.props.Router.pathname.indexOf('patron') == -1) || 
					(prevProps.Router.pathname.indexOf('patron') == -1 && this.props.Router.pathname.indexOf('patron') !== -1)
				) {
					this.props.logout();
				}

				// if user is logged in, then check if token is expired or needs refresh
				if (this.props?.userData?.token && checkJwtStatus(this.props?.userData?.token, this.props.threshold) !== false) {
					logger.log('[UserData] componentDidUpdate - route changed, jwtStatus: refresh or expired');

					// call updateUserData because that action handles the actual token refresh and updates userData
					this.props.updateUserData(this.props.userData)
				} 
				// if user is not logged in, then if not home page or a content page, then log user out.
				// user shouldn't be on any application pages without a token
				else if (!this.props.userData?.token) {
					this.handleRedirect();
				}
			// if route hasn't changed and we are not on home page or content page
			// check for token is expired or needs refresh
			} else if (!isHomePage && this.props?.Router?.params?.contentCategory) {
				if (this.props?.userData?.token && checkJwtStatus(this.props?.userData?.token, this.props.threshold) !== false) {
					// call updateUserData because that action handles the actual token refresh and updates userData
					logger.log('[UserData] componentDidUpdate - reload page, jwtStatus: refresh or expired');
					this.props.updateUserData(this.props.userData)
				}
			}

			// check if user has logged out
			if (prevProps.userData?.token !== this.props?.userData?.token && !this.props.userData.token) {
				this.handleRedirect();
			}
		}
	}

	handleRedirect() {
		//let isHomePage = window.location.href.indexOf('/en_US/index.html') !== -1;
		let isHomePage = this.props?.Router?.pathname == '/' || this.props?.Router?.params?.homePage ? true : false;
		let action = this.props?.Router?.params?.action;
		let category = this.props?.Router?.params?.category;
		
		logger.log('[UserData] handleRedirect - isHomePage:%o', isHomePage);

		if (!isHomePage && 
			!this.props?.Router?.params?.contentCategory && 
			action !== 'agree' && 
			category !== 'newaccount' &&
			category !== 'password' &&
      		category !== 'magiclink' &&
			category !== 'uhOh' && 
			category !== 'email' &&
			((category == 'account' && action == 'confirmation') || 
			 (category == 'application' && action == 'confirmation') ||
			 (category !== 'verifyIdentiy')
			)
		) {
			logger.log('[UserData] handleRedirect - only redirect if not homepage or content page: Router:%o', this.props.Router);
			window.location.replace(this.props.flowData.actions.general.logout);
		}
	}

	render() {
		//logger.log('[UserData] componentDidUpdate this:%o', this);
		return null	;	
	}
}

import StepIndicator from './StepIndicator';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';

/**
  * -----------------------------------------------------------------------------
  * React Component: Steps Indicator
  * -----------------------------------------------------------------------------
  */


 const mapStateToProps = (state, props) => {
  return {
    ...state.StepIndicator,
    siteConfigStatus: state['Config'].status,
    userData: op.get(state['UserData'], 'userData', {}),
    flowData: op.get(state['Config'], 'staticData.flow.data', {}),
    site: state?.Config?.dataSettings?.site,
    Router: state['Router'],
    messageSettings: op.get(state['Config'], 'staticData.cmsMessages.data'),
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  loadConfig: () => dispatch(deps.actions.Config.loadConfig()),
});

export default connect(mapStateToProps, mapDispatchToProps)(StepIndicator);
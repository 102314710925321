/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
 import React, { Component } from 'react';
 import { connect } from 'react-redux';
 import Template from 'components/Template';
 import deps from 'dependencies';
 import ContentHero from 'shared/cms/ContentHero';
 import LogInView from 'shared/forms/LogInView';
 import op from 'object-path';
 import MeasurementUtils from 'appdir/lib/analytics';
 import CmsMessageContent from 'shared/general/CmsMessageContent';
 import LoadingIndicator from 'shared/general/LoadingIndicator';

 /**
 * -----------------------------------------------------------------------------
 * React Component: HomeContent
 * -----------------------------------------------------------------------------
 */

export default class HomeContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			mounted: false,
		};
	}

	componentDidMount() {
		logger.log('[Home] - componentDidMount this.props:%o', this.props);

		this.props.logout();

		if (this.props.configStatus !== 'loaded') {
			this.props.loadConfig();
		} else {
			this.setState({
				mounted: true,
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log('[Home] - componentDidUpdate state:%o', this.state);

		// check config status to see if config_web is loaded, sometimes we kick it off in componentDidMount
		if (prevProps.configStatus !== this.props.configStatus && this.props.configStatus == 'loaded') {
			//logger.log('[Home] - componentDidUpdate configStatus:%o', this.props.configStatus);

			this.setState({
				mounted: true,
			});
		}

		// metrics pageView
		if (this.state.mounted !== prevState.mounted && this.state.mounted == true) {
			if (this.props.site !== 'psb' && this.props?.EventsWindow?.numericWindowSize > 1) {
				MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
					pageTitle: 'Home',
					currentView: 'full',
					site: this.props.site,
				});
			} else {
				MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
					pageTitle: 'Home',
					currentView: 'login',
					site: this.props.site,
				});
			}
		}
	}

	getLoadingIndicator = () => {
		return (
			<div className="fadeOverlay" ref={this.loadingRef}>
				<LoadingIndicator />
			</div>
		);
	};

	render() {
		logger.log('[Home] render this:%o, window.commit:%o', this, window.commit);

		if (this.props.configStatus == 'loaded' && this.props?.EventsWindow?.numericWindowSize >= 0) {
			let basePicPath = window.commit == 'local' ? '' : op.get(this.props, 'otherData.basePicPath', '');
			let imageConfig = {
				useLocal: window.commit == 'local',
				image: {
					alt: '',
					//background: true,
					//className: "hero-bkg",
					//style: {height: "calc(100% - 0px)", backgroundImage: "url(http://www.masters.com/images/pics/large/h_DM23730.jpg)"}
				},
				imageList: {
					default: `${basePicPath}/assets/images/headers/header_patron_qtr.jpg`,
					large: `${basePicPath}/assets/images/headers/header_patron_qtr.jpg`,
					medium: `${basePicPath}/assets/images/headers/header_patron_qtr.jpg`,
					small: `${basePicPath}/assets/images/headers/header_patron_sm.jpg`,
				},
			};

			return (
				<Template>
					<section className="page-content">
						<section className="page-title">
							<h1><CmsMessageContent id="general.page_header_title" textOnly={true} /></h1>
						</section>
						<ContentHero heroType="quarter-height" imageConfig={imageConfig}></ContentHero>
						<section className="page-grid cols-1">
							<LogInView ticketsData={this.props.ticketsData} flowData={this.props.flowData} />
						</section>
					</section>
				</Template>
			);
		} else {
			return (
				this.getLoadingIndicator()
			);
		}
	}
}

HomeContent.defaultProps = {};

import ForgotPassword from './index';

export default [
	{
		path: ['/:lang(en_US)/:category(password)/:action(change).html'],
		exact: true,
		component: ForgotPassword,
	},

	{
		path: ['/:site(patron)/:category(password)/:action(change).html'],
		exact: true,
		component: ForgotPassword,
	},
];

import React from 'react';
import EventsLink from 'shared/general/EventsLink';

/**
 * -----------------------------------------------------------------------------
 * React Component: Image
 * render image for content pages
 * -----------------------------------------------------------------------------
 */

export default ({ data = {} }) => {
	// const { url, caption, credit } = data;
	// debugger;
	const centerStyle = data.style === 'center' ? 'text centered' : '';
	return (
		<div className={centerStyle}>
			<div className={`containerPhoto ${data.style ? data.style : ''}`}>
				<EventsLink
					to={data.link}
					external={data.externalLink ? true : false}>
					<img src={data.image.url} alt={data.image.caption} />
				</EventsLink>
				<div className="caption-credit">
				 <div className="caption">{data.image.caption}</div>
				 <div className="credit">{data.image.credit}</div>
				</div> 				
			</div>
		</div>
	);
};

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import Template from 'components/Template';
import MeasurementUtils from 'appdir/lib/analytics';
import ContentHero from 'shared/cms/ContentHero';
import CmsMessageContent from 'shared/general/CmsMessageContent';
import Button from 'shared/general/Button';
import EventsLink from 'shared/general/EventsLink';
import op from 'object-path';
import { getApplicationPhase, getHomePage } from 'shared/general/Util';

/**
 * -----------------------------------------------------------------------------
 * React Component: CancelRegistrationContent
 * -----------------------------------------------------------------------------
 */
export default class CancelRegistrationContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			mounted: false,
		};

		this.successURLUpdated = false;
		this.handleAction = this.handleAction.bind(this);
	}

	componentDidMount() {
		logger.log('[Confirmation] - componentDidMount this%o', this);

		if (this.props.siteConfigStatus == 'loaded' && this.props.flowStatus == 'loaded') {
			this.setState({
				mounted: true,
				successURL: this.props?.flowData?.actions?.general?.signin,
			});
		} else {
			this.props.loadConfig();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		// logger.log('[CancelRegistrationContent] componentDidUpdate - this:%o', this);
		let category = op.get(this.props, 'match.params.category', false);

		if (prevProps.flowStatus !== this.props.flowStatus && this.props.flowStatus == 'loaded') {
			this.setState({
				mounted: true,
			});
		}

		if (prevState.mounted !== this.state.mounted && this.state.mounted == true) {
			MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
				pageTitle: category == 'registration' ? 'CancelRegistration' : 'CancelApplication',
				userID: this.props.tempUserData.userID,
				site: this.props.tempUserData.eventName,
			});
			if (this.props.flowData.login) {
				this.setState({
					successURL: this.props?.flowData?.actions?.general?.signin,
				});
			}
		}

		if (!this.successURLUpdated && op.get(this.props, 'Router.historyArray')) {
			// logger.log('[CancelRegistrationContent] componentDidUpdate - historyArray:%o', this.props.Router.historyArray);
			this.successURLUpdated = true;
			let found;

			let temp = this.props.Router.historyArray.reverse();

			found = temp.find(function(el) {
				return el.indexOf('/cancel') === -1;
			});

			logger.log('[CancelRegistrationContent] componentDidUpdate - historyArray reversed:%o, found:%o', temp, found);

			if (found) {
				this.setState({
					successURL: found,
				});
			}
		}
	}

	resetUserData() {
		this.props.updateUserData({
			...this.props.tempUserData,
			jwt: this.props.userData.jwt,
			refreshToken: this.props.userData.refreshToken,
			secureJwt: this.props.userData.secureJwt,
			secureRefreshToken: this.props.userData.secureRefreshToken,
			token: this.props.userData.token,
		});
	}

	renderPageActions() {
		let applicationPhase = getApplicationPhase(op.get(this.props, 'userData.application'), this.props.eventConfig);
		let homePage = getHomePage(this.props.userData, this.props.eventConfig, this.props.flowData);
		logger.log('[CancelRegistrationContent] renderPageActions homePage:%o, applicationPhase', homePage, applicationPhase);

		if (
			/** awt:  if there's no address hash, then cancel should log user out. */
			//(applicationPhase == 'registration' || applicationPhase == 'applicationAdd' || this.props?.userData?.application?.applicationStatus !== 0) &&
			(!this.props?.tempUserData?.addressHash || this.props?.tempUserData?.addressHash == '') &&
			this.props.eventConfig.eventName !== 'psb'
		) {
			return (
				<div className="action-link-container">
					<EventsLink
						style="action-link"
						onClick={() => {
							this.handleAction('Yes');
							this.props.logout();
						}}>
						Yes
					</EventsLink>
				</div>
			);
		} else if (
			(applicationPhase == 'registration' || applicationPhase == 'applicationAdd') &&
			this.props?.userData?.addressHash
		) {
			return (
				<div className="action-link-container">
					<EventsLink
						style="action-link"
						to={homePage}
						onClick={() => {
							this.handleAction('Yes');
							this.resetUserData();
						}}>
						Yes
					</EventsLink>
				</div>
			);
		} else if (applicationPhase == 'applicationPaid') {
			logger.log('[CancelRegistrationContent] renderPageActions applicationPaid homePage:%o', homePage);

			return (
				<div className="action-link-container">
					<EventsLink
						style="action-link"
						to={homePage}
						onClick={() => {
							this.handleAction('Yes');
							this.resetUserData();
						}}>
						Yes
					</EventsLink>
				</div>
			);
		} else if (applicationPhase == 'registration' && this.props.eventConfig.eventName =='psb') {
				logger.log('[CancelRegistrationContent] renderPageActions psb registration homePage:%o', homePage);
	
				return (
					<div className="action-link-container">
						<EventsLink
							style="action-link"
							to={homePage}
							onClick={() => {
								this.handleAction('Yes');
								this.resetUserData();
							}}>
							Yes
						</EventsLink>
					</div>
				);
		} else if (applicationPhase !== 'registration') {
			return (
				<div className="action-link-container">
					<EventsLink
						style="action-link"
						to={homePage}
						onClick={() => {
							this.handleAction('Yes');
							this.resetUserData();
						}}>
						Yes
					</EventsLink>
				</div>
			);
		} else {
			return (
				<div className="action-link-container">
					<EventsLink
						style="action-link"
						onClick={() => {
							this.handleAction('Yes');
							this.props.logout();
						}}>
						Yes
					</EventsLink>
				</div>
			);
		}
	}

	handleAction(which) {
		let category = op.get(this.props, 'match.params.category', false);

		MeasurementUtils.dispatchMeasurementCall(
			category == 'registration' ? 'CancelRegistration' : 'CancelApplication',
			{
				action: which,
				userID: this.props.tempUserData.userID,
				site: this.props.tempUserData.eventName,
			}
		);
	}

	render() {
		logger.log('[CancelRegistrationContent] render this:%o', this);
		let category = op.get(this.props, 'Router.params.category', false);
		let applicationPhase = getApplicationPhase(op.get(this.props, 'userData.application'), this.props.eventConfig);

		return (
			<Template>
				<section className="page-content">
					<section className="page-title">
						<h1><CmsMessageContent id="general.page_header_title" textOnly={true} /></h1>
					</section>
					<section className="hero">
						<ContentHero />
					</section>
					<section className="section-title-wrapper">
						{category == 'registration' ? (
							<h2>
								{this.props.eventConfig.eventName !== 'psb' && applicationPhase == 'registration'
									? 'Cancel Registration'
									: 'Cancel Account Update'}
							</h2>
						) : (
							<h2>Cancel Application</h2>
						)}
					</section>

					<section className="page-grid cols-1">
						<div className="page-grid-item">
							<CmsMessageContent id="account.cancel_account_instructions" />

							<div className="page-actions centered">
								<Button
									buttonText="No"
									onClick={() => this.handleAction('No')}
									to={this.state.successURL}
								/>
								{this.props?.flowData?.login ? this.renderPageActions() : null}
							</div>
						</div>
					</section>
				</section>
			</Template>
		);
	}
}

CancelRegistrationContent.defaultProps = {};

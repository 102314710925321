import Home from './index';

export default [
	{
		path: [
			'/:legacy(dcp)/',
			'/:lang(en_US)/:legacy(create_account).html',
			'/:lang(en_US)/:legacyTickets(ticketsInfo|ticket_info|ticketsFAQ|ticketsPolicies|ticketsPrivacy).html',
		],
		exact: true,
		component: Home,
	},
	{
		path: [
			'/',
			'/:homePage(index).html',
			'/:lang(en_US)/:homePage(index).html',
			'/:lang(en_US)/:homePage(login).html/',
		],
		exact: true,
		component: Home,
	},
];

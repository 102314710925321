import { connect } from 'react-redux';
import MastersImage from './MastersImage';
import deps from 'dependencies';
import op from 'object-path';


const mapStateToProps = state => {
    return {
        ...state['MastersImage'],
        EventsWindow: state['WindowSize'].EventsWindow,
        basePicPath: op.get(state['Config'],'otherData.basePicPath',false)
    };
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.MastersImage.mount()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MastersImage);

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import ConfirmationContent from './ConfirmationContent';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';

/**
 * -----------------------------------------------------------------------------
 * React Component: ConfirmationContent
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state.ConfirmationContent,
		siteConfigStatus: state['Config'].status,
		EventsWindow: op.get(state['WindowSize'], 'EventsWindow', {}),
		userData: op.get(state['UserData'], 'userData', {}),
		pendingEmail: op.get(state['UserData'], 'pendingEmail', false),
		applicationConfig: op.get(state['Config'], 'application'),
		settings: state['Config'].dataSettings,
		flowData: op.get(state['Config'], 'staticData.flow.data'),
		eventConfig: op.get(state['Config'], 'eventConfig.data', {}),
		Router: state['Router'],
		pageTitle: state?.Config?.otherData?.pageTitle,
		ticketsData: state['Config'].ticketsData,
		site: state?.Config?.dataSettings?.site,
		messageSettings: op.get(state['Config'], 'staticData.cmsMessages.data'),
		invalidStatusIds: op.get(state['Config'], 'addressValidation.invalidStatusIds', {}),
        requiresVerifyId: op.get(state['Config'], 'addressValidation.requiresVerifyId', {}),
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	loadConfig: () => dispatch(deps.actions.Config.loadConfig()),
	logout: () => dispatch(deps.actions.UserData.logoutUser()),
	updateApplicationData: data => dispatch(deps.actions.UserData.updateApplicationData(data)),
	//completeEmailChange: token => dispatch(deps.actions.UserData.completeEmailChange(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationContent);

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import deps from 'dependencies';
import axios from 'axios';
import op from 'object-path';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { FormEmail, FormInput, FormConfirmPassword, FormPassword } from 'shared/forms/elements/FormFields';
import Button from 'shared/general/Button';
import { getPlatformID, writeErrors } from 'shared/forms/elements/Utils';
import LoadingIndicator from 'shared/general/LoadingIndicator';
import EventsLink from 'shared/general/EventsLink';
import MeasurementUtils from 'appdir/lib/analytics';
import CmsMessageContent from 'shared/general/CmsMessageContent';


/**
 * -----------------------------------------------------------------------------
 * React Component: CreateAccountForm
 * -----------------------------------------------------------------------------
 */
 const mapStateToProps = (state, props) => {
	return {
		...props,
		staticDataStatus: op.get(state['Config'], 'staticData.cmsMessages.status'),
		messageSettings: op.get(state['Config'], 'staticData.cmsMessages.data'),
		ticketsData: op.get(state['Config'], 'ticketsData',{}),
		site: state?.Config?.dataSettings?.site,
	};
};

const mapDispatchToProps = dispatch => ({
	updateUserData: data => dispatch(deps.actions.UserData.updateUserData(data))
});
class CreateAccountForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			errors: {},
			success: false,
			successRoute: null,
			email: null
		};

		this.windowRef = React.createRef();
		this.loadingRef = React.createRef();

		logger.log('[CreateAccountForm] constructor - props:%o', this.props);
		// logger.log('[CreateAccountForm] window.webview:%o', window.webview);

		this.loadingRef = React.createRef();
		this.firstLoad = true;
	}

	componentWillUnmount() {
		// logger.log('[CreateAccountForm] componentWillUnmount');

		this.setState({
			errors: {},
			success: false
		});
	}

	componentDidUpdate() {
		// logger.log('[CreateAccountForm] componentDidUpdate - state:%o', this.state);
		// logger.log('[CreateAccountForm] componentDidUpdate - this.userAvatar:%o', this.userAvatar);

		if(this.firstLoad) {
			this.firstLoad = false;
			/**
			 * Captcha Ninja - show Captcha even if Config said to hide!
			 * pass param, ?allowCaptcha=true to the URL
			 */
			const queryString = window.location.search;
			const urlParams = new URLSearchParams(queryString);
			this.allowCaptcha = urlParams.get('allowCaptcha');
		}

		if(!this.state.successRoute && op.get(this.props, 'flowData.actions.createAccount.signUp', false)) {
			this.setState({
				successRoute: this.props.flowData.actions.createAccount.signUp
			});
		}
	}

	handleAutoFill(e) {
		// logger.log('[CreateAccountForm] handleAutofill - e:%o', e.currentTarget);

		this.setState({
			[e.target.name]: {
				...this.state[e.target.name],
				active: e.animationName === 'onAutoFillStart' ? true : false,
				autofill: e.animationName === 'onAutoFillStart' ? true : false,
			},
		});
	}

	handleFocus(e, val, formProps) {
		if (formProps) {
			formProps.handleBlur(e);
		}

		if (e.target.value === '') {
			this.setState({
				[e.target.name]: {
					...this.state[e.target.name],
					focus: val,
				},
			});
		} else {
			this.setState({
				[e.target.name]: {
					...this.state[e.target.name],
					focus: true,
				},
			});
		}
	}

	togglePassword(which, field) {
		// let measureString = `CreateAccountShowPasswordToggle${which}`;
		let display = this.state[which] ? false : true;

		this.setState({
			[which]: display,
		}, () => {
			MeasurementUtils.dispatchMeasurementCall('togglePassword', {
				site: this.props.site,
				show: this.state.password_show,
				field
			});
		});
	}

	getLoadingIndicator = () => {
		return (
			<div className="fadeOverlay" ref={this.loadingRef}>
				<LoadingIndicator />
			</div>
		);
	};

	handleCreateAccount = (values, setSubmitting) => {
		logger.log('[CreateAccount] handleRegistrationSubmit - values:%o', values);
		let captchaSettings = this.props.ticketsData?.captcha;

		let recaptchaValue = '';
		var _this = this;

		/** generate captcha v3 token */
		if ((captchaSettings?.showCaptcha || this.allowCaptcha) && captchaSettings?.recaptchaV3SiteKey) {
			grecaptcha.ready(function() {
				grecaptcha.execute(captchaSettings.recaptchaV3SiteKey, { action: 'submit' }).then(function(token) {
					recaptchaValue = token;
					logger.log('TOKEN:%o', token);
					_this._submitValues(values, recaptchaValue, setSubmitting);
				});
			});
		} else {
			_this._submitValues(values, recaptchaValue, setSubmitting);
		}

		/** show loading indicator while everyone is working hard to create an account */
		if (this.loadingRef.current) {
			this.loadingRef.current.classList.add('show');
		}
	}

	_submitValues = (values, recaptchaValue, setSubmitting) => {
		// logger.log('[CreateAccount] handleRegistrationSubmit - navigator.userAgent:%o', navigator.userAgent);
		logger.log('[CreateAccount] _submitValues - this:%o', this);
		logger.log('[CreateAccount] _submitValues - values:%o, recaptchaValue:%o', values, recaptchaValue);
		let { ticketsData } = this.props;

		let url = ticketsData.registration;

		axios({
			method: 'post',
			url: url,
			data: {
				providerID: ticketsData.providerId,
				platformID: getPlatformID(),
				prefix: "",
				firstName: values.firstName.replace(/[\u2018\u2019\u0091\u0092]/g, "'").replace(/[\u201C\u201D]/g, '"'),
				lastName: values.lastName.replace(/[\u2018\u2019\u0091\u0092]/g, "'").replace(/[\u201C\u201D]/g, '"'),
				email: values.emailAddress,
				password: values.password,
				country: "",
				postalCode: "",
				captcha: recaptchaValue,
			},
		})
			.then(res => {
				logger.log('[CreateAccount] handleSubmit res:%o', res);
				if (res.status == 200) {
					this.setState({
						success: true,
						errors: {}
					}, () => {
						if (this.loadingRef.current) {
							this.loadingRef.current.classList.remove('show');
						}

						let data = {
							email: res.data.email
						}

						this.setState({
							email: res.data.email
						})
						MeasurementUtils.dispatchMeasurementCall('CreateAccountSubmit', {
							response: 'success',
							responseData: res,
							site: this.props.site
						});
					});					
				} else {
					setSubmitting(false);
					this.setState({
						success: false,
						errors: {
							...this.state.errors,
							response: this.props.staticDataStatus == 'loaded' ? this.props.messageSettings['general.service_error']?.replace("{0}","CA1") : 'There was an error creating your account'
						},
					});

					if (this.loadingRef.current) {
						this.loadingRef.current.classList.remove('show');
					}

					MeasurementUtils.dispatchMeasurementCall('CreateAccountSubmit', {
						response: 'failure',
						responseData: res,
						site:  this.props.site
					});
				}
			})
			.catch(error => {
				logger.error('[CreateAccount] handleSubmit create account error:%o', error?.response);

				let errorMessage = this.props.staticDataStatus == 'loaded' && error?.response?.data == '' ? this.props.messageSettings['login.create.account_exists'] : this.props.messageSettings['general.service_error']?.replace("{0}","CA2");
				let errorResponse = op.get(error, 'response.errors', []);
				let isCaptchaError = false;

				if (errorResponse.length > 0) {
					isCaptchaError = errorResponse[0] == 'Invalid Captcha';
					if (isCaptchaError) {
						errorMessage = this.props.messageSettings['general.service_error']?.replace("{0}","CA3"); //op.get(this.props, 'ticketsData.captcha.createAccountCaptchaError', false);
					}
				}

				if (error.response) {
					this.setState({
						success: false,
						errors: {
							...this.state.errors,
							submit: errorMessage,
						}
					});
				} else {
					this.setState({
						success: false,
						errors: {
							...this.state.errors,
							submit: errorMessage,
						}
					});
				}
				

				if (this.loadingRef.current) {
					this.loadingRef.current.classList.remove('show');
				}

				MeasurementUtils.dispatchMeasurementCall('CreateAccountSubmit', {
					response: 'error',
					responseData: error,
					site: this.props.site
				});

				setSubmitting(false);
			});
	}

	render() {
		// logger.log('[CreateAccountForm] render - this: %o', this);
		let passwordShow = this.state.password_show ? true : false;
		let confirmPasswordShow = this.state.confirmPassword_show ? true : false;

		let captchaSettings = this.props.ticketsData?.captcha;

		if(this.state.success === true && this.state.email) {
			return (
				<Redirect push 
					// to={
					// 	this.state.successRoute
					// } 
					to={{
						pathname: this.state.successRoute,
						state: {
							email:this.state.email
						},
					}}
				/>
			);
		}

		this.CreateAccountValues = {
			firstName: '',
			lastName: '',
			emailAddress: '',
			password: '',
			confirmPassword: ''
		};

		let CreateAccountSchema = yup.object({
			firstName: yup
				.string()
				.required('First Name is required'),
			lastName: yup
				.string()
				.required('Last Name is required'),
			emailAddress: yup
				.string()
				.email('Please supply a valid Email Address')
				.required('Email Address is required'),
			password: yup
				.string()
				.matches(
					new RegExp(this.props.ticketsData.passwordRegex),
					this.props.messageSettings['password_rules']
					)
				.required('Password is required'),
			confirmPassword: yup
				.string()
				.matches(
					new RegExp(this.props.ticketsData.passwordRegex),
					this.props.messageSettings['password_rules']
					)
				.required('Password (confirmation) is required')
				.oneOf([yup.ref('password'), null], 'Passwords must match'),
		});

		return (
			<div className="create-account-container">
				<div className="form-title">Create Account</div>
				<Formik
					initialValues={this.CreateAccountValues}
					validationSchema={CreateAccountSchema}
					//onSubmit={this.handleCreateAccount}
					onSubmit={(values, { setSubmitting }) => {
							if((values.emailAddress == '' || values.password == '')) {
								setSubmitting(false);
							} else {
								setSubmitting(true);
								this.handleCreateAccount(values, setSubmitting);
							}
						}}
					enableReinitialize={true}
					key="createAccount">
					{formProps => {
						logger.log('[CreateAccountForm] render - formProps:%o', formProps);
						return (
							<Form id="createAccountForm">
								{writeErrors(formProps, this.state.errors)}
								
								<div className="two-columns">
									<FormInput
										name="firstName"
										label="First Name"
										labeltype="float"
										type="text"
										maxLength="50"
										onAnimationStart={e => this.handleAutoFill(e)}
										onAnimationEnd={e =>
											e.animationName === 'onAutoFillStart'
												? (formProps.touched.firstName = true)
												: (formProps.touched.firstName = false)
										}
										onFocus={e => this.handleFocus(e, true)}
										onBlur={e => this.handleFocus(e, false, formProps)}
									/>

									<FormInput
										name="lastName"
										label="Last Name"
										labeltype="float"
										type="text"
										maxLength="50"
										onAnimationStart={e => this.handleAutoFill(e)}
										onAnimationEnd={e =>
											e.animationName === 'onAutoFillStart'
												? (formProps.touched.lastName = true)
												: (formProps.touched.lastName = false)
										}
										onFocus={e => this.handleFocus(e, true)}
										onBlur={e => this.handleFocus(e, false, formProps)}
									/>
								</div>

								<FormEmail
									name="emailAddress"
									label="Email"
									labeltype="float"
									onAnimationStart={e => this.handleAutoFill(e)}
									onAnimationEnd={e =>
										e.animationName === 'onAutoFillStart'
											? (formProps.touched.emailAddress = true)
											: (formProps.touched.emailAddress = false)
									}
									onFocus={e => this.handleFocus(e, true)}
									onBlur={e => this.handleFocus(e, false, formProps)}
								/>

								<FormPassword
									name="password"
									label="Password"
									labeltype="float"
									type={passwordShow ? "text" : "password"}
									passwordshow={passwordShow}
									instructions="true"
									onAnimationStart={e => this.handleAutoFill(e)}
									onAnimationEnd={e =>
										e.animationName === 'onAutoFillStart'
											? (formProps.touched.password = true)
											: (formProps.touched.password = false)
											}
									onFocus={e => this.handleFocus(e, true)}
									onBlur={e => this.handleFocus(e, false, formProps)}
									onTogglePassword={() => this.togglePassword('password_show', 'password') }
								/>

								<FormConfirmPassword
									name="confirmPassword"
									label="Password (confirmation)"
									labeltype="float"
									type={confirmPasswordShow ? 'text' : 'password'}
									onAnimationStart={e => this.handleAutoFill(e)}
									passwordshow={confirmPasswordShow}
									passwordvalue={formProps.values.password}
									onAnimationEnd={e =>
										e.animationName === 'onAutoFillStart'
											? (formProps.touched.confirmPassword = true)
											: (formProps.touched.confirmPassword = false)
									}
									onFocus={e => this.handleFocus(e, true)}
									onBlur={e => this.handleFocus(e, false, formProps)}
									onTogglePassword={() => this.togglePassword('confirmPassword_show', 'passwordConfirm') }
								/>

								<div className="page-actions">
									 {/** 
									* in order to send form on hitting Enter key, need to use <input type="submit" /> 
									 */}
									<input 
										type="submit" 
										disabled={ (!formProps.isValid || formProps.isSubmitting)}
										className={`button submit full ${!formProps.isValid || formProps.isSubmitting ? 'disabled' :''}`}
										value="Sign Up" 
									/>
								</div>
							</Form>
						);
					}}
				</Formik>
									
				<div className="form-text">
					{/* Do not create an account if you have a new email address or have moved.
					<br/><br/>
					To update your infomation, log in using old email and password and click on "Edit Your Information".
					<br/><br/>
					You may contact the Masters Ticket Office at (706) 667-6700 for assistance. */}
						<CmsMessageContent id="login.create.existing_instructions" />
					{
						(captchaSettings?.showCaptcha || this.allowCaptcha) ? (
							<div className="termsAgree">This site is protected by reCAPTCHA and the Google &nbsp;
								<EventsLink to="https://policies.google.com/privacy"  external={true}>Privacy Policy</EventsLink> and &nbsp;
								<EventsLink to="https://policies.google.com/terms" external={true}>Terms of Service</EventsLink> apply.
							</div>
						) : null
					}
				</div>

				{this.getLoadingIndicator()}
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccountForm);

import deps from 'dependencies';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import { getQuerystringValues } from 'appdir/components/general/Util';

export default {
	mount: (cmsData) =>  (dispatch, getState, store) => {
		logger.log('[Config] actions.mount store:%o', getState());
		let parsedQs = getQuerystringValues();
		let data = {bypass: parsedQs.bypass == 'true' ? true : false}
		dispatch({ type: deps.actionTypes.CONFIG_MOUNT, data });
	},
	update: params => (dispatch, getState) => {
		//logger.log('[Config] actions.update - ');
		dispatch({ type: deps.actionTypes.CONFIG_LOADING });
		return deps.services.Config.fetch()
			.then(result => {
				logger.log('[Config] actions.update - update config');

				if (typeof result === 'object') {
					dispatch({
						type: deps.actionTypes.CONFIG_UPDATE,
						updated: moment().toISOString(),
						status: 'loaded',
						data: result,
					});
				} else {
					dispatch({
						type: deps.actionTypes.CONFIG_ERROR,
						error: 'json parse',
					});
				}
			})
			.catch(err => {
				dispatch({
					type: deps.actionTypes.CONFIG_ERROR,
					error: err,
				});
			});
	},

	updateStatic: param => (dispatch, getState) => {
		let url = getState()['Config'].staticData[param].url;
		logger.log('[Config] actions.updateStatic - url:%o', url);

		dispatch({
			type: deps.actionTypes.CONFIG_STATIC_LOADING,
			data: {
				name: param,
				status: 'loading',
			},
		});

		return deps.services.Config.fetchStatic(url).then(result => {
			logger.log('[Config] updateStatic - result:%o', result);
			if (typeof result === 'object') {
				let data = {
					name: param,
					status: 'loaded',
					updated: moment().toISOString(),
					data: result,
				};

				dispatch({
					type: deps.actionTypes.CONFIG_STATIC_UPDATE,
					data: data,
				});
			}
		});
	},

	unmount: () => (dispatch, getState, store) => {
		let cmsData = { id: '', dataUrl: '' };
		logger.log('[ContentPage] unmount - cmsData: %o', cmsData);
		dispatch({ type: deps.actionTypes.CONTENT_UNMOUNT, data: { cmsData } });
	},

	loadConfig: () => (dispatch, getState, store) => {
		//logger.log('[Config] actions.loadConfig store:%o', getState());
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			logger.log('[Config] actions.loadConfig: Config:%o', Config);
		});
	},

	updateEventConfig: () => (dispatch, getState, store) => {

		let url = getState()['Config'].ticketsData['event'];
		logger.log('[Config] updateEventConfig - url:%o',url);

		dispatch({ type: deps.actionTypes.CONFIG_EVENTCONFIG_LOADING });

		return deps.services.Config.fetchStatic(url).then(result => {
			logger.log('[Config] updateEventConfig - result:%o', result);
			if (typeof result === 'object') {
				let data = {
					status: 'loaded',
					data: result.data,
				};

				dispatch({
					type: deps.actionTypes.CONFIG_EVENTCONFIG_UPDATE,
					data: data,
				});
			}
		});
	},

};

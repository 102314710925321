/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import EventsLink from 'shared/general/EventsLink';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: Back Link
 *
 * props - klass, path, linkText are all optional
 * -----------------------------------------------------------------------------
 */
const PrintHeader = props => {
	// logger.log('[PrintHeader] props:%o', props);

	return (
		<section className={`print-header`}>
			<div id="eventLogo">
				<EventsLink to="https://www.drivechipandputt.com/">DCP</EventsLink>
			</div>
			{/* <div className="print-date">{moment().format('MM-DD-YYYY HH:mm:ss')}</div> */}
		</section>
	);
};

export default PrintHeader;

PrintHeader.defaultProps = {};

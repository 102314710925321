/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';

/**
 * -----------------------------------------------------------------------------
 * React Component: ToggleButton
 * -----------------------------------------------------------------------------
 * <ToggleButton data={{
 *		toggleLabel:"Track",
 *		toggleOn:this.props.trackToggle,
 *		toggleCallback:(newValue)=>this.toggleTrack(newValue),
 *	}} />
 *
 *  Your callback function should be in the parent component.  Here's an example that calls an action to update some state/redux var.
 *
 * toggleTrack(value) {
 *		this.props.updateMyStreamStatus({
 *			trackToggle:value
 *		})
 *	}
 */

const ToggleButton = props => {
	return (
		<div className="toggle-wrapper">
			<div className="toggle-label">{props.data.toggleLabel}</div>
			<div
				className={`toggle-button ${
					props.data.toggleOn ? 'on' : 'off'
				}`}
				onClick={() => {
					props.data.toggleCallback(!props.data.toggleOn);
				}}
				tabIndex={0}
				role="button"
				aria-label={`Toggle ${props.data.toggleLabel}`}
				onKeyPress={event => {
					logger.log('[ToggleButton] accessibility toggle');
					event.key == 'Enter'
						? props.data.toggleCallback(!props.data.toggleOn)
						: null;
				}}
				>
				{props.data.toggleOn ? 'on' : 'off'}
			</div>
		</div>
	);
};

ToggleButton.defaultProps = {
	data: {},
};

export default ToggleButton;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
 import React from 'react';
 import EventsLink from 'shared/general/EventsLink';
 import op from 'object-path';
 
 /**
  * -----------------------------------------------------------------------------
  * React Component: Button
  * -----------------------------------------------------------------------------
  */
 
const Button = (props) => {
    logger.log('[Button] props:%o', props);

    if(op.get(props, 'buttonState', false) == 'disabled') {
        return(
            <div className={`button disabled ${props.style ? props.style : ''}`}>
                {props.buttonText} 
            </div>
		);
    } else {
        if(op.get(props, 'to', false) && op.get(props, 'onClick', false)) {
            return(
                <EventsLink 
                    style={`button ${props.style ? props.style : ''}`} 
                    to={props.to}
                    onClick={props.onClick}
                    onKeyPress={event => {
                        event.key == 'Enter'
                            ? props.onClick()
                            : null;
                    }} 
                    tabIndex="0"
                >
                    {props.buttonText} 
                </EventsLink>
            );
        } else if(op.get(props, 'to', false) && !op.get(props, 'onClick', false)) {
            return(
                <EventsLink 
                    style={`button ${props.style ? props.style : ''}`} 
                    to={props.to}
                    tabIndex="0"
                    external={props.external}
                >
                    {props.buttonText} 
                </EventsLink>
            );
        } else if(!op.get(props, 'to', false) && op.get(props, 'onClick', false)){
            return(
                <div 
                    className={`button ${props.style ? props.style : ''}`}
                    onClick={props.onClick}
                    onKeyPress={event => {
                        event.key == 'Enter'
                            ? props.onClick()
                            : null;
                    }} 
                    tabIndex="0"
                > 
                    {props.buttonText} 
                </div>
            );
        } else {
            return null
        }
    }
}
 export default Button;
/**
 * Schema: Use this to define a schema for validating data.
 * 
 * how to use example:  
 * ------------------------
 * import { Validator } from 'jsonschema';
 * 
 * const schema = require('appdir/components/data/Schema/index.js');
 * const v = new Validator();
 * 
 * let validateResult = v.validate(response.data, schema.configWeb);
 * 
 * if (validateResult.valid) {
 *	return response.data;
 * } else {
 * 	// Do something:  throw error and go to catch block
 * 	throw new Error('failed schema validation');
 * }
 * 
 */


export const configWeb = {
	id: '/Config',
	type: 'object',
	properties: {
		refreshSec: {
			type: 'integer',
			minimum: 10
		},
		settings: {
			type: 'object'
		},
		scoring: {
			type: 'object'
		},
		scoringData: {
			type: 'object'
		},
		stubPages: {
			type: 'object'
		}
	},
	required: ['refreshSec']
};

export const relatedContentVideo = {
	id: '/RelatedContent',
	type: 'object',
	properties: {
		title: { type: 'string' },
		contentId: { type: 'string' },
		cmsId: { type: 'string' },
		images: { type: 'array' },
		url: { type: 'string' },
		media: {
			type: 'object',
			properties: {
				m3u8: { type: 'string' },
				share: { type: 'string' }
			},
			required: ['m3u8']
		}
	},
	required: ['title', 'cmsId', 'images', 'url', 'media']
};

export const searchResults = {
	id: '/Search',
	type: 'object',
	properties: {
		responseHeader: { type: 'object' },
		response: { type: 'object' }
	},
	required: ['responseHeader', 'response']
};

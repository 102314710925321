import React, { Component } from 'react';
import EventsLink from 'shared/general/EventsLink';

class SponsorList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			counter: -1,
		};
		this.interval = 30 * 1000;
		this._randomize = true;
	}

	componentWillUnmount() {
		clearInterval(this.timerID);
		this._randomize = true;
	}

	componentDidMount() {
		if (this.timerID !== undefined) {
			clearInterval(this.timerID);
		}

		// Set the initial sponsor to the first element of the array
		// Mercedes is the first element in the sponsor array
		//this.setState({ counter: 0 });

		// call this if there's a random start.  otherwise set state like above to default counter to a specific start.
		this.getRandom();

		this.timerID = setInterval(() => {
			this.rotate();
		}, this.interval);
	}

	getRandom() {
		// logger.log('[SponsorList] getRandomStart - state: %o', this.state);
		// max set to 2 will return 0 or 1
		// max set to 3 will return 0, 1 or 2
		var max = 3;

		this.setState({ counter: Math.floor(Math.random() * max) });
	}

	rotate() {
		//logger.log('[SponsorList] rotate - state: %o', this.state);
		let tmp_counter;

		// Get random number to set the  sponsor shown
		// It will be either 0, 1 or 2 and then we add 1 (this is based off max)
		if (this._randomize) {
			this._randomize = false;
			this.getRandom();
		}

		if (this.state.counter >= 0) {
			tmp_counter = this.state.counter;

			tmp_counter += 1;

			if (tmp_counter >= 3) {
				tmp_counter -= 3;
			}
			this.setState({ counter: tmp_counter });
		}
	}

	render() {
		// logger.log('[SponsorList] render - state: %o', this.state);

		let { sponsors } = this.props;

		if (this.state.counter >= 0) {
			return (
				<div id="sponsors" className="sponsors">
					{sponsors.map((item, i) => {
						let showClass = i === this.state.counter ? 'show' : '';
						return (
							<div className={`${item.class} ${showClass} `} key={`nav-${i}`}>
								<EventsLink to={item.to} external={true}>
									<img src={item.imgsrc} height={item.height} width={item.width} alt={item.label} />
								</EventsLink>
							</div>
						);
					})}
				</div>
			);
		} else {
			return null;
		}
	}
}

SponsorList.defaultProps = {
	sponsors: [
		{
			class: 'mb',
			exact: true,
			to: 'http://www.mbusa.com/',
			imgsrc: '/assets/images/nav/mercedes.png',
			width: '32',
			height: '32',
			label: 'Mercedes',
		},
		{
			class: 'ibm',
			exact: true,
			to: 'http://www.ibm.com/',
			imgsrc: '/assets/images/nav/ibm_logo.png',
			width: '55',
			height: '20',
			label: 'IBM',
		},
		{
			class: 'att',
			exact: true,
			to: 'http://www.att.com/',
			imgsrc: '/assets/images/nav/att_logo.png',
			width: '61',
			height: '25',
			label: 'AT&T',
		},
	],
};

export default SponsorList;

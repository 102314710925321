/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
 import HomeContent from './HomeContent';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';

/**
 * -----------------------------------------------------------------------------
 * React Component: HomeContent
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['HomeContent'],
		configStatus: state['Config'].status,
		otherData: state['Config'].otherData,
		EventsWindow: op.get(state['WindowSize'], 'EventsWindow', {}),
		ticketsData: op.get(state['Config'], 'ticketsData', {}),
		flowData: op.get(state['Config'], 'staticData.flow.data', {}),
		pageTitle: state?.Config?.otherData?.pageTitle,
		site: state?.Config?.dataSettings?.site,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	loadConfig: () => dispatch(deps.actions.Config.loadConfig()),
	logout: () => dispatch(deps.actions.UserData.logoutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeContent);

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
 import React, { Component } from 'react';
 import Template from 'components/Template';
 import MeasurementUtils from 'appdir/lib/analytics';
 import ContentHero from 'shared/cms/ContentHero';
 import AccountPinForm from 'shared/forms/AccountPinForm';
 import isEmpty from 'lodash/isEmpty';
 import CmsMessageContent from 'shared/general/CmsMessageContent';
 /**
  * -----------------------------------------------------------------------------
  * React Component: AccountPinContent
  * -----------------------------------------------------------------------------
  */
 export default class AccountPinContent extends Component {
     constructor(props) {
         super(props);
         this.state = {
             errors: {},
         };
 
         MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
             pageTitle: 'AccountPin',
             site: this.props.userData.eventName,
             userID: this.props.userData.userID,
         });
     }
 
     componentDidMount() {
         logger.log('[AccountPinContent] - componentDidMount this%o', this);
         this.props.loadConfig();
     }
 
     render() {
         logger.log('[AccountPinContent] render this:%o', this);
 
         if (this.props.siteConfigStatus == 'loaded' && !isEmpty(this.props.ticketsData)) {
             return (
                 <Template>
                     <section className="page-content">
                         <section className="page-title">
                             <h1><CmsMessageContent id="general.page_header_title" textOnly={true} /></h1>
                         </section>
                         <section className="hero">
                             <ContentHero />
                         </section>
                         <section className="section-title-wrapper">
                             <h2>Account Information</h2>
                         </section>
 
                         <section className="page-grid cols-1">
                             <div className="page-grid-item">
                                 {this.props.requiresPin[this.props.userData.country] ? (
                                     <CmsMessageContent id="account.ssn_instructions" />
                                 ) : (
                                     <CmsMessageContent id="account.no_ssn_insructions" />
                                 )}
 
                                 <AccountPinForm
                                     ticketsData={this.props.ticketsData}
                                     userData={this.props.userData}
                                     flowData={this.props.flowData}
                                 />
                             </div>
                         </section>
                     </section>
                 </Template>
             );
         } else {
             return null;
         }
     }
 }
 
 AccountPinContent.defaultProps = {};
 
/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import EventsLink from 'shared/general/EventsLink';
import Button from 'shared/general/Button';
import { useSelector } from 'react-redux';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: Visit Link
 * -----------------------------------------------------------------------------
 */
const VisitButton = props => {
    const messageSettings = useSelector(state => state['Config']?.staticData.cmsMessages.data);

    if (messageSettings) {
        if (props.type !== 'button') {
            return (
                <div className="action-link-container">
                    <EventsLink to={messageSettings.website_url} style="action-link">
                        Visit {messageSettings.website_name}
                    </EventsLink>
                </div>
            );
        } else {
            return (
                <Button buttonText={`Visit ${messageSettings.website_name}`} to={messageSettings.website_url} />
            )
        }

    } else {
        return null
    }
   
};

export default VisitButton;
// /**
//  * -----------------------------------------------------------------------------
//  * Imports
//  * -----------------------------------------------------------------------------
//  */
// import React, { Component } from 'react';
// import deps from 'dependencies';
// import op from 'object-path';
// import Nav from 'components/general/Nav';
// //import SiteAlert from 'components/general/SiteAlert';
// import { connect } from 'react-redux';

// const mapStateToProps = (state, props) => ({
// 	...state.Header,
// 	settings: state['Config'].dataSettings,
// 	siteAlertPath: op.get(state['Config'], 'cmsData.alert', ''),
// 	siteAlert: state['Config']['siteAlert'],
// 	...props,
// });

// const mapDispatchToProps = (dispatch, props) => ({
// 	updateAlert: data => dispatch(deps.actions.Config.updateAlert(data)),
// });

// /**
//  * -----------------------------------------------------------------------------
//  * React Component: Header
//  * -----------------------------------------------------------------------------
//  */

// class Header extends Component {
// 	constructor(props) {
// 		super(props);
// 		this.state = {
// 			//...this.props,
// 		};

// 		// this.fetchedData = false;
// 		// this.interval = 30 * 1000;
// 	}

// 	componentDidMount() {
// 		if (this.props.hasOwnProperty('mount')) {
// 			this.props.mount(this);
// 		}

// 		window.addEventListener('scroll', this.resizeHeaderOnScroll);
// 	}

// 	componentDidUpdate(prevProps, prevState) {
// 		// if (this.props.siteAlertPath !== '' && !this.props.siteAlert && !this.fetchedData) {
// 		// 	this.fetchedData = true;
// 		// 	this.fetchAlert();
// 		// 	if (this.timerID !== undefined) {
// 		// 		clearInterval(this.timerID);
// 		// 	}
// 		// 	this.timerID = setInterval(() => {
// 		// 		this.fetchAlert();
// 		// 	}, this.interval);
// 		// }
// 	}

// 	componentWillUnmount() {
// 		window.removeEventListener('scroll', this.resizeHeaderOnScroll);
// 		// this.fetchedData = false;

// 		// if (this.timerID !== undefined) {
// 		// 	clearInterval(this.timerID);
// 		// }
// 	}

// 	fetchAlert() {
// 		//logger.log('[Header] - fetchAlert');

// 		let alertPath = this.props.siteAlertPath;

// 		if (alertPath !== '') {
// 			deps.services.Header.fetch(alertPath).then(results => {
// 				//logger.log('[Header] - fetchAlert results:%o', results);
// 				this.props.updateAlert(results);
// 			});
// 		}
// 	}

// 	resizeHeaderOnScroll() {
// 		const distanceY = window.pageYOffset || document.documentElement.scrollTop,
// 			shrinkOn = 100,
// 			headerEl = document.getElementById('dcp-header');

// 		if (distanceY > shrinkOn) {
// 			headerEl !== null && headerEl ? headerEl.classList.add('smaller') : null;
// 		} else {
// 			headerEl !== null && headerEl ? headerEl.classList.remove('smaller') : null;
// 		}
// 	}

// 	render() {
// 		let siteAlert = op.get(this.props, 'siteAlert', null);
// 		let alertText = op.get(siteAlert, 'text', '');

// 		//let siteAlert = op.get(this.props, 'settings.siteAlert', null);
// 		let className = siteAlert && (alertText || siteAlert.linkText) ? 'alert' : '';
// 		return (
// 			<header id="dcp-header" className={className}>
// 				{/* {siteAlert && (alertText !== '' || siteAlert.linkText !== '') ? (
// 					<SiteAlert text={alertText} linkText={siteAlert.linkText} link={siteAlert.link} />
// 				) : null} */}
// 				<Nav />
// 			</header>
// 		);
// 	}
// }

// export default connect(mapStateToProps, mapDispatchToProps)(Header);

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import deps from 'dependencies';
import op from 'object-path';
import Nav from 'components/general/Nav';
import CookieWarning from 'components/general/CookieWarning';
import throttle from 'lodash/throttle';
import cn from 'classnames';
import { connect } from 'react-redux';

/**
 * -----------------------------------------------------------------------------
 * React Component: Header
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => ({
	...state.Header,
	EventsWindow: state['WindowSize'].EventsWindow,
	settings: state['Config'].dataSettings,
	Router: state['Router'],
	...props,
});

const mapDispatchToProps = (dispatch, props) => ({});

class Header extends Component {
	constructor(props) {
		super(props);

		this._isMounted = false;
		this.fetchedData = false;
		this.interval = 30 * 1000;

		this.state = {
			scrolled: false,
		};

		this.onScoll = this.onScroll.bind(this);
	}

	onScroll() {
		//logger.log('[Header] - onScroll: scrollY: %o', window.scrollY);
		if (this._isMounted) {
			if (this.props.EventsWindow) {
				if (this.props.EventsWindow.numericWindowSize < 3 && window.scrollY > 96) {
					this.setState({
						scrolled: true,
					});
				} else if (this.props.EventsWindow.numericWindowSize > 2 && window.scrollY > 75) {
					this.setState({
						scrolled: true,
					});
				} else {
					this.setState({
						scrolled: false,
					});
				}
			} else {
				this.setState({
					scrolled: false,
				});
			}
		}
	}

	componentDidMount() {
		window.addEventListener('scroll', throttle(this.onScroll.bind(this), 100, { trailing: true }), false);
		this._isMounted = true;
	}

	componentDidUpdate(prevProps, prevState) {}

	componentWillUnmount() {
		this._isMounted = false;
		this.fetchedData = false;
		window.removeEventListener('scroll', this.onScoll.bind(this), false);

		if (this.timerID !== undefined) {
			clearInterval(this.timerID);
		}

		logger.log('[Header] - componentWillUnmount this:%o', this);
	}

	render() {
		let headerClasses = cn({
			scrolled: this.state.scrolled,
		});

		return (
			<Fragment>
				<header id="masters-header" className={headerClasses}>
					<Nav />
				</header>
				<CookieWarning />
			</Fragment>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);

import React from 'react';
import CmsMessageContent from 'shared/general/CmsMessageContent';
import MastersImage from 'shared/general/MastersImage';

const UnderConstruction = props => {
	const { underConstructionImage } = props.attributes;

	return (
		<section className="under-construction-page">
			<section className="under-construction-content">
				<div className="under-construction-img">
					{
						underConstructionImage ? <MastersImage imageConfig={underConstructionImage} /> : null
					}
					
				</div>
				<div className="under-construction-message">
					<div className="message-header">
						<CmsMessageContent id="general.stub_content_header" />
					</div>
					<div className="message-body">
						<CmsMessageContent id="general.stub_content" />
					</div>
				</div>
			</section>
		</section>
	);
};

export default UnderConstruction;

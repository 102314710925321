import deps from 'dependencies';

export default (state = {}, action) => {
    let newState;

    switch (action.type) {
        case deps.actionTypes.STUBBOXCMS_MOUNT:
            newState = {
				...state,
				...action.data,
			};
            logger.log('[StubBoxCms] - reducer: %o', newState);
            return newState;

        default:
            return state;
    }
};

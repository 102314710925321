/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import MagicLinkContent from 'shared/page-content/MagicLinkContent';

/**
 * -----------------------------------------------------------------------------
 * React Component: MagicLink
 * -----------------------------------------------------------------------------
 */

const MagicLink = props => {
	logger.log('[MagicLink] props:%o', props);
	return <MagicLinkContent />;
};

export default MagicLink;

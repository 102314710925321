import deps from 'dependencies';
//import { logger } from 'handlebars';

export default {
    mount: () => (dispatch, getState, store) => {
        return deps.services.Config.ensureConfigurationLoaded(
            dispatch,
            store
        ).then(Config => {
            //logger.log('[MastersImage] - actions mount');
            let data = {
                mounted: true
            };
            dispatch({ type: deps.actionTypes.MASTERSIMAGE_MOUNT, data: data });
        });
    },
};

import Review from './index';

/**
 * route definitions:
 *
 * en_US/account/review - page to review account info before submitting
 * en_US/application/review - page to review application before submitting
 * en_US/payment/review - page to review payment before submitting
 * en_US/payment/complete - page after payment is submitted (receipt)
 */
export default [
	{
		path: ['/:lang(en_US)/:category(account|application|payment)/:action(review).html'],
		exact: true,
		component: Review,
	},
	{
		path: ['/:lang(en_US)/:category(payment)/:action(complete).html'],
		exact: true,
		component: Review,
	},

	{
		path: ['/:site(patron)/:category(account|application|payment)/:action(review).html'],
		exact: true,
		component: Review,
	},
	{
		path: ['/:site(patron)/:category(payment)/:action(complete).html'],
		exact: true,
		component: Review,
	},

	{
		path: ['/:lang(en_US)/:category(account|application|payment)/:action(review)/:display(print).html'],
		exact: true,
		component: Review,
	},
	{
		path: ['/:lang(en_US)/:category(payment)/:action(complete)/:display(print).html'],
		exact: true,
		component: Review,
	},

	{
		path: ['/:site(patron)/:category(account|application|payment)/:action(review)/:display(print).html'],
		exact: true,
		component: Review,
	},
	{
		path: ['/:site(patron)/:category(payment)/:action(complete)/:display(print).html'],
		exact: true,
		component: Review,
	},
];

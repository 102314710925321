/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
 import AccountPinContent from './AccountPinContent';
 import { connect } from 'react-redux';
 import deps from 'dependencies';
 import op from 'object-path';
 
 /**
  * -----------------------------------------------------------------------------
  * React Component: AccountPinContent
  * -----------------------------------------------------------------------------
  */
 
 const mapStateToProps = (state, props) => {
     return {
         ...state.AccountPinContent,
         siteConfigStatus: state['Config'].status,
         ticketsData: op.get(state['Config'], 'ticketsData', {}),
         EventsWindow: op.get(state['WindowSize'], 'EventsWindow', {}),
         userData: op.get(state['UserData'], 'userData', {}),
         flowData: op.get(state['Config'], 'staticData.flow.data', {}),
         requiresPin: op.get(state['Config'], 'addressValidation.requiresPin', {}),
         pageTitle: state?.Config?.otherData?.pageTitle,
         site: state?.Config?.dataSettings?.site,
         ...props,
     };
 };
 
 const mapDispatchToProps = (dispatch, props) => ({
     loadConfig: () => dispatch(deps.actions.Config.loadConfig()),
 });
 
 export default connect(mapStateToProps, mapDispatchToProps)(AccountPinContent);
 
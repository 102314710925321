import Confirmation from './index';

export default [
	{
		path: [
			'/:lang(en_US)/:category(newaccount|account|application|optin|optout|email|deleteaccount|deleted|requestdelete|verifyidentity)/:action(confirmation).html',
		],
		exact: true,
		component: Confirmation,
	},
	{
		path: ['/:lang(en_US)/:category(application)/:action(notification).html'],
		exact: true,
		component: Confirmation,
	},
	{
		path: ['/:lang(en_US)/:category(terms)/:action(reject).html'],
		exact: true,
		component: Confirmation,
	},
	{
		path: ['/:site(en_US)/:category(uhOh)/:action(index).html'],
		exact: true,
		component: Confirmation,
	},
];

/**
 * -----------------------------------------------------------------------------
 * Imports
 * ----------------------------------------------------------------------------
 */
import { connect } from 'react-redux';
import deps from 'dependencies';
import Config from './Config';

/**
 * -----------------------------------------------------------------------------
 * Inject Redux State and Actions into React Component: ControlBar
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => ({
	...state.Config,
	userData: state['UserData'],
	...props,
});

const mapDispatchToProps = dispatch => ({
	mount: () => dispatch(deps.actions.Config.mount()),
	update: () => dispatch(deps.actions.Config.update()),
	updateStatic: item => dispatch(deps.actions.Config.updateStatic(item)),
	updateEventConfig: () => dispatch(deps.actions.Config.updateEventConfig()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Config);

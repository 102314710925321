/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import ReviewContent from './ReviewContent';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';

/**
 * -----------------------------------------------------------------------------
 * React Component: ReviewContent
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state.Review,
		siteConfigStatus: state['Config'].status,
		eventDays: op.get(state['Config'], 'application.days'),
		applicationStatus: op.get(state['Config'], 'application.status'),
		ticketsData: op.get(state['Config'], 'ticketsData', {}),
		EventsWindow: op.get(state['WindowSize'], 'EventsWindow', {}),
		userData: op.get(state['UserData'], 'userData', {}),
		tempUserData: op.get(state['UserData'], 'tempUserData'),
		eventConfig: op.get(state['Config'], 'eventConfig.data', {}),
		applicationConfig: op.get(state['Config'], 'application'),
		flowData: op.get(state['Config'], 'staticData.flow.data', {}),
		invalidStatusIds: op.get(state['Config'], 'addressValidation.invalidStatusIds', {}),
		validStatusIds: op.get(state['Config'], 'addressValidation.validStatusIds', {}),
		messageSettings: op.get(state['Config'], 'staticData.cmsMessages.data'),
		pendingEmail: op.get(state['UserData'], 'pendingEmail', false),
		site: state?.Config?.dataSettings?.site,
		Router: state['Router'],
		requiresVerifyId: op.get(state['Config'], 'addressValidation.requiresVerifyId', {}),
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	loadConfig: () => dispatch(deps.actions.Config.loadConfig()),
	updatePatronData: data => dispatch(deps.actions.UserData.updatePatronData(data)),
	updateUserData: data => dispatch(deps.actions.UserData.updateUserData(data)),
	updateTempUserData: () => dispatch(deps.actions.UserData.updateTempUserData()),
	deleteTempUserData: data => dispatch(deps.actions.UserData.deleteTempUserData()),
	logout: () => dispatch(deps.actions.UserData.logoutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewContent);

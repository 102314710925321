import deps from 'dependencies';
import actions from './actions';

export default (state = {}, action) => {
	let newState;

	//logger.log("[Config] reducer - action: %o", action);

	switch (action.type) {
		case deps.actionTypes.CONFIG_MOUNT:
			return { ...state, init: true, ...action.data };

		case deps.actionTypes.CONFIG_UPDATE:
			let curStatic = {
				...action.data.staticData,
				...state.staticData,
			};

			newState = {
				...state,
				...action.data,
				staticData: curStatic,
				refreshSec: Math.max(5, action.data.refreshSec || 0),
				status: action.status,
				updated: action.updated,
			};

			if (newState.hasOwnProperty('error')) {
				delete newState.error;
			}
			logger.log('[Config] reducer.CONFIG_UPDATE - newState:%o:', newState);
			return newState;

		case deps.actionTypes.CONFIG_STATIC_UPDATE:
			//logger.log('[Config] reducer.CONFIG_STATIC_UPDATE - action:%o:', action);

			let newStatic = {
				...state.staticData,
			};

			newStatic[action.data.name] = {
				...newStatic[action.data.name],
				...action.data.data,
				status: action.data.status,
				updated: action.data.updated,
			};
			//logger.log('[Config] reducer.CONFIG_STATIC_UPDATE - newStatic:%o:', newStatic);

			newState = {
				...state,
				staticData: newStatic,
			};

			logger.log('[Config] reducer.CONFIG_STATIC_UPDATE - newState:%o:', newState);
			//return state;
			return newState;

		case deps.actionTypes.CONFIG_STATIC_LOADING:
			let loadStatic = {
				...state.staticData,
			};

			loadStatic[action.data.name] = {
				...loadStatic[action.data.name],
				status: action.data.status,
			};

			newState = {
				...state,
				staticData: loadStatic,
			};
			return newState;

		case deps.actionTypes.CONFIG_LOADING:
			newState = Object.assign({}, state, { status: 'loading' });
			if (newState.hasOwnProperty('error')) {
				delete newState.error;
			}
			return newState;

		case deps.actionTypes.CONFIG_ERROR:
			logger.log('[Config] reducer.CONFIG_ERROR - action:%o', action);
			newState = Object.assign({}, state, {
				status: 'error',
				error: action.error,
			});
			return newState;

		case deps.actionTypes.CONFIG_EXPIRED:
			logger.log('[Config] reducer.CONFIG_EXPIRED');
			newState = Object.assign({}, state, { status: 'expired' });
			return newState;

		case deps.actionTypes.UPDATE_ROUTE:
			newState = Object.assign({}, state, { status: 'expired' });
			logger.log('[Config] reducer.UPDATE_ROUTE - state:%o', newState);
			return newState;

		case deps.actionTypes.CONFIG_EVENTCONFIG_LOADING: 
			newState = {
				...state,
				eventConfig:{
					...state.eventConfig,
					status:'loading'
				},
			};

			// logger.log('[Config] reducer - CONFIG_LOAD_EVENTCONFIG: %o', newState);
			return newState;
		
		case deps.actionTypes.CONFIG_EVENTCONFIG_UPDATE: 
			newState = {
				...state,
				eventConfig:{
					...state.eventConfig,
					...action.data
				},
			};

			logger.log('[Config] reducer - CONFIG_EVENTCONFIG_UPDATE: %o', newState);
			return newState;
			
		default:
			return state;
	}
};

import jwt_decode from "jwt-decode";
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

export const checkJwtStatus = (secureJwt, threshold) => {
	let status = 'expired';

	let decodedJwt = jwt_decode(secureJwt);
	let jwtExpirationTime = moment(decodedJwt.exp * 1000);
	let diff = jwtExpirationTime.diff(moment(new Date()),'minutes');
	

	if (diff <= 0) {
		status = 'expired'
	} else if (diff > 0 && diff <= threshold) {
		status =  'refresh'
	} else {
		status =  false
	}
	logger.log('[UserData] Util -  checkJwtStatus - threshold: %o, diff:%o minutes, status:%o',threshold, diff, status);

	return status;
}
/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import ConfirmationContent from 'shared/page-content/ConfirmationContent';

/**
 * -----------------------------------------------------------------------------
 * React Component: Confirmation
 * -----------------------------------------------------------------------------
 */

const Confirmation = props => {
	logger.log('[Confirmation] props:%o', props);
	return <ConfirmationContent />;
};

export default Confirmation;

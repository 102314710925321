(function() {
	if (typeof window.CustomEvent === 'function') return false;

	function CustomEvent(event, params) {
		params = params || { bubbles: false, cancelable: false, detail: null };
		var evt = document.createEvent('CustomEvent');
		evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
		return evt;
	}

	CustomEvent.prototype = window.Event.prototype;

	window.CustomEvent = CustomEvent;
})();

const ACTION_TYPES = {
	pageView: 'pageView',
	onPageClick: 'onPageClick',
};

const DAYS_OF_WEEK = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const WEEKDAY = ['Weekend', 'Weekday', 'Weekday', 'Weekday', 'Weekday', 'Weekday', 'Weekend'];

const getTimeSlot = (date = new Date()) => {
	let currentMinutes = date.getMinutes();
	let displayMinutes = '45';

	if (currentMinutes >= 0 && currentMinutes < 15) {
		displayMinutes = '00';
	} else if (currentMinutes >= 15 && currentMinutes < 30) {
		displayMinutes = '15';
	} else if (currentMinutes >= 30 && currentMinutes < 45) {
		displayMinutes = '30';
	}

	let currentHours = date.getHours();
	let amPm = ' AM';
	let displayHours = currentHours == 0 ? 12 : currentHours;
	if (currentHours > 12) {
		displayHours = currentHours - 12;
		amPm = ' PM';
	}

	return displayHours + ':' + displayMinutes + amPm;
};

const prepareDataLayerObject = () => {
	let date = new Date();

	let path = location.pathname.replace(/\/$/, '');

	let props = window.dataLayer ? window.dataLayer.props : {};

	let dataLayer = {
		props: { ...props },
		url: {
			path: path,
			queryString: location.search,
			fullUrl: location.href.replace(/\/$/, ''),
			fullPath: path + location.search,
		},
		dateProperties: {
			dayOfWeek: DAYS_OF_WEEK[date.getDay()],
			weekday: WEEKDAY[date.getDay()],
			timeSlot: getTimeSlot(date),
		},
		language: 'en_US',
	};
	return dataLayer;
};

export default class MeasurementUtils {
	static dispatchMeasurementCall(actionType, actionData) {
		// Set up the standard values
		let dataLayer = prepareDataLayerObject();

		// Spread in the custom values
		dataLayer = {
			...dataLayer,
			actionData: actionData,
			actionType: actionType,
			_googleData: [],
		};

		// Place on window object so it can be pulled at by the Adobe Tag Manager
		window.dataLayer = dataLayer;

		// why am i putting push function on window.dataLayer? Some plugins assumes that window.dataLayer is for google tag manager.
		// our projects, google tag manager defines it as window.gtagLayer.  I couldn't figure out how to make <AddToCalendarButton /> use gtagLayer
		// instead.  Yes, this is a hacky workaround.
		if (!window?.dataLayer?.push) {
			window.dataLayer['push'] = value => window.dataLayer?._googleData?.push(value);
		}

		logger.log('[Measurement] - Data Layer Properties: %o', window.dataLayer);

		let event = new CustomEvent('measureEvent', dataLayer);
		document.dispatchEvent(event);
	}

	static get ACTION_TYPES() {
		return ACTION_TYPES;
	}
}

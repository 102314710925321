import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';
import { Redirect } from 'react-router';

import { Formik, Form } from 'formik';
import { FormCheckBox } from 'shared/forms/elements/FormFields';
import Button from 'shared/general/Button';
import VisitButton from 'shared/general/VisitButton';
import LogoutButton from 'shared/general/LogoutButton';
import isEmpty from 'lodash/isEmpty';
import CmsMessageContent from 'shared/general/CmsMessageContent';

/**
 * React Component: Loser Notification Checkbox 
 */

 const mapStateToProps = (state, props) => {
    return {
        ...state.KeepNotifiedForm,
        userData: op.get(state['UserData'], 'userData', {}),
        flowData: op.get(state['Config'], 'staticData.flow.data', {}),
        ...props,
        Router: state['Router'],
    };
};

class KeepNotifiedForm extends Component {
    constructor(props) {
        super(props);
        logger.log('[KeepNotifiedForm] props:%o', props);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            isChecked: op.get(this.props, 'userData.application.keepInformed'),
            success: false,
            successURL: null,
            redirect: null,
        };

        this.submit = false;
    }

    handleChange(checked) {
        this.setState({isChecked: checked});
        if(this.isChecked) {
            this.setState({
                redirect: true
            });
        }else {
            this.setState({
                redirect: false 
            });
        }
    }

    // Submit optin or optout
    handleSubmit = (value) => {
        console.log(value);

        let data = {
            keepInformed: value //"true" or "false",
        };

        this.props.updateApplicationData(data).then(response => {
            if (response.status == 200) {
                this.setState({
                    errors:{}
                })
            } else {
                this.setState({
                    errors:{submitError:this?.props?.messageSettings['general.service_error']?.replace("{0}","KN1")}
                })
            }
        }).catch(e => {
            this.setState({
                errors:{submitError:this?.props?.messageSettings['general.service_error']?.replace("{0}","KN2")}
            })
        })
    }

    // Display button based on keepInformed value
    renderOptinButtons() {
        if(isEmpty(this.props.flowData)) {
            return null;
        }
        let redirectURL = '';
        if (this.state.isChecked === true && op.get(this.props, 'flowData.actions.notification', null)) {
             redirectURL = this.props.flowData.actions.notification.optin
        } else {
            redirectURL = this.props.flowData.actions.notification.optout
        }
        return (
            <div className="page-actions">
                <Button
                    buttonText="Continue"
                    to={redirectURL}
                    onClick={() => this.handleSubmit(this.state.isChecked)}
                />
                <VisitButton />
                <LogoutButton />
            </div>
        );
    }

    renderNotificationOptions() {
        console.log("flowdata info:" + isEmpty(this.props.flowData));
        if(isEmpty(this.props.flowData)) {
            return null;
        }

        if (this.state.redirect === true && op.get(this.props, 'flowData.actions.notification')) {
            console.log("location for options" + this.props.flowData.actions.notification.optin)
            return <Redirect push to={this.props.flowData.actions.notification.optin} />
        } else {
            console.log("location for options" + this.props.flowData.actions.notification.optout)
            return <Redirect push to={this.props.flowData.actions.notification.optout} />
        }
    }
    
    render() {
        logger.log('[KeepNotifiedForm] props:%o', this);
        return (
            <div className="form-content">
                <div className="keep-notified-container">
                    <Formik 
                        enableReinitialize={true} 
                        initialValues={{check: !(this.state.isChecked)}}
                    >
                    {({ values }) => (
                        <Form id="keepNotifiedForm">
                            <div>
                                <FormCheckBox 
                                    name="check" 
                                    id="check" 
                                    onChange={() => this.handleChange(values.check)}
                                >
                                </FormCheckBox>
                                {this.isChecked ? (
                                    <CmsMessageContent id="application.splash_not_selected_optin" />
						        ) : (
                                    <CmsMessageContent id="application.splash_not_selected_optout" />
                                )}
                            </div>
                        </Form>         
                    )}
                    </Formik>
                </div>
                <>{this.renderOptinButtons()}</>
            </div>
        );
    }
};

const mapDispatchToProps = (dispatch, props) => ({
    loadConfig: () => dispatch(deps.actions.Config.loadConfig()),
    updateApplicationData: data => dispatch(deps.actions.UserData.updateApplicationData(data)),
    logout: () => dispatch(deps.actions.UserData.logoutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(KeepNotifiedForm);
import CmsMessageContent from './CmsMessageContent';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';

const mapStateToProps = (state, props) => {
	return {
		...state.CmsMessageContent,
		siteConfigStatus: state['Config'].status,
		staticDataStatus: op.get(state['Config'], 'staticData.cmsMessages.status'),
		messageSettings: op.get(state['Config'], 'staticData.cmsMessages.data'),
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	loadConfig: () => dispatch(deps.actions.Config.loadConfig()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CmsMessageContent);

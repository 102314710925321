/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';
import { Redirect } from 'react-router';
 
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { writeErrors } from 'shared/forms/elements/Utils';
import { FormCheckBox } from 'shared/forms/elements/FormFields';
import Button from 'shared/general/Button';
import CmsMessageContent from 'shared/general/CmsMessageContent';
import EventsLink from 'shared/general/EventsLink';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import LoadingIndicator from 'shared/general/LoadingIndicator';
import MeasurementUtils from 'appdir/lib/analytics';
import { values } from 'lodash';

 
/**
 * -----------------------------------------------------------------------------
 * Functional Component: const AddressConfirmationForm = (props) => {
 * -----------------------------------------------------------------------------
 */
 const mapStateToProps = (state, props) => {
	return {
		...props,
        userData: op.get(state['UserData'], 'userData', {}),
	};
};

const mapDispatchToProps = dispatch => ({
    updateUserData: data => dispatch(deps.actions.UserData.updateUserData(data)),
	updateTempUserData: () => dispatch(deps.actions.UserData.updateTempUserData()),
	updateApplicationData: data => dispatch(deps.actions.UserData.updateApplicationData(data)),
    updatePatronData: data => dispatch(deps.actions.UserData.updatePatronData(data)),
});

class AddressConfirmationForm extends Component {
    constructor(props) {
		super(props);
		this.state = {
            success: false,
            AddressConfirmationValues : {
                mainAddress: false,
                permanentAddress: false,
                onlyResident: false,
            }
        };

        this.loadingRef = React.createRef();
        this.handleChange = this.handleChange.bind(this);
        this.submit = false;

        logger.log('[AddressConfirmationForm] props:%o', props);
	}

    componentWillUnmount() {
        this.submit = false;

        this.setState({
            success: false
        }) 
    }

	componentDidUpdate(prevProps) {
        // if((!isEqual(prevProps.userData, this.props.userData) ||
        //     isEqual(prevProps.userData, this.props.userData)) && 
        //     this.submit === true
        // ) {
        //     this.submit = false;

        //     this.setState({
        //         success: true
        //     })
        // }
    }

    handleSubmit = (values) => {
        logger.log('[AddressConfirmationForm] handleSubmit - this:%o', this);
        logger.log('[AddressConfirmationForm] handleSubmit - values:%o', values);

        /** show loading indicator while everyone is working hard to create an account */
		// if (this.loadingRef.current) {
		// 	this.loadingRef.current.classList.add('show');
		// }
        this.submit = true;

        // logger.log('[AddressConfirmationForm] handleSubmit - data:%o', data);

        if (this.props.addressChange && this.props?.userData?.application?.applicationStatus !== 0) {
            let data = {
                addressConfirmed: true
            }
            // this.props.updatePatronData(this.props.userData)
            // this.props.updateApplicationData(data);

            this.props.updatePatronData(this.props.userData).then(patResponse => {
                if (patResponse.status == 200) {
                    logger.log('[AddressConfirmationForm] handleSubmit patResponse:%o', patResponse);

                    this.props.updateApplicationData(data).then(appResponse => {
                        if (appResponse.status == 200) {
                            logger.log('[AddressConfirmationForm] handleSubmit appResponse:%o', appResponse);
                            this.setState({
                                ...this.state,
                                success: true,
                                errors: {}
                            }, () => {
                                this.submit = false;
                            });
                        } else {
                            logger.log('[AddressConfirmationForm] handleSubmit error appResponse:%o', appResponse);
                            this.setState({
                                ...this.state,
                                success: false,
                                errors: {
                                    response: `${appResponse.data.errorCode}`,
                                },
                            }, () => {
                                this.submit = false;
                            });
                        }
                    });
                } else {
                    logger.log('[AddressConfirmationForm] handleSubmit error patResponse:%o', patResponse);
                    this.setState({
                        ...this.state,
                        success: false,
                        errors: {
                            response: `${patResponse.data.errorCode}`,
                        },
                    });
                }
            });
        } else {
            let data = {
                application : {
                    ...this.props.userData.application,
                    addressConfirmed: true
                }
            }
            this.props.updateUserData(data);

            this.setState({
                ...this.state,
                success: true,
                errors: {}
            })
        }
    }

    handleChange = (fieldName, values) => {
        logger.log('[AddressConfirmationForm] handleChange fieldName:%o, values:%o', fieldName, values);
        
        this.setState({
            AddressConfirmationValues: {
                ...values,
                [fieldName]:!values[fieldName]
            }
        }, () => {
            MeasurementUtils.dispatchMeasurementCall("AddressConfirmation", {
                site: this.props.userData.eventName,
                id: fieldName,
                checked: this.state.AddressConfirmationValues[fieldName]
            });
        })
        
    }

    isFormValid = (values) => {
        logger.log('[AddressConfirmationForm] isFormValid - values:%o', values);
        let found = false; 

        found = Object.keys(values).find(item => {
            return values[item] == false;
        }) 

        logger.log('[AddressConfirmationForm] isFormValid - found:%o', !found ? true : false);

        return (!found ? true : false); 
    }

    getLoadingIndicator = () => {
		return (
			<div className="fadeOverlay" ref={this.loadingRef}>
				<LoadingIndicator />
			</div>
		);
	};
 
    render() {
        logger.log('[AddressConfirmationForm] render - this:%o', this);


        let AddressConfirmationSchema = yup.object({
            mainAddress: yup.boolean().oneOf([true],'Please confirm by checking each box'),
            permanentAddress: yup.boolean().oneOf([true],'Please confirm by checking each box'),
            onlyResident: yup.boolean().oneOf([true],'Please confirm by checking each box')
        });

        if (this.state.success === true && op.get(this.props, 'flowData.actions.addressConfirmation.next', null)) {
            if (this.props.addressChange) {
                return <Redirect push to={this.props.flowData.actions.accountReview.submit} />;
            } else {
                return <Redirect push to={this.props.flowData.actions.addressConfirmation.next} />;
            }
		}

        return (
            <div className="form-container address-confirmation">
				<div className="form-content">
					<div className="address-confirmation-container">

						<Formik
							initialValues={this.state.AddressConfirmationValues}
							validationSchema={AddressConfirmationSchema}
							onSubmit={this.handleSubmit}
							enableReinitialize={true}
                            validateOnChange={true}
                            validateOnBlur={false}
							key="addressConfirmation">
							{(formProps) => {
								logger.log('[AddressConfirmationForm] render - formProps:%o', formProps);

                                this.isFormValid(formProps.values);

								return (
									<Form id="addressConfirmationForm">
										{writeErrors(formProps, this.state.errors)}

                                        <div>
                                            <FormCheckBox name="mainAddress" id="mainAddress" onChange={() => this.handleChange("mainAddress", formProps.values)}>
                                                <CmsMessageContent id="application.address_confirm.question1" />
                                            </FormCheckBox>

                                            <FormCheckBox name="permanentAddress" id="permanentAddress" onChange={() => this.handleChange("permanentAddress", formProps.values)}>
                                                <CmsMessageContent id="application.address_confirm.question2" />    
                                            </FormCheckBox>

                                            <FormCheckBox name="onlyResident" id="onlyResident" onChange={() => this.handleChange("onlyResident", formProps.values)}>
                                                <CmsMessageContent id="application.address_confirm.question3" />
                                            </FormCheckBox>                                    
                                        </div>

                                        <CmsMessageContent id= "application.address_confirm_duplicate_note"
                                        />

                                        {
                                            this.props.addressChange 
                                            ? (
                                                <div className="page-actions">

                                                    <Button
                                                        style={this.props.numericWindowSize < 3 ? 'full' : ''}
                                                        //buttonState={(!formProps.dirty && isEmpty(formProps.touched)) || !formProps.isValid ? 'disabled' : ''}
                                                        buttonState={!this.isFormValid(formProps.values) ? 'disabled' : ''}

                                                        onClick={() => {
                                                            if (formProps.isValid) {
                                                                formProps.submitForm();
                                                            }
                                                        }}
                                                    buttonText="Submit" />
                                                    
                                                    <div className="action-link-container">
                                                        <EventsLink style="action-link" to={op.get(this.props, 'flowData.actions.addressConfirmation.cancel', '')}>
                                                            Cancel
                                                        </EventsLink>
                                                    </div>
                                                </div>
                                            )
                                            : (
                                                <div className="page-actions">
                                                    <Button
                                                        style={this.props.numericWindowSize < 3 ? 'full' : ''}
                                                       // buttonState={(!formProps.dirty && isEmpty(formProps.touched)) || !formProps.isValid ? 'disabled' : ''}

                                                        buttonState={!this.isFormValid(formProps.values) ? 'disabled' : ''}
                                                        
                                                       onClick={() => {
                                                            if (formProps.isValid) {
                                                                formProps.submitForm();
                                                            }
                                                        }}
                                                        buttonText="Next" />
                                                    
                                                    <div className="action-link-container">
                                                        <EventsLink style="action-link" to={op.get(this.props, 'flowData.actions.addressConfirmation.previous', '')}>
                                                            Previous
                                                        </EventsLink>
                                                    </div>
                                                    
                                                    <div className="action-link-container">
                                                        <EventsLink style="action-link" to={op.get(this.props, 'flowData.actions.addressConfirmation.cancel', '')}>
                                                            Cancel
                                                        </EventsLink>
                                                    </div>
                                                </div>
                                            )
                                        }
                                      

                                    </Form>
                                )
                            }}
                        </Formik>
                    </div>
                </div>
                {this.getLoadingIndicator()}
            </div>
        );
    }
};
 
export default connect(mapStateToProps, mapDispatchToProps)(AddressConfirmationForm);
 
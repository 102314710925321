/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import MeasurementUtils from 'appdir/lib/analytics';

const mapStateToProps = (state, props) => {
	return {
		...state['ArticleLeadImage'],
		EventsWindow: state['WindowSize'].EventsWindow,
		...props,
	};
};
const mapDispatchToProps = (dispatch, props) => ({
	showVod: ({ videoId, title }) =>
		dispatch(deps.actions.VodModal.show({ videoId, title })),
});

/**
 * -----------------------------------------------------------------------------
 *  Component: ArticleLeadImage
 * -----------------------------------------------------------------------------
 */
class ArticleLeadImage extends Component {
	constructor(props) {
		super(props);
	}

	getImageStyle(imageUrl) {
		return {
			backgroundImage: `url(${imageUrl})`,
		};
	}

	calculateContainerStyle() {
		if (this.props.EventsWindow.windowSize !== 'desktop') {
			return this.props.videoId
				? 'article-image-container mobile video'
				: 'article-image-container mobile';
		}
		return this.props.videoId
			? 'article-image-container video'
			: 'article-image-container';
	}
	render() {
		logger.log('[ArticleLeadImage] - render this.props:%o', this.props);
		return (
			<div
				className={this.calculateContainerStyle()}
				onClick={() => {
					if (this.props.videoId) {
						logger.log(
							'[ArticleLeadImage] - this.props: %o',
							this.props
						);
						this.props.playVod(
							this.props.videoId,
							this.props.videoTitle
						);
					}
				}}>
				<section
					className="image-wrapper article-image"
					style={this.getImageStyle(this.props.image)}>
					{this.props.caption && this.props.caption != '' ? (
						this.props.captionVisible ? (
							<div
								className="caption"
								tabIndex={0}
								onClick={() => {
									MeasurementUtils.dispatchMeasurementCall('articleHeroCaption', { 
										caption: this.props.captionVisible ? 'Hide' : 'Show'
									});
									this.props.toggleArticleCaption();
								}}
								onKeyPress={() =>
									event.key === 'Enter'
										? this.props.toggleArticleCaption()
										: null
								}>
								{this.props.caption}
								{this.props.credit
									? ` (Photo by ${this.props.credit})`
									: null}
							</div>
						) : (
							<div
								className="icon"
								tabIndex={0}
								onClick={() => {
									MeasurementUtils.dispatchMeasurementCall('articleHeroCaption', { 
										caption: this.props.captionVisible ? 'Hide' : 'Show'
									});
									this.props.toggleArticleCaption();
								}}
								onKeyPress={() =>
									event.key === 'Enter'
										? this.props.toggleArticleCaption()
										: null
								}
							/>
						)
					) : null}
				</section>
				{this.props.videoId ? (
					<div className="centered_icon play" />
				) : null}
			</div>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ArticleLeadImage);

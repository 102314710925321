/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import EventsLink from 'shared/general/EventsLink';
import Button from 'shared/general/Button';
import deps from 'dependencies';

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: Logout Button
 * -----------------------------------------------------------------------------
 */
const LogoutButton = props => {
    const dispatch = useDispatch();
   // dispatch(deps.actions.UserData.logout());
   const userData = useSelector(state => state['UserData']?.userData);
   const flowData = useSelector(state => state['Config']?.staticData?.flow?.data);

   logger.log('[LogoutButton] userData: %o, flowData: %o', userData, flowData);

   const onLogoutHere = () => {
        logger.log('[LogoutButton] onLogoutHere');
        logger.log('[LogoutButton] onLogoutHere - userData: %o, flowData: %o', userData, flowData);

        if (userData?.token) {
            dispatch(deps.actions.UserData.logoutUser());
        } else {
            window.location.href=flowData.actions.general.signin
        }
   }
    if (props.type !== 'button') {
        return (
            <div className="action-link-container">
                <EventsLink info="logout" onClick={() => onLogoutHere()} style="action-link">
                    Logout
                </EventsLink>
            </div>
        );
    } else {
        return (
            <Button buttonText="Logout" style="full" onClick={() => onLogoutHere()} />
        )
    }
};

export default LogoutButton;
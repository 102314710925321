/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import { Component } from 'react';
import op from 'object-path';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import { isEmpty } from 'lodash';

/**
 * -----------------------------------------------------------------------------
 * React Component: Config
 * -----------------------------------------------------------------------------
 */

export default class Config extends Component {
	constructor(props) {
		super(props);
		//logger.log('[Config] constructor - props:%o', this.props);
	}

	componentDidMount() {
		//logger.log('[Config] componentDidMount');
		//this.props.update();

		const { mount } = this.props;

		mount();
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log('[Config] componentDidUpdate - props:%o', this.props);

		if (this.props.status !== prevProps.status && this.props.status === 'expired') {
			logger.log('[Config] componentDidUpdate - status:%o', this.props.status);
			this.props.update();
		}

		if (op.get(this.props, 'staticData', false)) {
			//logger.log('[Config] componentDidUpdate - staticData:%o', Object.keys(this.props.staticData).length);
			for (let item in this.props.staticData) {
				let updated = op.get(this.props.staticData[item], 'updated', 0);
				let loadDiff = moment().diff(moment(updated), 'seconds', true);
				//expired if diff greater then refresh and
				//  has valid refresh time with data loaded
				let data = op.get(this.props.staticData[item], 'data', false);
				if (!data && !this.props.staticData[item]) {
					data = this.props.staticData[item]
				}
				let expired = loadDiff >= this.props.staticData[item].refresh;
				if (expired && data && this.props.staticData[item].refresh < 0) {
					expired = false;
				}

				if (expired && op.get(this.props.staticData[item], 'status', '') != 'loading') {
					logger.log(
						'[Config] componentDidUpdate - staticData update - item:%o data:%o expired:%o, status:%o',
						item,
						this.props.staticData[item],
						expired,
						op.get(this.props.staticData[item], 'status')
					);
					this.props.updateStatic(item);
				}
			}
		}

		if (this.props.status === 'loaded' && op.get(this.props.userData) && !op.get(this.props, 'eventConfig.status')) {
			logger.log('[Config] componentDidUpdate load eventConfig');
			this.props.updateEventConfig();
		}

	}

	render() {
		return null;
	}
}

import axios from 'axios';
import { restHeaders } from 'appdir/components/general/Util';

const fetch = path => {
    let hdr = restHeaders();
    return axios
        .get(path, { headers: hdr })
        .then(response => {
            if (response.status === 200) {
                return response.data;
            }
        })
        .catch(error => {
            //logger.log('[ContentInfo] fetch - error:%o', error);
            if (error.response && error.response.status == 404) {
                //logger.log('[ContentInfo] fetch - error1:%o', error.response);
                throw error.response;
            } else {
                //logger.log('[ContentInfo] fetch - error2:%o', error);
                throw error;
            }
        });
};

export default {
    fetch,
};

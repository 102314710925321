import deps from 'dependencies';
import axios from 'axios';
import MeasurementUtils from 'appdir/lib/analytics';
import { getPlatformID } from 'shared/forms/elements/Utils';
import op from 'object-path';

import { checkJwtStatus } from './Util';
let refreshAttempts = 0;
let refreshRetries = 3; // default to 3;
let retryTimout;

export default {
	mount: () => (dispatch, getState, store) => {
		logger.log('[UserData] actions -  mount');
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			dispatch({ type: deps.actionTypes.USERDATA_MOUNT, data: {configLoaded:true} });
		});
	},
	updatePatronData: (patron_data, measure_string) => (dispatch, getState, store) => {
		logger.log('[UserData] actions -  updatePatronData - patron_data:%o', patron_data);

		let url = getState()['Config'].ticketsData.patronInfo;
	  	let userData = getState()['UserData'].userData;
		let tempUserData = getState()['UserData'].tempUserData;
		let pendingEmail = getState()['UserData'].pendingEmail;

		logger.log('[UserData] actions -  updatePatronData - userData:%o, tempUserData:%o, pendingEmail', userData, tempUserData, pendingEmail);

		let newData;
		let isEmailChange = false

		if (pendingEmail && tempUserData?.email && pendingEmail.toLowerCase() !== tempUserData.email.toLowerCase()) {
			isEmailChange = true;
			patron_data.email = getState()['UserData'].tempUserData.email;

		}
		
		logger.log('[UserData] actions -  updatePatronData - isEmailChange:%o', isEmailChange);
		
		return axios({
			method: 'post',
			url: url,
			data: {
				...patron_data,
				'patronId': userData.patronId,
			},
			headers: { Authorization: `Bearer ${userData.token}` },
		})
			.then(patronRes => {
				logger.log('[UserData] actions -  updatePatronData patronRes:%o', patronRes);

				newData = {
					...patronRes.data,
				};

				if (isEmailChange) {
					newData.email = userData.email
					newData.pendingSubmitted = true
				} else {
					newData.pendingSubmitted = false
				}
				if (measure_string) {
					MeasurementUtils.dispatchMeasurementCall(measure_string, {
						response: 'success',
						responseData: patronRes,
						webview: window.webview ? true : false,
					});
				}

				dispatch({ type: deps.actionTypes.USERDATA_UPDATE_USER_DATA, data: newData, ...(pendingEmail && {pendingEmail}) });
				dispatch({ type: deps.actionTypes.USERDATA_UPDATE_TEMP_USER_DATA});
				return patronRes;
			})
			.catch(error => {
				logger.log('[UserData] actions -  updatePatronData error updating patron data:%o', error.response);

				if (measure_string) {
					MeasurementUtils.dispatchMeasurementCall(measure_string, {
						response: 'error',
						responseData: error,
						webview: window.webview ? true : false,
					});
				}
				return error.response;
			});
	},
	updateApplicationData: (data, measure_string) => (dispatch, getState, store) => {
		logger.log('[UserData] actions -  updateApplicationData - data:%o', data);

		let url = getState()['Config'].ticketsData.application;
		let userData = getState()['UserData'].userData;
		let newData;

		logger.log('[UserData] actions -  updateApplicationData - userData:%o', userData);

		return axios({
			method: 'post',
			url: url,
			data: {
				...data,
				'patronId': userData.patronId,
			},
			headers: { Authorization: `Bearer ${userData.token}` },
		})
			.then(applicationRes => {
				logger.log('[UserData] actions -  updateApplicationData applicationRes:%o', applicationRes);

				newData = {
					...userData,
					application: {
						...userData.application,
						...applicationRes.data,
					}
				};

				if (measure_string) {
					MeasurementUtils.dispatchMeasurementCall(measure_string, {
						response: 'success',
						responseData: applicationRes,
						webview: window.webview ? true : false,
					});
				}

				dispatch({ type: deps.actionTypes.USERDATA_UPDATE_USER_DATA, data: newData });
				dispatch({ type: deps.actionTypes.USERDATA_UPDATE_TEMP_USER_DATA});

				return applicationRes;
			})
			.catch(error => {
				logger.log('[UserData] actions -  updateApplicationData error updating application data:%o', error.response);

				if (measure_string) {
					MeasurementUtils.dispatchMeasurementCall(measure_string, {
						response: 'error',
						responseData: error,
						webview: window.webview ? true : false,
					});
				}
				return error.response;
			});
	},
	updateUserData: (data, refreshToken = false) => (dispatch, getState, store) => {
		logger.log('[UserData] actions -  updateUserData - data:%o', data);
		// set token to the same as secureJwt so that we don't have to change
		// it everwhere to secureJwt
		//data.secureJwt ? data.token = data.secureJwt : null;

		let userData = getState()['UserData'].userData;
		let tempUserData = getState()['UserData'].tempUserData;

		let isEmailChange = tempUserData?.email && (data?.email && data.email.toLowerCase() !== tempUserData.email.toLowerCase());
		let pendingEmail = false;

		logger.log('[UserData] actions -  updateUserData - userData:%o', userData);
		
		if (isEmailChange) {
			logger.log('[UserData] actions -  updateUserData - set pendingEmail');
			pendingEmail = data.email.toLowerCase();
			data.email = tempUserData.email.toLowerCase();
		}
		logger.log('[UserData] actions -  updateUserData - tempUserData:%o', tempUserData);

		let jwtExpireStatus = 'expired';

		if(data.secureJwt) {
			data.token = data.secureJwt;
			jwtExpireStatus = checkJwtStatus(data.secureJwt, getState()['Config'].ticketsData.jwtWillExpire);
		} else if (userData.secureJwt) {
			jwtExpireStatus = checkJwtStatus(userData.secureJwt, getState()['Config'].ticketsData.jwtWillExpire);
		} 
		
		logger.log("[UserData] actions -  updateUserData jwtExpireStatus: %o, refreshToken:%o", jwtExpireStatus, refreshToken);

		if (jwtExpireStatus == 'expired') {
			logger.log('[UserData] actions -  updateUserData - no jwtExpirationtime or expired');
			dispatch({ type: deps.actionTypes.USERDATA_LOGOUT_USER, data: data });
		}
		else if (jwtExpireStatus == 'refresh' || (!jwtExpireStatus && refreshToken)) {
			let url = getState()['Config'].ticketsData.getRefreshToken;

			logger.log('[UserData] actions -  actions -  updateUserData - about to expire, so refresh ');

			return axios({
				method: 'post',
				url: url,
				data: {
					//...data,
					'provider': getState()['Config'].ticketsData.provider,
					'token': data.secureRefreshToken ? data.secureRefreshToken : userData.secureRefreshToken,
				},
				headers: { Authorization: `Bearer ${userData.token}` },
			})
			.then(res => {
				logger.log('[UserData] actions -  updateUserData - res:%o', res);
				if (res.status == 403) {
					dispatch({ type: deps.actionTypes.USERDATA_LOGOUT_USER, data: data });
				} else {
					logger.log('[UserData] actions -  updateUserData - old:%o', data.secureJwt);
					logger.log('[UserData] actions -  updateUserData - new:%o', res.data.secureJwt);

					data.token = res.data.secureJwt;
					data.secureJwt = res.data.secureJwt;
					data.secureRefreshToken = res.data.secureRefreshToken;

					logger.log('[UserData] actions -  updateUserData - pendingEmail:%o', pendingEmail);

					dispatch({ type: deps.actionTypes.USERDATA_UPDATE_USER_DATA, data: data, ...(pendingEmail && {pendingEmail}) });
					return res;
				}
			})
			.catch(error => {
				logger.log('[UserData] actions -  updateUserData error updating user data:%o', error.response);
				dispatch({ type: deps.actionTypes.USERDATA_LOGOUT_USER, data: data });
				return error.response;
			}); 
		} 
		else {
			logger.log('[UserData] actions -  updateUserData - not expired data:%o', data);
			dispatch({ type: deps.actionTypes.USERDATA_UPDATE_USER_DATA, data: data, ...(pendingEmail && {pendingEmail}) });
		}
	},
	logoutUser: data => (dispatch, getState, store) => {
		// MeasurementUtils.dispatchMeasurementCall('Logout', {});
		dispatch({ type: deps.actionTypes.USERDATA_LOGOUT_USER, data: data });
	},
	refreshProfile: () => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			// do axios call to pull the profile
			let userData = getState()['UserData'].userData;
			let patronUrl = getState()['Config'].ticketsData.patronInfo;
			let applicationUrl = getState()['Config'].ticketsData.application;

			let data = {};
			logger.log(
				'[UserData] actions refreshProfile refreshRetries: %o, refreshAttempts:%o',
				refreshRetries,
				refreshAttempts
			);

			if (userData.token) {
				axios({
					method: 'get',
					url: patronUrl,
					headers: {Authorization: `Bearer ${userData?.secureJwt}` },
					//headers: {Authorization: `Bearer ${loginRes.data.token}` },
				})
					.then(patronRes => {
						data = {
							...data,
							...patronRes.data,
						};
						logger.log('[UserData] actions refreshProfile data:%o', data);

						return axios({
							method: 'get',
							url: applicationUrl,
							headers: {Authorization: `Bearer ${userData?.secureJwt}` },
						});
					})
					.then(applResp => {
						//logger.log('[UserData] refreshProfile profileResp:%o', profileResp);
						logger.log('[UserData] actions refreshProfile applResp:%o', applResp);
						data = {
							...data,
							application:{...applResp.data},
							...(userData.jwts && { jwts: userData.jwts }), // allows you to add this only if pendingEmail exists
						};
						// logger.log('[UserData] refreshProfile data:%o', data);
						// No need to call this anymore.  secureJwt token will expire on its own.
						// dispatch(deps.actions.UserData.refreshJwt());

						dispatch({ type: deps.actionTypes.USERDATA_REFRESH, data });
					})
					.catch(error => {
						logger.error('[UserData] actions refreshProfile error fetching profile data:%o', error);
						// retry fetching profile x number of times because there is a db that replicates
						// the token across locations.  that takes time so, let's retry the refresh a few
						// times to give replication some time to finish
						if (refreshAttempts <= refreshRetries) {
							logger.error(
								'[UserData] refreshProfile error fetching profile refreshAttempts:%o',
								refreshAttempts
							);
							refreshAttempts++;
							dispatch(deps.actions.UserData.refreshProfile());
						} else {
							logger.error('[UserData] refreshProfile error fetching profile logout user');
							dispatch({ type: deps.actionTypes.USERDATA_LOGOUT_USER, data: data });
						}
					});
			} else {
				logger.warn('[UserData] refreshProfile no userData in redux');
			}
		});
	},
	updateTempUserData: () => (dispatch) => {
		logger.log('[UserData] actions -  updateTempUserData');
		dispatch({ type: deps.actionTypes.USERDATA_UPDATE_TEMP_USER_DATA});
	},
	deleteTempUserData: () => (dispatch) => {
		logger.log('[UserData] actions -  deleteTempUserData');
		dispatch({ type: deps.actionTypes.USERDATA_DELETE_TEMP_USER_DATA});
	},
	completeEmailChange: (emailChangeToken) => (dispatch, getState, store) => {
		// NOT SURE IF I'M GOING TO USE THIS YET
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			let url = Config.ticketsData.completeEmailChange;

			axios({
				method: 'post',
				url: `${url}token=${emailChangeToken}`,
				data: {
					provider: getState()['Config'].ticketsData.provider
				}
				//headers: { Authorization: `Bearer ${userData.token}` },
			}).then(completeEmail => {
				logger.log('[UserData] actions -  completeEmailChange completeEmail:%o', completeEmail);
				//dispatch({ type: deps.actionTypes.USERDATA_INITIATE_EMAIL_CHG, data: newData, pendingEmail });
			}).catch(e => {
				logger.log('[UserData] actions -  completeEmailChange Error:%o', e);
	
			})
		})
	},
};
import deps from 'dependencies';

export default (state = {}, action) => {
    let newState;

    switch (action.type) {
        case deps.actionTypes.CONTENT_LOAD:
            newState = {
                ...state,
                ...action.data,
            };

            logger.log('[ContentInfo] mount - action: %o', newState);
            return newState;

        default:
            return state;
    }
};

import PaymentContent from './PaymentContent';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';

const mapStateToProps = (state, props) => {
	return {
		...state.Payment,
		dataSettings: state['Config'].dataSettings,
		siteConfigStatus: state['Config'].status,
		staticDataStatus: op.get(state['Config'], 'staticData.cmsMessages.status'),
		ticketsData: op.get(state['Config'], 'ticketsData'),
		applicationConfig: op.get(state['Config'], 'application'),
		paymentConfig: op.get(state['Config'], 'payment'),
		messageSettings: op.get(state['Config'], 'staticData.cmsMessages.data'),
		userData: op.get(state['UserData'], 'userData', {}),
		tempUserData: state['UserData']?.tempUserData,
		eventConfig: op.get(state['Config'], 'eventConfig.data', {}),
		pageTitle: state?.Config?.otherData?.pageTitle,
		flowData: op.get(state['Config'], 'staticData.flow.data'),
		paymentMessages: state?.Config?.staticData?.messages?.data,
		Router: state['Router'],
		secureJwt: op.get(state['UserData'], 'userData.secureJwt'),
		requiresVerifyId: op.get(state['Config'], 'addressValidation.requiresVerifyId', {}),
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	loadConfig: () => dispatch(deps.actions.Config.loadConfig()),
	updateUserData: (data, refreshToken) => dispatch(deps.actions.UserData.updateUserData(data, refreshToken)),
	refreshProfile: () => dispatch(deps.actions.UserData.refreshProfile()),
	updateTempUserData: () => dispatch(deps.actions.UserData.updateTempUserData()),
	mount: () => dispatch(deps.actions.Config.mount()),
	logout: () => dispatch(deps.actions.UserData.logoutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentContent);

import React from 'react';

/**
 * -----------------------------------------------------------------------------
 * React Component: PullQuote
 * -----------------------------------------------------------------------------
 */

export default ({ data = {} }) => {
	const { style, text, author } = data;
	return (
		<div className="text centered extra_spacing">
			<p className={`${style} pullquote`}>
				{text}
				<span className="close-quote" />
				<div className="credit">{author}</div>
			</p>
		</div>
	);
};

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import { Redirect } from 'react-router';
import { Helmet } from 'react-helmet';
import deps from 'dependencies';

import Header from './general/Header';
import Footer from './general/Footer';
import LoadingIndicator from 'shared/general/LoadingIndicator';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import UnderConstruction from 'shared/general/UnderConstruction';
import { isLegacy, getRedirectPath } from 'shared/general/Util';
const op = require('object-path');
import ErrorBoundary from 'shared/general/ErrorBoundary';
import PrintHeader from './general/PrintHeader';

/**
 * -----------------------------------------------------------------------------
 * React Component: Template (has header and footer)
 * -----------------------------------------------------------------------------
 */

const Template = props => {
	const dispatch = useDispatch();

	const configStatus = useSelector(state => state['Config']?.status);
	const staticStatus = useSelector(state => state['Config']?.staticData?.cmsMessages?.status);

	logger.log('[Template] configStatus:%o, staticStatus:%o', configStatus, staticStatus);

	// if (configStatus !== 'loaded' || staticStatus !== 'loaded') {
	// 	dispatch(deps.actions.Config.loadConfig());
	// }
	const description = op.get(props, 'data.description')
		? props.data.description
		: `The Official Ticketing Site of the Masters Golf Tournament 2022.  This major golf tournament is played annually at the Augusta National Golf Club.`;
	const keywords = op.get(props, 'data.keywords')
		? props.data.keywaords
		: 'Masters Tournament 2022, Augusta National Golf Club, ANGC, Masters Champions, Amen Corner, Augusta, Georgia, Chairman Billy Payne, Tiger Woods, Phil Mickelson, Lee Westwood, Anthony Kim, K.J. Choi, birdie, eagle';
	const Config = useSelector(state => state['Config']?.staticData?.cmsMessages?.data);
	const bypass = useSelector(state => state['Config']?.bypass);
	const Route = useSelector(state => state['Router']);
	const EventsWindow = useSelector(state => state['WindowSize']?.EventsWindow);
	const eventConfig = useSelector(state => state['Config']?.eventConfig?.data);

	const seoDescription = Config?.['general.website_seo_description'];
	let isLegacyRoute = isLegacy(Route);
	const title =
		Config && Config['general.website_title']
			? Config['general.website_title']
			: `Drive Chip and Putt National Finals Tickets`;

	//console.log('[Template] props:%o, Route:%o', props, Route);

	if (Route?.params?.display == 'print') {
		document.body.classList.add('print');
	}
	const underConstructionImage = {
		image: {
			alt: '',
		},
		imageList: {
			default: '/assets/images/misc/leaderboard_stub_lg.jpeg',
			small: '/assets/images/misc/leaderboard_stub_sm.jpeg',
			medium: '/assets/images/misc/leaderboard_stub_lg.jpeg',
			high: '/assets/images/misc/leaderboard_stub_lg.jpeg',
		},
	};
	logger.log('[Template] props:%o, Route:%o', props, Route);

	if (isLegacyRoute?.isLegacy) {
		return <Redirect push to={getRedirectPath(Route['params'], isLegacyRoute['legacyParam'])} />;
	} else if (configStatus == 'loaded' && Config && EventsWindow) {
		if (Config['system.stubbed'] == 'false' || bypass == true) {
			return (
				<>
					<ErrorBoundary redirect="/en_US/uhOh/index.html">
						<Helmet>
							<title>{title}</title>
							<meta name="description" content={seoDescription} />
							<meta name="keywords" content={keywords} />
							<link rel="icon" href="/assets/images/favicon.ico" />
							{props.script ? <script src={props.script}></script> : null}
						</Helmet>
						<a className="skip-main" href="#main">
							Skip to main content
						</a>
						{/* <Header /> */}
						{Route?.params?.display != 'print' ? <Header /> : null}
						<section id="main" role="main" tabIndex="-1">
							{Route?.params?.display == 'print' ? <PrintHeader /> : null}
							{props.children}
							{/* <Footer year={`2022`} /> */}
							{/** only for non-print pages */}
							{Route?.params?.display != 'print' ? (
								<>
									<Footer year={`2022`} />
								</>
							) : null}
						</section>
					</ErrorBoundary>
				</>
			);
		} else {
			return (
				<>
					<Helmet>
						<title>{title}</title>
						<meta name="description" content={seoDescription} />
						<meta name="keywords" content={keywords} />
						<link rel="icon" href="/assets/images/favicon.ico" />
						{props.script ? <script src={props.script}></script> : null}
					</Helmet>
					<UnderConstruction attributes={{ underConstructionImage }} />
				</>
			);
		}
	} else {
		return <LoadingIndicator />;
	}
};

Template.defaultProps = {};

export default Template;

import ContentPageContent from './ContentPageContent';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';

const mapStateToProps = (state, props) => {
	return {
		...state.ContentPageContent,
		content: state.ContentInfo,
		siteConfigStatus: state['Config'].status,
		cmsConfig: state['Config'].cmsData,
		Router: state['Router'],
		flowData: op.get(state['Config'], 'staticData.flow.data', {}),
		userData: op.get(state['UserData'], 'userData', {}),
		pageTitle: state?.Config?.otherData?.pageTitle,
		site: state?.Config?.dataSettings?.site,
		eventConfig: op.get(state['Config'], 'eventConfig.data', {}),
    	messageSettings: op.get(state['Config'], 'staticData.cmsMessages.data'),
		requiresVerifyId: op.get(state['Config'], 'addressValidation.requiresVerifyId', {}),
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	loadConfig: () => dispatch(deps.actions.Config.loadConfig()),
	mount: () => dispatch(deps.actions.Config.mount()),
	loadContent: path => dispatch(deps.actions.ContentInfo.load(path, 'content-page-content')),
	updatePatronData: data => dispatch(deps.actions.UserData.updatePatronData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContentPageContent);

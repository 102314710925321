/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import AddressVerificationContent from './AddressVerificationContent';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';

/**
 * -----------------------------------------------------------------------------
 * React Component: AddressVerificationContent
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state.AddressVerificationContent,
		ticketsData: op.get(state['Config'], 'ticketsData', {}),
		userData: op.get(state['UserData'], 'userData', {}),
		tempUserData: op.get(state['UserData'], 'userData'),
		flowData: op.get(state['Config'], 'staticData.flow.data', {}),
		invalidStatusIds: op.get(state['Config'], 'addressValidation.invalidStatusIds', {}),
		validStatusIds: op.get(state['Config'], 'addressValidation.validStatusIds', {}),
		requiresPin: op.get(state['Config'], 'addressValidation.requiresPin', {}),
		pageTitle: state?.Config?.otherData?.pageTitle,
		site: state?.Config?.dataSettings?.site,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	updateUserData: data => dispatch(deps.actions.UserData.updateUserData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressVerificationContent);

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import JSXParser from 'react-jsx-parser';
import ErrorBoundary from 'shared/general/ErrorBoundary';

/**
 * -----------------------------------------------------------------------------
 * React Component: MastersImage
 *
 *  ****   props example ****
 * imageConfig = {
 * 		image : {
 * 			alt: ""
 *			background: true
 *			className: "hero-bkg"
 *			style: {height: "calc(100% - 0px)", backgroundImage: "url(http://www.masters.com/images/pics/large/h_DM23730.jpg)"}
 * 		}
 * 		imageList : {
 * 			default: "http://www.masters.com/images/pics/large/h_DM23730.jpg"
 *			large: "http://www.masters.com/images/pics/large/h_DM23730.jpg"
 *          medium: "http://www.masters.com/images/pics/large/h_DM23730.jpg"
 *          small: "http://www.masters.com/images/pics/large/b_DM23730.jpg"
 *          smallPortrait: "http://www.masters.com/images/pics/large/s_DM23730.jpg"
 * 		}
 * }
 * -----------------------------------------------------------------------------
 */

export default class MastersImage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			//...this.props,
		};
	}

	componentDidMount() {
		// logger.log('[MastersImage] - componentDidMount props:%o', this.props);
		if (this.props.hasOwnProperty('mount')) {
			this.props.mount();
		}
	}
	componentDidUpdate(prevState) {
		// logger.log('[MastersImage] - componentDidUpdate props:%o', this.props);
	}

	renderImageBackground() {
		let { imageConfig } = this.props;
		let { image } = imageConfig;
		let { imageList } = imageConfig;
		let style = image.style ? image.style : {};
		let text = imageConfig.imageText ? imageConfig.imageText : null;
		let { EventsWindow } = this.props;
		let basePicPath = this.props.basePicPath ? this.props.basePicPath : '';
		logger.log('[MastersImage] - renderImageBackground style:%o', style);
		//debugger;
		let defaultSrc = imageList['default'];
		let newSrc;
		let src;

		if (EventsWindow.breakpoint == 'xsmall') {
			if (EventsWindow.orientation == 'portrait') {
				//xlarge image has square image in it
				newSrc = imageList['xlarge']
					? imageList['xlarge']
					: imageList['small'];
			} else {
				newSrc = imageList['small'];
			}
			newSrc = newSrc ? newSrc : imageList['small'];
		} else if (EventsWindow.breakpoint == 'small') {
			newSrc = imageList['small'];
			newSrc = newSrc ? newSrc : imageList['small'];
		} else if (EventsWindow.breakpoint == 'medium') {
			newSrc = imageList['medium'];
		} else if (EventsWindow.breakpoint == 'large') {
			newSrc = imageList['large']
				? imageList['large']
				: imageList['high'];
		} else if (EventsWindow.breakpoint == 'xlarge') {
			newSrc = imageList['large']
				? imageList['large']
				: imageList['high'];
		}

		src = newSrc ? newSrc : defaultSrc ? defaultSrc : false;

		style.backgroundImage =
			src.indexOf('//') == -1
				? `url(${basePicPath}${src})`
				: `url(${src})`;

		logger.log('[MastersImage] - renderImageBackground style:%o', style);

		if (text) {
			return style.backgroundImage ? (
				<div className={image.className} style={style}>
					<div className="hero-image-text">
						<ErrorBoundary message="Unable to render content."><JSXParser jsx={text} /></ErrorBoundary>
					</div>
				</div>
			) : null;
		} else {
			return style.backgroundImage ? (
				<div className={image.className} style={style} />
			) : null;
		}
	}

	renderImage() {
		// logger.log('[MastersImage] - renderImage');

		let { imageConfig } = this.props;
		let basePicPath = this.props.basePicPath ? this.props.basePicPath : '';
		let { image } = imageConfig;
		let { imageList } = imageConfig;
		let style = image.style ? image.style : {};
		let { EventsWindow } = this.props;
		let defaultSrc = imageList['default'];
		let newSrc;
		let src;

		if (EventsWindow.breakpoint == 'xsmall' && !imageList['xsmall']) {
			// if (EventsWindow.breakpoint == 'xsmall') {
			if (EventsWindow.orientation == 'portrait') {
				newSrc = imageList['xlarge']
					? imageList['xlarge']
					: imageList['small'];
			}
			// once width > height, orientation changes to landscape
			else {
				if (imageList['xsmall']) {
					//if there is an xsmall image defined, use xsmall image
					newSrc = imageList['xsmall'];
				} else {
					//if no xsmall image defined, use small image
					newSrc = imageList['small'];
				}
			}
			newSrc = newSrc ? newSrc : imageList['small'];
		} else if (EventsWindow.breakpoint == 'xsmall' && imageList['xsmall']) {
			newSrc = imageList['xsmall'];
			newSrc = newSrc ? newSrc : imageList['small'];
		} else if (EventsWindow.breakpoint == 'small') {
			newSrc = imageList['small'];
			newSrc = newSrc ? newSrc : imageList['small'];
		} else if (EventsWindow.breakpoint == 'medium') {
			newSrc = imageList['medium'];
		} else if (EventsWindow.breakpoint == 'large') {
			newSrc = imageList['large']
				? imageList['large']
				: imageList['high'];
		} else if (EventsWindow.breakpoint == 'xlarge') {
			newSrc = imageList['large'];
		}

		src = newSrc ? newSrc : defaultSrc ? defaultSrc : false;

		return src ? (
			<img
				src={src.indexOf('//') == -1 && !imageConfig.useLocal ? `${basePicPath}${src}` : src}
				className={image.className}
				style={style}
				alt={`${image.alt == undefined ? 'Masters image' : image.alt}`}
			/>
		) : null;
	}

	render() {
		// logger.log('[MastersImage] render props:%o', this.props);
		let { imageConfig } = this.props;
		let { image } = imageConfig;
		let { imageList } = imageConfig;

		if (
			this.props.basePicPath !== null &&
			imageConfig &&
			image &&
			imageList &&
			this.props.EventsWindow
		) {
			if (image.background) {
				return this.renderImageBackground();
			} else {
				return this.renderImage();
			}
		} else {
			return null;
		}
	}
}

MastersImage.defaultProps = {};

import deps from 'dependencies';
import { parseString } from 'xml2js';
import { validateData } from 'components/general/Util';
import uniq from 'lodash/uniq';
import MeasurementUtils from 'appdir/lib/analytics';

const getComponentListArray = list => {
	let comps = [];

	if (validateData(list, 'array')) {
		list.forEach(item => {
			if (item.hasOwnProperty('type')) {
				comps.push(item['type']);
			} else {
				//logger.log('[ContentPage] getComponentList - removing: %o:', item['reference']);
			}
		});
	} else if (validateData(list, 'json')) {
		comps.push(list['type']);
	}

	comps = uniq(comps);
	logger.log('[ContentInfo] getComponentListArray - ', { list, comps });
	return comps;
};

const getAttributesArray = list => {
	let attr = {};

	if (validateData(list, 'array')) {
		list.forEach(item => {
			if (item.hasOwnProperty('type')) {
				attr[item['reference']] = item;
			} else {
				//logger.log('[ContentPage]  getAttributesArray - removing: %o:', item['reference']);
			}
		});
	} else if (validateData(list, 'json')) {
		attr[list['reference']] = list;
	}
	//logger.log('[ContentPage]  getAttributesArray - list: %o:', list);

	return attr;
};

export default {
	load: (path, zone = 'jsx-content-page') => (dispatch, getState) => {
		return deps.services.ContentInfo.fetch(path).then(content => {
			// logger.log('[ContentPage] - componentDidUpdate content:%o', content);
			let site = getState()['Config'].dataSettings.site;
			let Router = getState() ['Router'];
			logger.log('[ContentPage] - actions load Router:%o', Router);

			//set the xml parse options
			let options = {
				explicitArray: false,
				normalize: true,
				trim: true,
				mergeAttrs: true,
				valueProcessors: [],
			};

			//parse the xml
			parseString(content, options, function(err, json) {
				content = json.contentItem;
			});

			//clean the jsx block
			content.jsx = content.jsx
				.replace(/data="{(.*?)}"/gi, 'data={$1}')
				.replace(/class=/gi, 'className=');

			content.bindings = getAttributesArray(content.dependencies.data);

			getComponentListArray(content.dependencies.data).forEach(
				component =>
					dispatch(
						deps.actions.Plugable.addPlugin({
							id: `${component}-component`,
							component,
							zone,
							paths: ['cms/', 'common-ui/', 'data/'],
						})
					)
			);

			MeasurementUtils.dispatchMeasurementCall(
				MeasurementUtils.ACTION_TYPES.pageView,
				{
					content: content,
					site,
					category: Router?.params?.contentCategory ? Router?.params?.contentCategory : Router?.params?.category,
					action: Router?.params?.action ? Router?.params?.action : null
				}
			);

			dispatch({ type: deps.actionTypes.CONTENT_LOAD, data: content });
		});
	},
};

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import EventsLink from 'shared/general/EventsLink';

const SiteAlert = props => {
	return (
		<div className="site-alert">
			{props.text}
			{props.link ? <EventsLink to={props.link}>{props.linkText}</EventsLink> : null}
		</div>
	);
};

export default SiteAlert;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component, Fragment } from 'react';
import Template from 'components/Template';
import MastersImage from 'shared/general/MastersImage';
/**
 * -----------------------------------------------------------------------------
 * React Component: NotFound
 * -----------------------------------------------------------------------------
 */

const NotFound = props => {
	const { notFoundImage } = props;
	return (
		<Template>
			<section class="page-content hero notfound">
				<section class="hero-section content-page half-height">
					<MastersImage imageConfig={notFoundImage} />
				</section>
			</section>
			<section class="page-content">
				<article>
					<div class="full text centered extra_spacing">
						<h2>In The Second Cut</h2>
						<p class="centered">You've played a shot that is out of bounds.</p>
					</div>
				</article>
			</section>
		</Template>
	);
};

NotFound.defaultProps = {
	notFoundImage: {
		image: {
			alt: '',
		},
		imageList: {
			default: '/assets/images/headers/header_404.jpg',
			small: '/assets/images/headers/header_404_sm.jpg',
			medium: '/assets/images/headers/header_404_med.jpg',
			high: '/assets/images/headers/header_404.jpg',
		},
	},
};

export default NotFound;

export default {
	'pendingEmail':null, 
	'userData': {},
	'tempUserData':null,
	//persist: 2592000000
	// // currently persisting for 30 days
	// why is this not working

	persist: {
		'pendingEmail': 2592000000,
		'userData': 2592000000,
		'tempUserData': 2592000000,
	}
};

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import op from 'object-path';
import Template from 'components/Template';
import MeasurementUtils from 'appdir/lib/analytics';
import ContentHero from 'shared/cms/ContentHero';
import CmsMessageContent from 'shared/general/CmsMessageContent';
import Button from 'shared/general/Button';
import MagicLinkForm from 'shared/forms/MagicLinkForm';
import { isEmpty } from 'lodash';
import LoadingIndicator from 'shared/general/LoadingIndicator';

/**
 * -----------------------------------------------------------------------------
 * React Component: MagicLink
 * -----------------------------------------------------------------------------
 */

export default class MagicLinkContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			submitSuccess: false,
		};

		this.requestMagicLinkSubmit = this.requestMagicLinkSubmit.bind(this);
		this.pvLogged = false;
	}

	componentDidMount() {
		// logger.log('[MagicLinkContent] - componentDidMount this.props:%o', this.props);
		this.props.loadConfig();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.site && !this.pvLogged) {
			this.pvLogged = true;
			MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
				pageTitle: 'MagicLink',
				site: this.props.site,
			});
		}
	}

	requestMagicLinkSubmit(val) {
		this.setState({
			submitSuccess: val,
		});
	}

	render() {
		logger.log('[MagicLinkContent] render this:%o', this);

		return this.props.siteConfigStatus == 'loaded' &&
			op.get(this.props, 'EventsWindow') &&
			!isEmpty(this.props.flowData) ? (
			<Template>
				<section className="page-content">
					<section className="page-title">
						<h1>
							<CmsMessageContent id="general.page_header_title" textOnly={true} />
						</h1>
					</section>
					<section className="hero">
						<ContentHero />
					</section>

					{this.state.submitSuccess === true ? (
						<div className="form-container">
							<div className="form-content">
								<div className="form-title">
                  <CmsMessageContent id='login.secure_login_confirmation_header' />
                </div>

								<CmsMessageContent id="login.secure_login_confirmation_info" />
								<div className="page-actions">
									<Button
										style={this.props.EventsWindow.numericWindowSize < 2 ? 'full' : ''}
										to={this.props.flowData.actions.magicLink.signin}
										buttonText="Sign In"
									/>
								</div>
							</div>
						</div>
					) : (
						<MagicLinkForm
							ticketsData={this.props.ticketsData}
							EventsWindow={this.props.EventsWindow}
							onFormSubmit={this.requestMagicLinkSubmit}
							flowData={this.props.flowData}
						/>
					)}
				</section>
			</Template>
		) : (
			<Template>
				<section className="page-content">
					<section className="page-title">
						<h1>
							<CmsMessageContent id="general.page_header_title" textOnly={true} />
						</h1>
					</section>
					<section className="hero">
						<ContentHero />
					</section>
					<div className="form-container">
						<div className="form-content">
							<div className="form-title">
                <CmsMessageContent id='login.secure_login_header' />
              </div>
							<div className="fadeOverlay" ref={this.loadingRef}>
								<LoadingIndicator />
							</div>
						</div>
					</div>
				</section>
			</Template>
		);
	}
}

MagicLinkContent.defaultProps = {};

import React, { Component, Fragment, useState } from 'react';
import Steps from './steps';


export default function ProgressNavigation(props) {
  logger.log('[ProgressNavigation] props:%o', props);
  let data = [];
  
  if(props.showWinnerSplash){
    data.push(props.stepLabels)
  } else {
    let formatData = props.stepLabels.replaceAll(',','');
    data = formatData.split(" ");
    console.log("thisIsData:", data)
  }

  return(
    <div className="progressWrapper">
      {data.map((item, index) => <Steps numOfSteps={data.length} isActive={props.currentStep === index + 1} currentStep={props.currentStep} showWinnerSplash={props.showWinnerSplash} paymentSteps={props.paymentSteps} key={index} index={index} label={item}></Steps>)}
    </div>
  )
}
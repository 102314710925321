/**
 Config Initial State
*/

export default {
	status: null,
	refreshSec: 30,
	updated: '2018-01-01T12:00:00.000Z',
	staticLoad: false
};

import ClientRouter from 'dotix/components/Router';
import DevTools from 'dotix/components/DevTools';
import deps, { getComponents } from 'dependencies';
import { PlugableProvider } from 'dotix/components/Plugable';
import { Provider } from 'react-redux';
import createStore from 'dotix/createStore';
import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';

deps.init();

const store = createStore();

// Placeholder for the bindable elements
const bindPoints = [];

// <Component /> DOM Elements array
const elements =
	typeof document !== 'undefined' ? Array.prototype.slice.call(document.querySelectorAll('component')) : [];

if (elements.length > 0) {
	let types = [];

	let elms = elements.map(elm => {
		let path = elm.getAttribute('path');
		let type = elm.getAttribute('type');

		types.push(type);

		return { path, type };
	});

	let components = getComponents(elms);

	elements.forEach(elm => {
		// Get the component type
		let type = elm.getAttribute('type');

		if (!components.hasOwnProperty(type)) {
			return;
		}

		// Get parameters from container element
		let params = {};
		let exclude = ['type', 'path'];
		Object.entries(elm.attributes).forEach(([key, attr]) => {
			key = String(key).toLowerCase();
			if (exclude.indexOf(key) < 0) {
				return;
			}
			params[attr.name] = attr.value;
		});

		// Get the children from the element and pass them to the component
		let children = elm.innerHTML;
		if (children) {
			params['children'] = children;
		}

		// Create the React element and apply parameters
		let cmp = React.createElement(components[type], params);
		bindPoints.push({ component: cmp, element: elm });
	});
}

export const App = () => {
	if (bindPoints.length > 0) {
		bindPoints.forEach(item => {
			ReactDOM.render(
				<Provider store={store}>
					<PlugableProvider {...deps.plugableConfig}>
						<Fragment>{item.component}</Fragment>
					</PlugableProvider>
				</Provider>,
				item.element
			);
		});
	}

	let routerTarget = document.getElementById('root');

	ReactDOM.render(
		<Provider store={store}>
			<PlugableProvider {...deps.plugableConfig}>
				<DevTools />
				<ClientRouter />
			</PlugableProvider>
		</Provider>,
		routerTarget
	);
};

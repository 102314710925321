/**
 * -----------------------------------------------------------------------------
 * Imports
 * ----------------------------------------------------------------------------
 */
import { connect } from 'react-redux';
import deps from 'dependencies';
import UserData from './UserData';
import op from 'object-path';

/**
 * -----------------------------------------------------------------------------
 * Inject Redux State and Actions into React Component: ControlBar
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => ({
	...state.UserData,
	threshold: op.get(state['Config'], 'ticketsData.jwtWillExpire'),
	flowData: op.get(state['Config'], 'staticData.flow.data', {}),
	dataSettings: op.get(state['Config'], 'dataSettings'),
	Router: state['Router'],
	...props,
});

const mapDispatchToProps = dispatch => ({
	mount: () => dispatch(deps.actions.UserData.mount()),
	updateUserData: (data) => dispatch(deps.actions.UserData.updateUserData(data)),
	logout: () => dispatch(deps.actions.UserData.logoutUser()),

});

export default connect(mapStateToProps, mapDispatchToProps)(UserData);

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import op from 'object-path';
import axios from 'axios';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { FormPassword, FormConfirmPassword } from 'shared/forms/elements/FormFields';
import Button from 'shared/general/Button';
import { writeErrors } from 'shared/forms/elements/Utils';
import isEmpty from 'lodash/isEmpty';
import EventsLink from 'shared/general/EventsLink';
import { getQuerystringValues } from 'appdir/components/general/Util';
import { getPlatformID } from 'shared/forms/elements/Utils';
import MeasurementUtils from 'appdir/lib/analytics';
import LoadingIndicator from 'shared/general/LoadingIndicator';

/**
 * -----------------------------------------------------------------------------
 * React Component: ChangePasswordForm
 * -----------------------------------------------------------------------------
 */

 const mapStateToProps = (state, props) => {
	return {
		...props,
		messageSettings: op.get(state['Config'], 'staticData.cmsMessages.data', {}),
		site: state?.Config?.dataSettings?.site,
	};
};
class ChangePasswordForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			submitStatus: null
		};

		this.tokenRetrieved = false;
		this.parsedQs = getQuerystringValues();
	}

	componentDidMount() {
		this.setState({
			mount: true,
		});
	}

	componentDidUpdate() {
		// logger.log('[ChangePasswordForm] componentDidUpdate - this:%o', this);

		if (this.parsedQs.resetConfirmationToken && !this.tokenRetrieved) {
			this.tokenRetrieved = true;
			let resetToken = this.parsedQs.resetConfirmationToken;

			// logger.log('[ChangePasswordForm] componentDidUpdate - resetToken:%o', resetToken);

			this.setState({
				resetToken: resetToken
			});
		}
	}

	componentWillUnmount() {
		// logger.log('[ChangePassword] componentWillUnmount');

		this.setState = {
			submitStatus: null,
		};
	}

	handleAutoFill(e) {
		// logger.log('[SignInForm] handleAutofill - e:%o', e.currentTarget);

		this.setState({
			[e.target.name]: {
				...this.state[e.target.name],
				active: e.animationName === 'onAutoFillStart' ? true : false,
				autofill: e.animationName === 'onAutoFillStart' ? true : false,
			},
		});
	}

	handleFocus(e, val, formProps) {
		// logger.log('[ChangePasswordForm] handleFocus');
		if (formProps) {
			formProps.handleBlur(e);
		}

		if (e.target.value === '') {
			this.setState({
				[e.target.name]: {
					...this.state[e.target.name],
					focus: val,
				},
			});
		} else {
			this.setState({
				[e.target.name]: {
					...this.state[e.target.name],
					focus: true,
				},
			});
		}
	}

	handleChangePassword = (values) => {
		// logger.log('[ChangePasswordForm] handleChangePassword - values:%o', values);

		let providerIDVal = op.get(this.props, 'ticketsData.providerId', null) ? op.get(this.props, 'ticketsData.providerId') : 1;
		let url = `${this.props.ticketsData.passwordReset}?token=${this.state.resetToken}&provider=${this.props.ticketsData.provider}`;

		// logger.log('[ChangePasswordForm] handleChangePassword - providerIDVal:%o', providerIDVal);
		// logger.log('[ChangePasswordForm] handleChangePassword - url:%o', url);

		axios({
			method: 'post',
			url: url,
			data: {
				providerID: providerIDVal,
				platformID: getPlatformID(),
				password: values.password,
			}
		})
			.then(res => {
				if (res.data.statusCode === 'ok') {
					this.setState({
						submitStatus: 'success',
					}, () => {
						MeasurementUtils.dispatchMeasurementCall('ChangePasswordSubmit', {
							response: 'success',
							responseData: res,
						});

						this.props.onSubmit(true);
					});
					
				} else {
					logger.log('[ChangePassword] handleChangePassword reset status code not ok:%o', res);
					this.setState({
						submitStatus: 'error',
						errors:{
							...this.state.errors,
							responseData:`${this.props.messageSettings['login.change_password_error']}  ${this.props.messageSettings['further_assistance']}`
						}
					}, () => {
						MeasurementUtils.dispatchMeasurementCall('ChangePasswordSubmit', {
							response: 'failure',
							responseData: res,
						});
					});
				}
			})
			.catch(error => {
				logger.error('[ChangePassword] handleChangePassword reset error:%o', error);
				this.setState({
					submitStatus: 'error',
					errors:{
						...this.state.errors,
						responseData:`${this.props.messageSettings['login.change_password_error']}  ${this.props.messageSettings['further_assistance']}`
					}
					
				}, () => {
					MeasurementUtils.dispatchMeasurementCall('ChangePasswordSubmit', {
						response: 'error',
						responseData: error,
					});
				});			
			});
	}

	togglePassword = (which) => {
		// logger.log('[ChangePasswordForm] togglePassword - which:%o', which);
		// let measureString = `ChangePasswordShowPasswordToggle`;
		let display = this.state[which] ? false : true;

		MeasurementUtils.dispatchMeasurementCall('ChangePasswordShowPasswordToggle', {
			display,
		});

		this.setState({
			[which]: display,
		});
	}

	getLoadingIndicator = () => {
		logger.log('[ForgotPasswordForm] getLoadingIndicator');
		return (
			<div className="fadeOverlay" ref={this.loadingRef}>
				<LoadingIndicator />
			</div>
		);
	};

	render() {
		logger.log('[ChangePasswordForm] render - this %o', this);
		let passwordShow = this.state.password_show ? true : false;
		let confirmPasswordShow = this.state.confirmPassword_show ? true : false;

		if (op.get(this.props, 'eventsWindow.windowSize', false)
			&& !isEmpty(this.props.flowData)
		) {
			this.ChangePasswordValues = {
				password: '',
				confirmPassword: ''
			};
	
			let ChangePasswordSchema = yup.object({
				password: yup
					.string()
					.matches(
						new RegExp(this.props.ticketsData.passwordRegex),
						this.props.messageSettings['password_rules']
					)
					.required('Password is required'),
				confirmPassword: yup
					.string()
					.matches(
						new RegExp(this.props.ticketsData.passwordRegex),
						this.props.messageSettings['password_rules']
					)
					.required('Password (confirmation) is required')
					.oneOf([yup.ref('password'), null], 'Passwords must match'),
			});

			return (
				<div className="form-container change-password">
					<div className="form-content">
						<div className="change-password-container">
							<div className="form-title">Change Password</div>

							<Formik
								initialValues={this.ChangePasswordValues}
								validationSchema={ChangePasswordSchema}
								onSubmit={this.handleChangePassword}
								enableReinitialize={true}
								key="changePassword">
								{formProps => {
									// logger.log('[ChangePasswordForm] render - formProps:%o', formProps);
									return (
										<Form id="changePasswordForm">
											{writeErrors(formProps, this.state.errors)}

											<div className="form-text">
												Please enter a new password.  Password must contain at least 8 characters and a minimum of 1 numeric character.
											</div>

											<FormPassword
												id="password"
												name="password"
												label="Password"
												labeltype="float"
												type={passwordShow ? "text" : "password"}
												passwordshow={passwordShow}
												instructions="true"
												onAnimationStart={e => this.handleAutoFill(e)}
												onAnimationEnd={e =>
													e.animationName === 'onAutoFillStart'
														? (formProps.touched.password = true)
														: (formProps.touched.password = false)
												}
												onFocus={e => this.handleFocus(e, true)}
												onBlur={e => this.handleFocus(e, false, formProps)}
												onTogglePassword={() => this.togglePassword('password_show') }
											/>

											<FormConfirmPassword
												id="confirmPassword"
												name="confirmPassword"
												label="Password (confirmation)"
												labeltype="float"
												type={confirmPasswordShow ? 'text' : 'password'}
												onAnimationStart={e => this.handleAutoFill(e)}
												passwordshow={confirmPasswordShow}
												passwordvalue={formProps.values.password}
												onAnimationEnd={e =>
													e.animationName === 'onAutoFillStart'
														? (formProps.touched.confirmPassword = true)
														: (formProps.touched.confirmPassword = false)
												}
												onFocus={e => this.handleFocus(e, true)}
												onBlur={e => this.handleFocus(e, false, formProps)}
												onTogglePassword={() => this.togglePassword('confirmPassword_show') }
											/>

											<div className="page-actions">
												<Button
													style={this.props.numericWindowSize < 2 ? 'full' : ''}
													buttonState={!formProps.isValid || formProps.values.password == '' || formProps.values.confirmPassword == '' ? 'disabled' : 'active'}
													onClick={() => {
														if (formProps.isValid) {
															formProps.submitForm();
														}
													}}
													buttonText="Submit" />

												<div className="action-link-container">
													<EventsLink style="action-link" to={this.props.flowData.actions.changePassword.cancel}>
														Cancel
													</EventsLink>
												</div>
											</div>
										</Form>
									);
								}}
							</Formik>
						</div>
					</div>

					{this.getLoadingIndicator()}
				</div>
			)
		}  else {
			return this.getLoadingIndicator();
		}
	}
}

export default connect(mapStateToProps)(ChangePasswordForm);

import React, { Component, Fragment, useState } from 'react';
import JSXParser from 'react-jsx-parser';
import cn from 'classnames';


export default function Steps(props) {

  logger.log('[Steps] props:%o', props); 

  let classNames = cn(
    "stepContainer",
    {
      "active": props.isActive,
      "completed": (props.currentStep > props.index + 1 || props.currentStep === props.numOfSteps || (!props?.isActive && props?.paymentSteps?.currentStep > props?.index + 1) ),
    }
  )

  return(
    <div className={classNames}>
      {props.showWinnerSplash ? 
        <JSXParser jsx={props.label} />
      : 
        <>
          <div className={"circleWrapper"}>
            <div className="circle">{props.index + 1}</div>
          </div>
          <span className="circleLabel">{props.label}</span>
        </>
      }
    </div>
  )
}
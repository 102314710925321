/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';
import { Redirect } from 'react-router';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
 
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { writeErrors } from 'shared/forms/elements/Utils';
import { FormInput } from 'shared/forms/elements/FormFields';
import Button from 'shared/general/Button';
import EventsLink from 'shared/general/EventsLink';
import isEqual from 'lodash/isEqual';
import {shouldMobileVerify } from 'shared/general/Util';


import LoadingIndicator from 'shared/general/LoadingIndicator';
 
/**
 * -----------------------------------------------------------------------------
 * Functional Component: const AccountInformationForm = (props) => {
 * -----------------------------------------------------------------------------
 */
 const mapStateToProps = (state, props) => {
	return {
		...props,
        userData: op.get(state['UserData'], 'userData', {}),
        flowData: op.get(state['Config'], 'staticData.flow.data', {}),
        messageSettings: op.get(state['Config'], 'staticData.cmsMessages.data'),
		requiresVerifyId: op.get(state['Config'], 'addressValidation.requiresVerifyId', {}),
	};
};

const mapDispatchToProps = dispatch => ({
    updateApplicationData: data => dispatch(deps.actions.UserData.updateApplicationData(data))
});
class SignatureForm extends Component {
    constructor(props) {
		super(props);
		this.state = {
            success: false,
            errors: {}
        };

        this.loadingRef = React.createRef();
        this.submit = false;

        logger.log('[SignatureForm] props:%o', props);
	}

    componentWillUnmount() {
        this.submit = false;

        this.setState({
            success: false,
            errors: {}
        }) 
    }

    handleAutoFill(e) {
		// logger.log('[SignatureForm] handleAutofill - e:%o', e.currentTarget);

		this.setState({
			[e.target.name]: {
				...this.state[e.target.name],
				active: e.animationName === 'onAutoFillStart' ? true : false,
				autofill: e.animationName === 'onAutoFillStart' ? true : false,
			},
		});
	}

	handleFocus(e, val, formProps) {
		// logger.log('[SignatureForm] handleFocus');
		if (formProps) {
			formProps.handleBlur(e);
		}

		if (e.target.value === '') {
			this.setState({
				[e.target.name]: {
					...this.state[e.target.name],
					focus: val,
				},
			});
		} else {
			this.setState({
				[e.target.name]: {
					...this.state[e.target.name],
					focus: true,
				},
			});
		}
	}

    componentDidUpdate(prevProps) {
        if((!isEqual(prevProps.userData, this.props.userData) ||
            isEqual(prevProps.userData, this.props.userData)) && 
            this.submit === true
        ) {
            this.submit = false;

            this.setState({
                success: this.props.userData.application.hasAcceptedTicketAgreement,
                errors: {}
            })
        }
    }

    handleSubmit = (values) => {
        logger.log('[SignatureForm] handleSubmit - this:%o', this);
        logger.log('[SignatureForm] handleSubmit - values:%o', values);

        /** show loading indicator while everyone is working hard to create an account */
		// if (this.loadingRef.current) {
		// 	this.loadingRef.current.classList.add('show');
		// }

        let data = {
			ticketAgreementSignature: values ? values.ticketAgreementSignature : "",
			hasAcceptedTicketAgreement: values ? true : false,
		};

        logger.log('[SignatureForm] handleSubmit - data:%o', data);

        this.props.updateApplicationData(data).then(response => {
            if (response.status == 200) {
                this.submit = true;
                this.setState({
                    errors:{}
                })
            } else {
                this.submit = false;
                this.setState({
                    errors:{submitError:this?.props?.messageSettings['general.service_error']?.replace("{0}", "SG1")}
                })
            }
        }).catch(e => {
            logger.log('[SignatureForm] handleSubmit - error:%o', e);

            this.submit = false;
            this.setState({
                errors:{submitError:this?.props?.messageSettings['general.service_error']?.replace("{0}", "SG2")}
            })
        })
    }

    getLoadingIndicator = () => {
		return (
			<div className="fadeOverlay" ref={this.loadingRef}>
				<LoadingIndicator />
			</div>
		);
	};
 
    render() {
        logger.log('[SignatureForm] render - this:%o', this);
        let mobileVerify = shouldMobileVerify(this?.props?.requiresVerifyId, this?.props?.messageSettings?.['system.mobile_verification'], this?.props?.userData?.country);

        let { userData } = this.props;
        let _this = this;

        this.SignatureValues = {
            ticketAgreementSignature: ''
        };

        let SignatureSchema = yup.object({
            ticketAgreementSignature: yup
                .string()
                .required('Signature is required')
                .test('ticketAgreementSignature','Your signature must match your first and last name exactly.',(value) => {
                    return value && value.trim() !== '' 
                        ? isEqual(value.toLowerCase().trim().replace(/[\u2018\u2019\u0091\u0092]/g, "'").replace(/[\u201C\u201D]/g, '"'),`${_this.props.userData.firstName.toLowerCase().trim().replace(/[\u2018\u2019\u0091\u0092]/g, "'").replace(/[\u201C\u201D]/g, '"')} ${_this.props.userData.lastName.toLowerCase().trim().replace(/[\u2018\u2019\u0091\u0092]/g, "'").replace(/[\u201C\u201D]/g, '"')}`) 
                        : false
                }),
        });

        if (this.state.success === true && op.get(this.props, 'flowData.actions.termsAgree', null)) {
            let redirectUrl = userData.application.hasAcceptedTicketAgreement 
                    ? mobileVerify 
                        ? this.props.flowData.actions.termsAgree.acceptVerify
                        : this.props.flowData.actions.termsAgree.accept 
                    : this.props.flowData.actions.termsAgree.cancel;

			return <Redirect push to={redirectUrl} state={{msg:"issue with update"}} />;
		}

        return (
            <div className="form-container terms-signature">
				<div className="form-content">
					<div className="terms-signature-container">
                        {/* <p>{this.props.userData.email}</p> */}
                        <p>{`${this.props.userData.firstName.trim()} ${this.props.userData.lastName.trim()}`}</p>
						<Formik
							initialValues={this.SignatureValues}
							validationSchema={SignatureSchema}
							onSubmit={this.handleSubmit}
							enableReinitialize={true}
							key="termsSignature">
							{formProps => {
								// logger.log('[Signature] render - formProps:%o', formProps);
								return (
									<Form id="Signature">
                                        <br />
										{writeErrors(formProps, this.state.errors)}
                                        <div className="two-columns long">
                                            <FormInput
                                                label="Enter your name exactly as it appears above"
                                                labeltype="float"
                                                name="ticketAgreementSignature"
                                                type="text"
                                                maxLength="100"
                                                required={true}
                                                onAnimationStart={e => this.handleAutoFill(e)}
                                                onAnimationEnd={e =>
                                                    e.animationName === 'onAutoFillStart'
                                                        ? (formProps.touched.ticketAgreementSignature = true)
                                                        : (formProps.touched.ticketAgreementSignature = false)
                                                }
                                                onFocus={e => this.handleFocus(e, true)}
                                                onBlur={e => this.handleFocus(e, false, formProps)}
                                            />
                                        </div>


                                        <div className="page-actions">
                                            <Button
                                                    style={this.props.numericWindowSize < 3 ? 'full' : ''}
                                                    buttonState={!formProps.isValid || !formProps.dirty ? 'disabled' : ''}
                                                    onClick={() => {
                                                        if (formProps.isValid) {
                                                            formProps.submitForm();
                                                        }
                                                    }}
                                                    buttonText="I Agree" />
                                            <div className="action-link-container">
                                                <EventsLink style="action-link" to={op.get(this.props, 'flowData.actions.termsAgree.reject', '')}>
													I do not agree
												</EventsLink>
											</div>
                                            <div className="action-link-container">
                                                <EventsLink style="action-link" to={op.get(this.props, 'flowData.actions.termsAgree.print', '')}>
													View Printable Terms
												</EventsLink>
											</div>

                                        </div>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </div>
                </div>
                {this.getLoadingIndicator()}
            </div>
        );
    }
};
 
export default connect(mapStateToProps, mapDispatchToProps)(SignatureForm);
 
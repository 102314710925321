import { connect } from 'react-redux';
import ContentInfo from './ContentInfo';

const mapStateToProps = state => {
    return {
        ...state['ContentInfo'],
    };
};

const mapDispatchToProps = dispatch => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ContentInfo);

import React, { Component } from "react";
import { connect } from "react-redux";
import deps from "dependencies";
import op from "object-path";
import axios from "axios";
import { Redirect } from "react-router";

import { Formik, Field, Form } from "formik";
import * as yup from "yup";
import { writeErrors } from "shared/forms/elements/Utils";
import {
  FormCheckBox,
  FormRadioButton,
  FormInput,
  FormPassword,
} from "shared/forms/elements/FormFields";
import Button from "shared/general/Button";
import VisitButton from "shared/general/VisitButton";
import LogoutButton from "shared/general/LogoutButton";
import EventsLink from "shared/general/EventsLink";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import CmsMessageContent from "shared/general/CmsMessageContent";
import LoadingIndicator from "shared/general/LoadingIndicator";
import MeasurementUtils from "appdir/lib/analytics";
import jwt_decode from "jwt-decode";
import { checkJwtStatus } from "../../data/UserData/Util";

/**
 * React Component: User Identity Verification
 */

const mapStateToProps = (state, props) => {
  return {
    ...state.VerifyIdentityForm,
    userData: op.get(state["UserData"], "userData", {}),
    flowData: op.get(state["Config"], "staticData.flow.data", {}),
    ticketsData: op.get(state["Config"], "ticketsData"),
    messageSettings: op.get(state["Config"], "staticData.cmsMessages.data"),
    siteConfigStatus: state["Config"].status,
    site: state?.Config?.dataSettings?.site,
    settings: state["Config"].dataSettings,
    Router: state["Router"],
    ...props,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  loadConfig: () => dispatch(deps.actions.Config.loadConfig()),
  updateApplicationData: (data) =>
    dispatch(deps.actions.UserData.updateApplicationData(data)),
  updatePatronData: data => dispatch(deps.actions.UserData.updatePatronData(data)),
  logout: () => dispatch(deps.actions.UserData.logoutUser()),
  refreshProfile: () => dispatch(deps.actions.UserData.refreshProfile())
});

class VerifyIdentityForm extends Component {
  constructor(props) {
    super(props);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleResetForm = this.handleResetForm.bind(this);
		this.refreshInterval = null;

    this.state = {
      errors: {},
      password_show: true,
      submitSuccess: false,
      requestCodeSuccess: false,
      asi: "",
      verificationType: "",
      verificationCode: "",
      verificationSuccess: false,
      working: false,
      updatePhone: false
    };

    this.submit = false;
    logger.log("[VerifyIdentityForm] constructor - this:%o", this);
  }

  // componentDidMount() {
  //   logger.log("[VerifyIdentityForm] - componentDidMount this%o", this);
  //   this.props.loadConfig();
  // }
  

  // componentDidUpdate(prevProps, prevState) {
  //   logger.log("[VerifyIdentityForm] - componentDidUpdate props%o", this.state);
  //   if (prevState.verificationSuccess !== this.state.verificationSuccess) {
  //     //  this.setState({
  //     // });
  //     //this.handleOnChange;
  //   }
  // }

  refresh(setSubmitting) {
    let ctr = 1;
        this.refreshInterval = setInterval(() => {
          if (this?.state?.errors?.verifyError || this.props?.userData?.datePhoneVerified || ctr == 3) {
            // Stop the interval once the condition is met (datePhoneVerified is populated).
            clearInterval(this.refreshInterval);
            if (setSubmitting) {
                setSubmitting(false);
              }
            } else {
            // Call the refreshProfile function if the condition is not met.
            ctr++;
            this.props.refreshProfile();
            }
        }, 2000);
  }

  handleAutoFill(e) {
    logger.log("[VerifyIdentityForm] handleAutofill - e:%o", e.currentTarget);

    this.setState({
      [e.target.name]: {
        ...this.state[e.target.name],
        active: e.animationName === "onAutoFillStart" ? true : false,
        autofill: e.animationName === "onAutoFillStart" ? true : false,
      },
    });
  }

  handleFocus(e, val, formProps) {
    logger.log("[VerifyIdentityForm] handleFocus");
    if (formProps) {
      formProps.handleBlur(e);
    }

    if (e.target.value === "") {
      this.setState({
        [e.target.name]: {
          ...this.state[e.target.name],
          focus: val,
        },
      });
    } else {
      this.setState({
        [e.target.name]: {
          ...this.state[e.target.name],
          focus: true,
        },
      });
    }
  }

  getVerificationCode = (setSubmitting, formProps) => {
    //this.props.refreshProfile();
    let jwtStatus = checkJwtStatus(this?.props?.userData.secureJwt, this?.props?.ticketsData?.jwtWillExpire);

    logger.log("[VerifyIdentityForm] getVerificationCode jwtStatus:%o", jwtStatus);

    if (jwtStatus == 'expired') {
      this.props.logout();
    } else {
      this.setState({
        working: true,
        updatePhone: false,
        errors:{}
      }, () => {
        // this block is for testing only so that you can bypass the request for a code
        // this.setState({
        //   requestCodeSuccess: true,
        //   asi: '',
        //   working: false,
        //   errors: {}
        // });

        let url = this.props.ticketsData.mobileVerifyStart;
        axios({
          method: "get",
          url: `${url}`,
          params: { vType: this.state.verificationType },
          headers: { Authorization: `Bearer ${this.props.userData.token}` },
        })
          .then((res) => {
            logger.log("[VerifyIdentityForm] getVerificationCode res:%o", res);
            if (res.status == 200) {
              this.setState({
                requestCodeSuccess: true,
                asi: res.data.asi,
                working: false,
                errors: {}
              }, () => {
                // if (isEmpty(this.state.errors)) {
                //   this.props.refreshProfile();
                // }
              });
            }  
            else if (res.status == 400) {
              this.setState(
                {
                  requestCodeSuccess: false,
                  errors: {
                    ...this.state.errors,
                    ...(res?.data?.errorCode !== 654 && {errorMessage: this?.props?.messageSettings['general.service_error'].replace('{0}', 'V1')})
                  },
                  working:false
                },() => {
                  MeasurementUtils.dispatchMeasurementCall(
                    "VerificationCodeSubmit",
                    {
                      site: this.props.site,
                      response: "failure",
                      resData: res.data,
                    }
                  );
                  if (res?.data?.errorCode == 654) {
                    this.props.refreshProfile();
                  }
                }
              );
            }
            else {
              this.setState(
                {
                  requestCodeSuccess: false,
                  errors: {
                    ...this.state.errors,
                    errorMessage: `${this?.props?.messageSettings['general.service_error'].replace('{0}', 'V2')}`,
                  },
                  working:false
                },() => {
                  MeasurementUtils.dispatchMeasurementCall(
                    "VerificationCodeSubmit",
                    {
                      site: this.props.site,
                      response: "failure",
                      resData: res.data,
                    }
                  );
                  // if (isEmpty(this.state.errors)) {
                  //   this.props.refreshProfile();
                  // }
                }
              );
            }
          })
          .catch((error) => {
            logger.error(
              "[VerifyIdentityForm] getVerificationCode error getting verification error:%o",
              error
            );
    
            this.setState({
              requestCodeSuccess: false,
              errors: {
                ...this.state.errors,
                ...(error?.response?.data?.errorCode != 654 && {errorMessage: this?.props?.messageSettings['general.service_error'].replace('{0}', 'V3')})
              },
              working: false
            }, () => {
              if (error?.response?.data?.errorCode == 654) {
                this.props.refreshProfile();
              }
            });
            return error;
          })
      })
    }
  };

  handleOnChange = (fieldId, fieldName, formProps) => {
    let { values } = formProps;
    let verificationType = fieldId;
    let formValues = {
      ...values,
      selectedVerifcationMethod: values[fieldName] == "true" ? "false" : "true",
    };

    this.setState({
      verificationType: verificationType,
    });

    if (fieldName !== "selectedVerifcationMethod") {
      formValues = {
        ...values,
        [fieldName]: !values[fieldName],
        selectedVerifcationMethod:
          !values[fieldName] == true
            ? "true"
            : values["selectedVerifcationMethod"],
      };

      this.setState(
        {
          formValues,
          errors:{}
        },
        () => {
          MeasurementUtils.dispatchMeasurementCall("VerifyIdentity", {
            site: this.props.site,
            id: fieldName,
            checked: this.state.formValues[fieldName],
          });
        }
      );
    } else {
      this.setState(
        {
          formValues,
        },
        () => {
          MeasurementUtils.dispatchMeasurementCall("VerifyIdentity", {
            site: this.props.site,
            id: fieldName,
            checked: this.state.formValues[fieldName],
          });
        }
      );
    }
    logger.log(
      "[VerifyIdentityForm] handleOnChange fieldName:%o, values:%o, id:%o",
      fieldName,
      values,
      fieldId
    );
  };

  handleSubmit = (values, setSubmitting) => {
    logger.log("[VerifyIdentityForm] handleSubmit - values:%o", values);
    logger.log("[VerifyIdentityForm] handleSubmit - props:%o", this.props);
    logger.log(
      "[VerifyIdentityForm] handleSubmit - setSubmitting:%o",
      setSubmitting
    );
    let url = this.props.ticketsData.mobileVerify;
    let pin = values.verificationCode;

    logger.log(
      "[VerifyIdentityForm] handleSubmit - setSubmitting:%o, state:%o",
      setSubmitting,
      this.state
    );
    axios({
      method: "get",
      url: `${url}`,
      params: {
        asi: this.state.asi,
        pin: pin,
        vType: this.state.verificationType,
      },
      headers: { Authorization: `Bearer ${this.props.userData.token}` },
    })
      .then((res) => {
        logger.log("[VerifyIdentityForm] handleSubmit res:%o", res.data);
        if (res.status == 200) {
          if (res?.data?.policyDecision == 'approve') {
            this.setState(
              {
                verificationSuccess: true,
                submitSuccess: true,
                errors: {}
              },() => {
                this.refresh(setSubmitting);
              }
            );
          } else {
            this.setState(
              {
                verificationSuccess: false,
                submitSuccess: false,
                errors : {
                  ...this.state.errors,
                  "verifyError": this?.props?.messageSettings?.['verification.invalid_code'] || 'The code you entered is invalid.'
                }
              },() => {
                this.refresh(setSubmitting);
              }
            );
          }
        }
        else if (res.status == 400) {
          if (res.data.errorCode) {
            this.setState(
              {
                errors: {
                  ...this.state.errors,
                  "verifyError":`${this?.props?.messageSettings['general.service_error'].replace('{0}', 'V4')}`
                },
                verificationSuccess: false,
                submitSuccess: false,
              },() => {
                this.refresh(setSubmitting);
                MeasurementUtils.dispatchMeasurementCall(
                  "VerificationCodeSubmit",
                  {
                    site: this.props.site,
                    response: "failure",
                    resData: res.data,
                  }
                );
              }
            );
          }
        }
        else {
          this.setState(
            {
              verificationSuccess: false,
              submitSuccess: false,
              errors : {
                ...this.state.errors,
                "verifyError":`${this?.props?.messageSettings['general.service_error'].replace('{0}', 'V5')}`
              }
            },() => {
              this.refresh(setSubmitting);
            }
          );
        }
      })
      .catch((error) => {
        logger.log(
          "[VerifyIdentityForm] handleSubmit error getting verification error:%o",
          error
        );

        this.setState({
          submitSuccess: false,
          errors: {
            ...this.state.errors,
            "verifyError":`${this?.props?.messageSettings['general.service_error'].replace('{0}', 'V6')}`
          },
        },() => {
          this.refresh(setSubmitting);
        }
        );
        return error;
      })
  };

  togglePassword() {
    this.setState(
      {
        password_show: this.state.password_show ? false : true,
      },
      () => {
        MeasurementUtils.dispatchMeasurementCall("togglePassword", {
          site: this.props.site,
          show: this.state.password_show,
        });
      }
    );
  }

  handleResetForm = (formProps, which) => {
    logger.log("[VerifyIdentityForm] handleResetForm formProps:%o", formProps);

    this.setState({
      requestCodeSuccess: false,
      verificationSuccess: false,
      updatePhone: false,
      submitSuccess: false,
      working: false,
      asi: "",
      verificationType: "",
      verificationCode: "",
      errors: {}
    }, () => {
      logger.log("[VerifyIdentityForm] handleResetForm reset form state:%o", this.state);
      formProps.resetForm();

      // only measure if it is from cancel button or resend code
      if (which) {
        MeasurementUtils.dispatchMeasurementCall('VerifyIdentity', {
          action: which,
          userID: this.props.userData.userID,
          site: this.props?.site,
        });
      }
    });
  }

  handleUpdatePhone = (formProps) => {
    logger.log("[VerifyIdentityForm] handleUpdatePhone formProps:%o", formProps);
    this.setState({
      working: true
    }, () => {
      if (this.props.userData.phone !== formProps?.values?.phone && !formProps?.errors?.phone) {
        logger.log("[VerifyIdentityForm] handleUpdatePhone phone changed, need to update");
        this.props.updatePatronData({phone: formProps?.values?.phone}).then(response => {
          if (response.status == 200) {
            logger.log('[VerifyIdentityForm] handleUpdatePhone response:%o', response);
            this.setState(
              {
                working: false,
                errors: {},
              },
              () => {
                logger.log('[VerifyIdentityForm] handleUpdatePhone state:%o', this.state);
                this.handleResetForm(formProps);
                MeasurementUtils.dispatchMeasurementCall('VerifyIdentity', {
                  action: 'updatePhoneSubmit',
                  status: 'Success',
                  userID: this.props.userData.userID,
                  site: this.props?.site,
                });
              }
            );
          } else {
            logger.log('[VerifyIdentityForm] handleUpdatePhone error response:%o', response);
            this.setState(
              {
                ...this.state,
                working: false,
                errors: {
                  response: `${response.data.errorCode}`,
                },
              },
              () => {
                MeasurementUtils.dispatchMeasurementCall('VerifyIdentity', {
                  action: 'updatePhoneSubmit',
                  status: 'Failure',
                  errors: this.state.errors,
                  userID: this.props.userData.userID,
                  site: this.props?.site,
                });
              }
            );
          }
        });
      } else {
        logger.log("[VerifyIdentityForm] handleUpdatePhone phone didn't change");
        this.setState({
          working: false
        }, () => {
          this.handleResetForm(formProps);
          MeasurementUtils.dispatchMeasurementCall('VerifyIdentity', {
            action: 'updatePhoneSubmit',
            status: 'NoChange',
            errors: {},
            userID: this.props.userData.userID,
            site: this.props?.site,
          });
        })
      }
    })
		
  }

  renderVerificationButtons = (formProps) => {
    if (isEmpty(this.props?.flowData)) {
      return null;
    }
    return (
      <div className="page-actions">
        <div className="verfiyIdentiy-btn">
          {!this.state.verificationSuccess &&
          this.props?.userData?.addressValidationStatusId !== 12 &&
          !this.props?.userData?.datePhoneVerified &&
          !this.state.updatePhone
          ? (
            <>
              {!this.state.requestCodeSuccess ? (
                <Button
                  buttonText={this?.props?.messageSettings['verification.button_request_code']}
                  onClick={this.getVerificationCode}
                  buttonState={!this.state.verificationType || this.state.working ? "disabled" : ""}
                />
              ) : (
                <Button
                  buttonText={
                    this?.props?.messageSettings["verification.button"]
                  }
                  buttonState={formProps.isSubmitting ? "disabled":""}
                  onClick={() => formProps.submitForm()}
                />
              )}

              {this.state.requestCodeSuccess ? (
                <EventsLink
                  onClick={() => this.handleResetForm(formProps, "newRequest")}
                  style="action-link"
                >
                  {this?.props?.messageSettings["verification.new_request"]}
                </EventsLink>
              ) : null}

              <EventsLink
                onClick={() => {formProps.setSubmitting(false); this.setState({updatePhone:true, errors:{}})}}
                //to={this.props?.flowData?.actions?.general?.accountUpdate}
                style="action-link"
              >
                {this?.props?.messageSettings["verification.update_phone"]}
              </EventsLink>
            </>
          ) : null}

          {
          (this.props?.userData?.addressValidationStatusId == 12 &&
            !this.props?.userData?.datePhoneVerified) &&
            !this.state.updatePhone ? (
            <>
              <LogoutButton type={"button"} />
              <EventsLink
                to={this.props?.flowData?.actions?.notification?.claim}
                style="action-link"
              >
                {this?.props?.messageSettings['verification.button_failed']}
              </EventsLink>
            </>
          ) : null}

          {this.state.updatePhone ? (
            <>
              <Button
                buttonText={this?.props?.messageSettings['verification.button_update']}
                onClick={() => this?.handleUpdatePhone(formProps)}
                buttonState={this.state.working || formProps?.errors?.phone ? "disabled" : ""}
              />
              <EventsLink
                  onClick={() => this.handleResetForm(formProps, "cancelPhoneUpdate")}
                  style="action-link"
                >
                  {this?.props?.messageSettings['verification.button_update_cancel']}
                </EventsLink>
            </>
          ) : null}
        </div>
      </div>
    );
  };

  render() {
    logger.log("[VerifyIdentityForm] render:%o", this.state);
    let passwordShow =
      op.get(this.state, "password_show", false) === true ? true : false;
    let VerifyIdentitySchema;
    const _this = this;
    VerifyIdentitySchema = yup.object({
      verificationCode: yup
        .string()
        .matches(new RegExp(_this?.props?.ticketsData?.verificationIdCodeRegex), {message: "Verification codes should be 6 digits."}),
      phone: yup
        .string()
        .required('Mobile Phone is required')
        .test('phone', 'Please enter a valid Mobile Phone', function(value) {
            let phoneRegex = new RegExp(_this?.props?.ticketsData?.phoneRegex);
            //let country = this.parent.country.value;
            let validPhone = false;

            //logger.log('[VerifyIdentityForm] VerifyIdentitySchema - phoneRegex:%o', _this?.props?.ticketsData?.phoneRegex);
            // logger.log('[AccountProfileForm] render - country:%o', country);
           
            validPhone = phoneRegex.test(value);
            //logger.log('[VerifyIdentityForm] VerifyIdentitySchema - value: %o, phone test:%o', value, validPhone);

            return validPhone;
        }),
    });

    if (this.props?.userData?.datePhoneVerified ) {
      //{this?.props?.flowData?.actions?.paymentReview?.payment}
      return (
        <Redirect push to={this?.props?.flowData?.actions?.paymentReview?.payment} />
      );
    }

    return (<>
      <div className="form-container">
        <div className="form-content">
          <div className="verify-identity-container">
            <>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  selectedVerifcationMethod: "",
                  verificationCode: "",
                  phone:this?.props?.userData?.phone
                }}
                //onSubmit={this.handleSubmit}
                validateOnChange={true}
                onSubmit={(values, { setSubmitting }) => {
                  if (values.verificationCode == "") {
                    setSubmitting(false);
                  } else {
                    setSubmitting(true);
                    this.handleSubmit(values, setSubmitting);
                  }
                }}
                validationSchema={VerifyIdentitySchema}
              >
                {(formProps, handleChange) => {
                  logger.log(
                    "[VerifyIdentityForm] formProps-render - formProps:%o",
                    formProps
                  );
                  return (
                    <Form id="VerifyIdentityForm">
                      {writeErrors(formProps, this.state.errors)}

                      {formProps.isSubmitting ? (
                        <LoadingIndicator style={{"textAlign":"center", "position":"relative"}} />
                      ) : (
                        <>
                          {!this.props?.userData?.datePhoneVerified && 
                          this.props?.userData?.addressValidationStatusId !== 12 ? (
                            <>
                              
                              {/* {data={[this.props?.userData?.phone]}} */}
                              {
                                !this.state.updatePhone && 
                                  <>
                                    <CmsMessageContent
                                      id="verification.instructions"
                                      data={[this.props?.userData?.phone]}
                                    />
                                    <div className="options">
                                      <span>
                                        <FormRadioButton
                                          type="radio"
                                          name="selectedVerificationMethod"
                                          value={
                                            this.state.verificationType == "sms"
                                              ? "true"
                                              : "false"
                                          }
                                          //this.state.verificationType == "sms" ? "true" : "false"
                                          id="sms"
                                          //onClick={this.handleClick}
                                          disabled={this.state.requestCodeSuccess}
                                          checked={
                                            this.state.verificationType == "sms" &&
                                            this.state?.formValues
                                              ?.selectedVerifcationMethod == "true"
                                          }
                                          onChange={() =>
                                            this.handleOnChange(
                                              "sms",
                                              "selectedVerificationMethod",
                                              formProps
                                            )
                                          }
                                        ></FormRadioButton>
                                        <CmsMessageContent id="verification.method_sms" />
                                      </span>
                                      <span>
                                        <FormRadioButton
                                          type="radio"
                                          name="selectedVerificationMethod"
                                          value={
                                            this.state.verificationType == "voice"
                                              ? "true"
                                              : "false"
                                          }
                                          //this.state.verificationType == "voice" ? "false" : "true"
                                          id="phone"
                                          //onClick={this.handleClick}
                                          disabled={this.state.requestCodeSuccess}
                                          checked={
                                            this.state.verificationType == "voice" &&
                                            this.state?.formValues
                                              ?.selectedVerifcationMethod == "true"
                                          }
                                          onChange={() =>
                                            this.handleOnChange(
                                              "voice",
                                              "selectedVerificationMethod",
                                              formProps
                                            )
                                          }
                                        ></FormRadioButton>
                                        <CmsMessageContent id="verification.method_phone_call" />
                                      </span>
                                    </div>
                                  </>
                              }

                              {
                                this.state.updatePhone ? (
                                  <div className="phoneUpdate">
                                    <CmsMessageContent
                                      id="verification.update_phone_instructions"
                                    />
                                    <FormInput
                                        label="Mobile Phone"
                                        labeltype="float"
                                        name="phone"
                                        type="text"
                                        maxLength="30"
                                        required={true}
                                        onAnimationStart={e => this.handleAutoFill(e)}
                                        onAnimationEnd={e =>
                                            e.animationName === 'onAutoFillStart'
                                                ? (formProps.touched.phone = true)
                                                : (formProps.touched.phone = false)
                                        }
                                        onFocus={e => this.handleFocus(e, true)}
                                        onBlur={e => this.handleFocus(e, false, formProps)}
                                    />
                                  </div>
                                ) : null
                              }
                              
                              {this.state.requestCodeSuccess && !this.state.updatePhone ? (
                                <>
                                  <CmsMessageContent id="verification.code_instructions" />
                                  <FormPassword
                                    label="Enter code"
                                    labeltype="float"
                                    name="verificationCode"
                                    type={passwordShow ? "password" : "number"}
                                    passwordshow={passwordShow}
                                    onTogglePassword={() => this.togglePassword()}
                                  ></FormPassword>
                                </>
                              ) : null}
                            </>
                          ) : null}

                          {this.props?.userData?.addressValidationStatusId == 12  ? (
                            <div className="verificationFailed">
                              {/* <i className="icon-error"></i> */}
                              <CmsMessageContent id="verification.failed" />
                            </div>
                          ) : null}
                        </>
                      )}
                      
                      {this.renderVerificationButtons(formProps)}
                    </Form>
                  );
                }}
              </Formik>
            </>
          </div>
        </div>
        {
          this.state.working
            ? <LoadingIndicator />
            : null
        }
        
      </div>
    </>);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyIdentityForm);

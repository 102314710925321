/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import axios from 'axios';
import Template from 'components/Template';
import ContentHero from 'shared/cms/ContentHero';
import CmsMessageContent from 'shared/general/CmsMessageContent';
import Button from 'shared/general/Button';
import { Redirect } from 'react-router';
import op from 'object-path';
import { getPlatformID } from 'shared/forms/elements/Utils';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import MeasurementUtils from 'appdir/lib/analytics';
import AccountInfo from 'shared/general/AccountInfo';
import LoadingIndicator from 'shared/general/LoadingIndicator';
import { getApplicationPhase, isAddressEditable, fetchDataFromPaths } from 'shared/general/Util';

/**
 * -----------------------------------------------------------------------------
 * React Component: AddressVerificationContent
 * -----------------------------------------------------------------------------
 */
export default class AddressVerificationContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			change: false,
			accept: false,
			redirect: false,
			errors: {},
		};

		this.addressVerified = false;

		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'AddressVerification',
			site: this.props.tempUserData.eventName,
			userID: this.props.tempUserData.userID,
		});
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log('[AddressVerificationContent] componentDidUpdate this:%o', this);

		/**
		 * on page load, attempt to verify address to display initial error if needed
		 */
		if (
			!isEmpty(this.props.userData) &&
			!this.addressVerified &&
			op.get(this.props, 'ticketsData.validateAddress')
		) {
			this.addressVerified = true;
			this.verifyAddress();
		}
	}

	verifyAddress(button) {
		logger.log('[AddressVerificationContent] verifyAddress this:%o', this);

		//if (op.get(this.props, 'userData.countryCode', '') == 'US' || op.get(this.props, 'userData.countryCode', '') == 'CA') {
		// send entered address thru melissa address verification
		axios({
			method: 'post',
			url: this.props.ticketsData.validateAddress,
			data: {
				providerID: this.props.ticketsData.providerId,
				platformID: getPlatformID(),
				prefix: op.get(this.props, 'userData.prefix', ''),
				firstName: this.props.userData.firstName,
				lastName: this.props.userData.lastName,
				suffix: op.get(this.props, 'userData.suffix', ''),
				country: op.get(this.props, 'userData.country', ''),
				address1: this.props.userData.address1,
				address2: this.props.userData.address2,
				suiteName: op.get(this.props, 'userData.suiteName', ''),
				suiteNumber: this.props.userData.suiteNumber,
				city: this.props.userData.city,
				stateProvince: this.props.userData.stateProvince,
				zipPostalCode: this.props.userData.zipPostalCode,
				zipPlus4: this.props.userData.zipPlus4,
				phone: this.props.userData.phone,
				patronId: this.props.userData.patronId,
			},
			headers: { Authorization: `Bearer ${this.props.userData.token}` },
		})
			.then(res => {
				logger.log('[AddressVerificationContent] verifyAddress res:%o', res);

				if (res.status == 200) {
					let userData = {
						...this.props.userData,
						...res.data,
					};

					logger.log('[AddressVerificationContent] verifyAddress userData:%o', userData);
					logger.log(
						'[AddressVerificationContent] verifyAddress addressValidationStatusId:%o',
						res.data.addressValidationStatusId
					);
					logger.log(
						'[AddressVerificationContent] verifyAddress invalidStatusLookup:%o',
						this.props.invalidStatusIds[res.data.addressValidationStatusId]
					);

					logger.log(
						'[AddressVerificationContent] verifyAddress new addressHash:%o, db addressHash',
						userData.addressHash,
						this.props?.tempUserData?.addressHash
					);

					if (
						userData.addressIsUsed &&
						(!this.props.tempUserData ||
							(this.props.tempUserData &&
								!isEqual(this.props.tempUserData.addressHash, userData.addressHash)))
					) {
						this.setState({
							...this.state,
							change: false,
							accept: false,
							redirect: true,
							errors: {
								response: `account.duplicate_address`,
							},
						});
					} else if (
						userData.addressValidationStatusId == 9 ||
						userData.addressValidationStatusId == 10 ||
						userData.addressValidationStatusId == 11
					) {
						this.setState(
							{
								...this.state,
								accept: true,
								change: false,
								redirect: true,
								errors: {},
								verifiedAddress: userData,
							},
							() => this.onAcceptAddress()
						);
					} else {
						this.setState({
							...this.state,
							accept: false,
							change: false,
							errors: {},
							verifiedAddress: userData,
						});
					}

					if (button === true) {
						MeasurementUtils.dispatchMeasurementCall('AddressVerificationSubmit', {
							response: 'success',
							responseData: userData,
							site: this.props.site,
						});
					}
				} else {
					logger.error('[AddressVerificationContent] verifyAddress validate address response - not 200:%o', res);
					this.setState({
						change: false,
						accept: false,
						errors: {
							errorCode: `${res.status}`,
							response: 'general.service_error',
						},
					});

					if (button === true) {
						MeasurementUtils.dispatchMeasurementCall('AddressVerificationSubmit', {
							response: 'failure',
							site: this.props.site,
							responseData: `error_${error.response.data.errorCode}`,
						});
					}
				}

				// if (this.loadingRef.current) {
				// 	this.loadingRef.current.classList.remove('show');
				// }
			})
			.catch(error => {
				logger.error('[AddressVerificationContent] verifyAddress validate address error 000 - catch:%o', error.response);
				this.setState({
					...this.state,
					change: false,
					accept: false,
					redirect: true,
					errors: {
						errorCode: `${error?.response?.status}`,
						response: 'general.service_error',
					},
				});

				if (button === true) {
					MeasurementUtils.dispatchMeasurementCall('AddressVerificationSubmit', {
						response: 'failure',
						site: this.props.site,
						responseData: `error_${error.response.data.errorCode}`,
					});
				}
			});
	}

	onChangeAddress() {
		this.setState(
			{
				...this.state,
				change: true,
				accept: false,
				redirect: true,
			},
			() => {
				MeasurementUtils.dispatchMeasurementCall('addressVerification', {
					action: 'change',
					site: this.props.site,
					userID: this.props.tempUserData.userID,
				});
			}
		);
	}

	onAcceptAddress() {
		// logger.log('[AddressVerificationContent] onAcceptAddress');

		this.setState(
			{
				change: false,
				accept: true,
				redirect: true,
				errors: {},
			},
			() => {
				MeasurementUtils.dispatchMeasurementCall('addressVerification', {
					action: 'accept',
					site: this.props.site,
					userID: this.props.tempUserData.userID,
				});
			}
		);

		this.props.updateUserData(this.state.verifiedAddress);
	}

	render() {
		logger.log('[AddressVerificationContent] render this:%o', this);
		let { verifiedAddress } = this.state;
		let userAddress = null;

		if (op.get(verifiedAddress, 'address1', false)) {
			userAddress = `${verifiedAddress.address1}<br/>`;

			if (op.get(verifiedAddress, 'address2', null)) {
				userAddress += `${verifiedAddress.address2}<br/>`;
			}

			if (op.get(verifiedAddress, 'suiteName', null)) {
				userAddress += `${verifiedAddress.suiteName} `;
			}

			if (op.get(verifiedAddress, 'suiteNumber', null)) {
				userAddress += `${verifiedAddress.suiteNumber}<br/>`;
			}

			userAddress += `${verifiedAddress.city}, ${verifiedAddress.stateProvince} ${verifiedAddress.zipPostalCode}`;

			if (op.get(verifiedAddress, 'zipPlus4', null)) {
				userAddress += `-${verifiedAddress.zipPlus4}<br/>`;
			} else {
				userAddress += '<br/>';
			}

			userAddress += `${verifiedAddress.country}`;
		}

		logger.log('[AddressVerificationContent] render - userAddress:%o', userAddress);

		/**
		 * user chooses to change address
		 */
		if (
			this.state.redirect === true &&
			this.state.change === true &&
			isEmpty(this.state.errors) &&
			!isEmpty(this.props.flowData)
		) {
			return (
				<Redirect
					push
					to={{
						pathname: this.props.flowData.actions.addressVerification.change,
						state: {
							addressValidationStatusId: verifiedAddress
								? verifiedAddress.addressValidationStatusId
								: null,
						},
					}}
				/>
			);
		}

		/**
		 * user has not chosen to change address, but there were verification errors
		 */
		if (
			this.state.redirect === true &&
			this.state.change === false &&
			!isEmpty(this.state.errors) &&
			!isEmpty(this.props.flowData)
		) {
			return (
				<Redirect
					push
					to={{
						pathname: this.props.flowData.actions.addressVerification.change,
						state: {
							errors: this.state.errors,
							addressValidationStatusId: verifiedAddress
								? verifiedAddress.addressValidationStatusId
								: null,
						},
					}}
				/>
			);
		}

		/**
		 * user accepts address
		 */
		if (
			this.state.redirect === true &&
			this.state.accept === true &&
			!(
				verifiedAddress.addressValidationStatusId == 9 ||
				verifiedAddress.addressValidationStatusId == 10 ||
				verifiedAddress.addressValidationStatusId == 11
			) &&
			!isEmpty(this.props.flowData)
		) {
			if (
				!this.props?.userData?.application?.applicationStatus ||
				this.props?.userData?.birthYear === 1865 ||
				this.props.userData.birthYear === 0 ||
				!this.props.userData.birthYear ||
				((this.props.requiresPin[this.props.userData.country] || this.props.requiresPin[this.props.userData.countryCode])  &&
					(this.props.userData.ssn4 == '' || !this.props.userData.ssn4))
			) {
				return (
					<Redirect
						push
						to={
							this.props?.userData?.birthYear === 1865 ||
							this.props.userData.birthYear === 0 ||
							!this.props.userData.birthYear ||
							((this.props.requiresPin[this.props.userData.country] || this.props.requiresPin[this.props.userData.countryCode]) &&
								(this.props.userData.ssn4 == '' || !this.props.userData.ssn4))
								? this.props.flowData.actions.addressVerification.accept
								: this.props.flowData.actions.accountProfile.review
						}
					/>
				);
			} else {
				return (
					<Redirect
						push
						to={{ pathname: this.props.flowData.actions.accountReview.next, state: 'addressChange' }}
					/>
				);
			}
		}

		/**
		 * user entered international address redirect to pin page
		 */
		if (
			this.state.redirect === true &&
			this.state.accept === true &&
			(verifiedAddress.addressValidationStatusId == 9 ||
				verifiedAddress.addressValidationStatusId == 10 ||
				verifiedAddress.addressValidationStatusId == 11)
		) {
			// if no application
			if (
				!this.props?.userData?.application?.applicationStatus ||
				this.props?.userData?.birthYear === 1865 ||
				this.props.userData.birthYear === 0 ||
				!this.props.userData.birthYear ||
				(this.props.requiresPin[this.props.userData.countryCode] &&
					(this.props.userData.ssn4 == '' || !this.props.userData.ssn4))
			) {
				return (
					<Redirect
						push
						to={
							this.props?.userData?.birthYear === 1865 ||
							this.props.userData.birthYear === 0 ||
							!this.props.userData.birthYear ||
							(this.props.requiresPin[this.props.userData.countryCode] &&
								(this.props.userData.ssn4 == '' || !this.props.userData.ssn4))
								? this.props.flowData.actions.addressVerification.accept
								: this.props.flowData.actions.accountProfile.review
						}
					/>
				);
			} else {
				return (
					<Redirect
						push
						to={{ pathname: this.props.flowData.actions.accountReview.next, state: 'addressChange' }}
					/>
				);
			}
		}

		return userAddress ? (
			<Template>
				<section className="page-content">
					<section className="page-title">
						<h1><CmsMessageContent id="general.page_header_title" textOnly={true} /></h1>
					</section>
					<section className="hero">
						<ContentHero />
					</section>
					<section className="section-title-wrapper">
						<h2>
							Address Verification
							{!this.props.validStatusIds[verifiedAddress.addressValidationStatusId] ? ' Failure' : ''}
						</h2>
					</section>

					<section className="page-grid cols-1">
						<div className="page-grid-item">
							{isEmpty(this.state.errors) ? (
								<CmsMessageContent
									id={
										verifiedAddress.addressValidationStatusId !== 1
											? 'account.validation.failure_summary'
											: 'account.validation.success_instructions'
									}
									data={[userAddress]}
								/>
							) : (
								<>
									{this?.state?.errors?.response ? (
										<CmsMessageContent id={this.state.errors.response} data={[this.state.errors.response !== 'general.service_error' ? userAddress : `AD1_${this.state.errors.errorCode}`]} />
									) : (
										<CmsMessageContent
											id="account.validation.failure_summary"
											data={[userAddress]}
										/>
									)}
								</>
							)}

							<CmsMessageContent id="account.validation.failure_question" />

							<AccountInfo category="verify" userData={verifiedAddress} />

							<div className="page-actions">
								<Button
									style={this.props.numericWindowSize < 3 ? 'full' : ''}
									buttonState={isEmpty(this.state.errors) ? 'active' : 'disabled'}
									onClick={() => {
										this.onAcceptAddress();
									}}
									buttonText="Accept"
								/>

								<div className="action-link-container">
									<a
										className="action-link"
										onClick={() => {
											this.onChangeAddress();
										}}
										onKeyPress={() => (event.key === 'Enter' ? this.onChangeAddress() : null)}
										tabIndex="0">
										Change
									</a>
								</div>
							</div>
						</div>
					</section>
				</section>
			</Template>
		) : (
			<Template>
				<section className="page-content">
					<section className="page-title">
						<h1><CmsMessageContent id="general.page_header_title" textOnly={true} /></h1>
					</section>
					<section className="hero">
						<ContentHero />
					</section>
					<section className="section-title-wrapper">
						<h2>Validating Address</h2>
					</section>
					<div className="fadeOverlay" ref={this.loadingRef}>
						<LoadingIndicator />
					</div>
				</section>
			</Template>
		);
	}
}

AddressVerificationContent.defaultProps = {};

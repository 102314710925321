/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import AddressVerificationContent from 'shared/page-content/AddressVerificationContent';

/**
 * -----------------------------------------------------------------------------
 * React Component: AccountProfile
 * -----------------------------------------------------------------------------
 */

const AddressVerification = props => {
	logger.log('[AddressVerification] props:%o', props);
	return <AddressVerificationContent />;
};

export default AddressVerification;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import deps from 'dependencies';
import axios from 'axios';
import op from 'object-path';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { FormCheckBox } from 'shared/forms/elements/FormFields';
import Button from 'shared/general/Button';
import { getPlatformID, writeErrors } from 'shared/forms/elements/Utils';
import LoadingIndicator from 'shared/general/LoadingIndicator';
import EventsLink from 'shared/general/EventsLink';
import MeasurementUtils from 'appdir/lib/analytics';
import CmsMessageContent from 'shared/general/CmsMessageContent';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { getApplicationPhase, getHomePage } from 'shared/general/Util';
import { useHistory } from "react-router-dom";
import { renderErrorItem } from 'shared/forms/elements/Utils';





/**
  * -----------------------------------------------------------------------------
  * React Component: Delete Account
  * -----------------------------------------------------------------------------
  */


 const mapStateToProps = (state, props) => {
  return {
    ...props,
    userData: op.get(state['UserData'], 'userData', {}),
    flowData: op.get(state['Config'], 'staticData.flow.data', {}),
    site: state?.Config?.dataSettings?.site,
    messageSettings: op.get(state['Config'], 'staticData.cmsMessages.data'),
    Router: state['Router'],
  };
};

class DeleteAccountForm extends Component{
  constructor(props) {
    super(props);
    this.state = {
      isChecked: false,
      redirect: null,
      submitStatus: null,
      successUrl: null,
      submitting: false,
      errors: {},
    };
    
    this.submit = false;
    logger.log('[DeleteAccountForm] props:%o', props);
  }

  componentWillUnmount() {
		logger.log('[DeleteAccountForm] componentWillUnmount');

		this.setState = {
			submitStatus: null,
		};
	}

// Submit delete request
  handleSubmit = () => {
        this.setState({
          submitting: true
        }, () => {
          this.handleDeleteAccount();
        })
  }

  handleChange = (checked) => {
    logger.log('[DeleteAccountForm] handleChange - value:%o', this.state);

    this.setState({isChecked: checked});
    if(checked) {
        this.setState({
            redirect: true
        });
    }else {
        this.setState({
            redirect: false 
        });
    }
  }
  
  handleDeleteAccount() {
    logger.log('[DeleteAccountForm] handleDeleteAccount - state:%o', this.state);
    let url = this.props.ticketsData.initiateRegulatoryDelete;

		axios({
      method: 'post',
      url: `${url}`,
      data: { provider: this.props.ticketsData.provider },
      headers: { Authorization: `Bearer ${this.props.userData.token}` },
    })
    .then(resp => {
        logger.log('[DeleteAccountForm] handleDeleteAccount - resp:%o', resp);
        if (resp.status == 200 && resp?.data?.statusCode == 'ok') {
            
            this.setState({
                redirect: true,
                submitting: false,
                successUrl: resp?.data?.responseProperties?.isWinner ? this.props?.flowData?.actions?.notification?.requestdelete : this.props?.flowData?.actions?.notification?.deleted,
                errors: {}
            }, () => {
                //logger.log('[DeleteAccountForm] handleDeleteAccount - logout');
                if (!resp?.data?.responseProperties?.isWinner) {
                  this.props.logout();
                }
                //this.props.updateUserData(userData);
            });
        } else {
          this.setState({
              redirect: true,
              successUrl: this.props?.flowData?.actions?.notification?.requestdelete,
              submitting: false,
              errors: {},
          });
        }
    })
    .catch(e => {
        logger.log('[DeleteAccountForm] handleDeleteAccount - Error:%o', e.response);
        this.setState({
          redirect: true,
          successUrl: this.props?.flowData?.actions?.notification?.requestdelete,
          submitting: false,
          errors: {}
        });
    });
  }

  goBackPage = () => {
    let category = op.get(this.props, 'match.params.contentCategory', null);
    let action = op.get(this.props, 'match.params.action', 'index');
    MeasurementUtils.dispatchMeasurementCall('goBack', {
      site: this.props.site,
      category,
      action,
      userID: this.props.userData.userID,
    });
    history.back();
  };

  renderButtons() {
    let applicationPhase = getApplicationPhase(op.get(this.props, 'userData.application'), this.props.eventConfig);

    if(isEmpty(this.props.flowData)) {
        return null;
    }
    let redirectURL = '';

    // if (this.state.redirect === true
    //   && this.state.isChecked === true
    //   // && op.get(this.props, 'flowData.actions.notification', null) 
    //   && (this?.state?.isWinner)) {
    //   redirectURL = this.props.flowData.actions.notification.requestdelete
    // } else {
    //   redirectURL = this.props.flowData.actions.notification.deleted
    // } 

    let cancelUrl = this.props.flowData.actions.notification.account;

    if (applicationPhase == 'applicationPaid') {
      cancelUrl = this.props.flowData.actions.notification.receipt
    } else if (applicationPhase == 'applicationWinner') {
      cancelUrl = this.props.flowData.actions.notification.claim
    } else if (applicationPhase == 'applicationLoser' || applicationPhase == 'applicationSubmitted' || applicationPhase == 'applicationSubmittedNoEdit') {
      cancelUrl = this.props.flowData.actions.notification.application
    }

    // let goToRedirectPath ='';
    // // Tickets applications off
    // if(this.props.userData.application.applicationStatus == 0 ) {
    //   goToRedirectPath = this.props.flowData.actions.notification.account
    // }
    // // Tickets applications on or Tickets loser
    // if(this.props.userData.application.applicationStatus == 1 || this.props.userData.application.applicationStatus == 4) {
    //   goToRedirectPath = this.props.flowData.actions.notification.application
    // }
    // // Tickets applications Winner
    // if(this.props.userData.application.applicationStatus == 2 || this.props.userData.application.applicationStatus == 6) {
    //   goToRedirectPath = this.props.flowData.login.unpaidWinnerHome
    // }
    // // Tickets applications Paid  Winner
    // if(this.props.userData.application.applicationStatus == 3) {
    //   goToRedirectPath = this.props.flowData.login.paidWinnerHome
    // }

    return (
        <div className="page-actions">
          <Button
            buttonText="Submit"
            //to={redirectURL}
            type="submit"
            onClick={() => this.handleSubmit()}
            buttonState={this.state.isChecked && !this.state.submitting ? 'active' : 'disabled'}
          >
          </Button>
          <div className="action-link-container">
          {/* <EventsLink
            //onClick={() => this.handleAction('Previous')}
            // to={goToRedirectPath}
            onClick={this.goBackPage}
            style="action-link">
            Previous
					</EventsLink> */}
				</div>

					<div className="action-link-container">
						<EventsLink
							//onClick={() => this.handleAction('Cancel')}
							to={cancelUrl}
              //onClick={this.goBackPage}
							style="action-link">
							Cancel
						</EventsLink>
					</div>
        </div>
    );
  }

  render() {
    logger.log('[DeleteAccountForm] this:%o', this);

    if (this.state.redirect === true && this.state.successUrl) {
      return <Redirect push to={this.state.successUrl} />
    } else {
      return(
        <>
          {this?.state?.errors?.deleteRequest ? (
            <div className="form-content">
              <div className="error-field">
                {renderErrorItem(this.state.errors.deleteRequest, 0)}
                </div>
            </div>
          ) : null}
          <CmsMessageContent id="account.delete_prompt_instructions" />
          <div className="form-content">
            <div className="delete-account-container">
            
              <Formik 
                enableReinitialize={true} 
                initialValues={{check: (this.state.isChecked)}}
                >
                {({ values }) => (
                  <Form id="DeleteAccountForm">
                      <FormCheckBox 
                        name="check" 
                        id="check"
                        onChange={() => this.handleChange(!values.check)}                     
                        >
                      </FormCheckBox>
                      <CmsMessageContent id="account.delete_prompt_checkbox" />
                  </Form>         
                )}
              </Formik>
            </div>
            <>{this.renderButtons()}</>
          </div>
          {this.state.submitting ? <LoadingIndicator /> : null}
        </>
      );
    }

  }
}

const mapDispatchToProps = (dispatch, props) => ({
  loadConfig: () => dispatch(deps.actions.Config.loadConfig()),
  logout: () => dispatch(deps.actions.UserData.logoutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAccountForm);
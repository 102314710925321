/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import AccountPinContent from 'shared/page-content/AccountPinContent';

/**
 * -----------------------------------------------------------------------------
 * React Component: AccountProfile
 * -----------------------------------------------------------------------------
 */

const AccountPin = props => {
	logger.log('[AccountPin] props:%o', props);
	return <AccountPinContent />;
};

export default AccountPin;

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import op from 'object-path';
import axios from 'axios';
import EventsLink from 'shared/general/EventsLink';
import LoadingIndicator from 'shared/general/LoadingIndicator';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { FormEmail } from 'shared/forms/elements/FormFields';
import Button from 'shared/general/Button';
import { writeErrors } from 'shared/forms/elements/Utils';
import { isEmpty } from 'lodash';
import MeasurementUtils from 'appdir/lib/analytics';

/**
 * -----------------------------------------------------------------------------
 * React Component: ForgotPasswordForm
 * -----------------------------------------------------------------------------
 */

 const mapStateToProps = (state, props) => {
	return {
		...props,
		messageSettings: op.get(state['Config'], 'staticData.messages.data', {}),
		site: state?.Config?.dataSettings?.site,
	};
};
class ForgotPasswordForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			submitSuccess: false
		};

		this.loadingRef = React.createRef();
	}

	handleAutoFill(e) {
		// logger.log('[SignInForm] handleAutofill - e:%o', e.currentTarget);

		this.setState({
			[e.target.name]: {
				...this.state[e.target.name],
				active: e.animationName === 'onAutoFillStart' ? true : false,
				autofill: e.animationName === 'onAutoFillStart' ? true : false,
			},
		});
	}

	handleFocus(e, val, formProps) {
		if (formProps) {
			formProps.handleBlur(e);
		}

		if (e.target.value === '') {
			this.setState({
				[e.target.name]: {
					...this.state[e.target.name],
					focus: val,
				},
			});
		} else {
			this.setState({
				[e.target.name]: {
					...this.state[e.target.name],
					focus: true,
				},
			});
		}
	}

	handleForgotPassword = (values, setSubmitting) => {
		// logger.log('[ForgotPasswordForm] handleSubmit - values:%o', values);

		/** show loading indicator while everyone is working hard to say yes come in or no */
		if (this.loadingRef.current) {
			this.loadingRef.current.classList.add('show');
		}

		axios({
			method: 'post',
			url: this.props.ticketsData.initiateReset,
			data: {
				provider: this.props.ticketsData.provider,
				username: values.email,
			}
		})
			.then(res => {
				logger.log('[ForgotPasswordForm] handleSubmit res:%o', res);
				if (res.status == 200) {
					this.setState({
						submitSuccess: true,
					}, () => {
						if (this.loadingRef.current) {
							this.loadingRef.current.classList.remove('show');
						}
						setSubmitting(false)
						MeasurementUtils.dispatchMeasurementCall('ForgotPasswordSubmit', {
							response: 'success',
							responseData: res,
							site: this.props.site
						});
	
						this.props.onFormSubmit(true);
					});					
				} else {
					this.setState({
						submitSuccess: false,
					}, () => {
						if (this.loadingRef.current) {
							this.loadingRef.current.classList.remove('show');
						}
						setSubmitting(false)

						MeasurementUtils.dispatchMeasurementCall('ForgotPasswordSubmit', {
							response: 'failure',
							responseData: res,
							site: this.props.site
						});
					});					
				}
			})
			.catch(error => {
				logger.error('[ForgotPasswordForm] handleSubmit error:%o', error);
				// this.setState({
				// 	submitSuccess: false,
				// }, () => {
				// 	if (this.loadingRef.current) {
				// 		this.loadingRef.current.classList.remove('show');
				// 	}
				// });			
				
				if (error.response && error.response.status == 400) {
					this.setState({
						success: false,
						errors: {
							...this.state.errors,
							submit: op.get(this.props, 'messageSettings.forgotPassword.400.message', 'There was an error creating your account'),
						},
					},() => {
						setSubmitting(false)

						if (this.loadingRef.current) {
							this.loadingRef.current.classList.remove('show');
						}
					});
				} else {
					this.setState({
						success: false,
						errors: {
							...this.state.errors,
							submit: op.get(this.props, 'messageSettings.createAccount.error.message', 'There was an error creating your account'),
						},
					}, () => {
						setSubmitting(false)

						if (this.loadingRef.current) {
							this.loadingRef.current.classList.remove('show');
						}
					});
				}

				MeasurementUtils.dispatchMeasurementCall('ForgotPasswordSubmit', {
					response: 'error',
					responseData: error,
					site: this.props.site
				});
			});
	}

	getLoadingIndicator = () => {
		logger.log('[ForgotPasswordForm] getLoadingIndicator');
		return (
			<div className="fadeOverlay" ref={this.loadingRef}>
				<LoadingIndicator />
			</div>
		);
	};

	render() {
		logger.log('[ForgotPasswordForm] render - this %o', this);

		this.ForgotPasswordValues = {
			email: ''
		};

		let ForgotPasswordSchema = yup.object({
			email: yup
				.string()
				.email('Please supply a valid Email Address')
				.required('Email Address is required')
		});

		return op.get(this.props.EventsWindow, 'windowSize', false) && !isEmpty(this.props.flowData) ? (
			<div className="form-container forgot-password">
				<div className="form-content">
					<div className="forgot-password-container">
						<div className="form-title">Forgot Password</div>

						<Formik
							initialValues={this.ForgotPasswordValues}
							validationSchema={ForgotPasswordSchema}
							//onSubmit={this.handleForgotPassword}
							onSubmit={(values, { setSubmitting }) => {
								if((values.email == '')) {
									setSubmitting(false);
								} else {
									setSubmitting(true);
									this.handleForgotPassword(values, setSubmitting)
								}
							}}
							enableReinitialize={true}
							key="forgotPassword">
							{formProps => {
								// logger.log('[ForgotPasswordForm] render - formProps:%o', formProps);
								return (
									<Form id="forgotPasswordForm">
										{writeErrors(formProps, this.state.errors)}

										<div className="form-text">
											Please enter your email address and select SUBMIT below.  An email will be sent to you with instructions for changing your password.
										</div>						
										
										<FormEmail
											id="email"
											name="email"
											label="Email"
											labeltype="float"
											onAnimationStart={e => this.handleAutoFill(e)}
											onAnimationEnd={e =>
												e.animationName === 'onAutoFillStart'
													? (formProps.touched.email = true)
													: (formProps.touched.email = false)
											}
											onFocus={e => this.handleFocus(e, true)}
											onBlur={e => this.handleFocus(e, false, formProps)}
										/>

										<div className="form-text">
											If you require further assistance, please contact the Masters Ticket Office at (706) 667-6700.
										</div>

										<div className="page-actions">
												<input 
													type="submit" 
													disabled={ (!formProps.isValid || !formProps.dirty || formProps.isSubmitting)}
													className={`button submit 
																${this.props.EventsWindow.numericWindowSize < 2 ? 'full' : ''}
																${!formProps.isValid || formProps.isSubmitting ? 'disabled' :''}`}
													value="Submit" 
												/>

											<div className="action-link-container">
												<EventsLink style="action-link" to={this.props.flowData.actions.forgotPassword.cancel}>
													Cancel
												</EventsLink>
											</div>
										</div>
									</Form>
								);
							}}
						</Formik>
					</div>
				</div>

				{this.getLoadingIndicator()}
			</div>
		) : this.getLoadingIndicator();
	}
}

export default connect(mapStateToProps)(ForgotPasswordForm);

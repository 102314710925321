import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Redirect } from 'react-router-dom';
import GenericError from './GenericError';

const mapStateToProps = (state, props) => ({
	...state.ErrorBoundary,
	Router: state['Router'],
	...props,
});
const mapDispatchToProps = dispatch => ({});
class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
		};
	}
	componentDidCatch(error, info) {
		if (this.props.redirect) {
			this.setState({
				redirect: true,
				error,
				info
			})
		} else {
			this.setState({ 
				hasError: true,
			});
		}
		
	}
	render() {
		//logger.log('[ErrorBoundary] - render this:%o', this);

		if (this.state.redirect) {
			return (
				<Redirect
					push
					to={{
						pathname: this.props.redirect,
						state: {
							data: {
								error:this.state.error,
								info:this.state.info,
								route: this.props.Router.pathname
							}
						},
					}}
				/>
			)
		} else if (this.state.hasError) {
			return <GenericError message={this.props.message} />;
		} else {
			return this.props.children;
		}
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);

import React, { Component } from 'react';

class GenericError extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		return <div className="error-indicator">{this.props.message}</div>;
	}
}
export default GenericError;

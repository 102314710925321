/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import Template from 'components/Template';
import ContentHero from 'shared/cms/ContentHero';
import CmsMessageContent from 'shared/general/CmsMessageContent';
import AccountInfo from 'shared/general/AccountInfo';
import AddressConfirmationForm from 'shared/forms/AddressConfirmationForm';
import isEmpty from 'lodash/isEmpty';
import MeasurementUtils from 'appdir/lib/analytics';

/**
 * -----------------------------------------------------------------------------
 * React Component: AddressConfirmationContent
 * -----------------------------------------------------------------------------
 */
export default class AddressConfirmationContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			change: false,
			accept: false,
			redirect: false,
			errors: {},
		};

		this.addressVerified = false;

		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'AddressConfirmation',
			site: this.props.userData.eventName,
		});
	}

	render() {
		logger.log('[AddressConfirmationContent] render this:%o', this);

		return !isEmpty(this.props.userData) ? (
			<Template>
				<section className="page-content">
					<section className="page-title">
						<h1><CmsMessageContent id="general.page_header_title" textOnly={true} /></h1>
					</section>
					<section className="hero">
						<ContentHero />
					</section>
					<section className="section-title-wrapper">
						<h2>Address Confirmation</h2>
					</section>

					<section className="page-grid cols-1">
						<div className="page-grid-item">
							<CmsMessageContent id="application.address_confirm.instructions" />

							<AccountInfo category="verify" userData={this.props.userData} />

							<AddressConfirmationForm
								userData={this.props.userData}
								flowData={this.props.flowData}
								addressChange={this.props?.Router?.state == 'addressChange'}
							/>
						</div>
					</section>
				</section>
			</Template>
		) : null;
	}
}

AddressConfirmationContent.defaultProps = {};

import { trackingMiddleware } from 'appdir/lib/tracking';

export default (middlewares = [], isServer = false) => {
	return middlewares.concat([
		{
			order: 0,
			name: 'analytics-redux-middleware',
			mw: trackingMiddleware(),
		},
	]);
};

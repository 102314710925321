/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import AddressConfirmationContent from './AddressConfirmationContent';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';

/**
 * -----------------------------------------------------------------------------
 * React Component: AddressConfirmationContent
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state.AddressConfirmationContent,
		userData: op.get(state['UserData'], 'userData', {}),
		flowData: op.get(state['Config'], 'staticData.flow.data', {}),
		Router: state['Router'],
		...props,
	};
};

export default connect(mapStateToProps, null)(AddressConfirmationContent);

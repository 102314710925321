/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
 import React from 'react';
 import { useField } from 'formik';
 import Select from 'react-select';
 import MaskedInput from 'react-text-mask';
 import op from 'object-path';
 import isEqual from 'lodash/isEqual';
 
 /**
  * -----------------------------------------------------------------------------
  * Functional Component: Formik Generic Form Fields
  * -----------------------------------------------------------------------------
  */
 
export const FormInput = ({ label, labeltype, ...props }) => {
    const [field, meta] = useField(props);
    logger.log('[FormInput] label:%o meta:%o props:%o', label, meta, props);
    let showInstructions = op.get(props, 'instructions', false) == "true" ? true : false;
    let instructionsText = op.get(props, 'instructions-text', false) ? props['instructions-text'] : false;
    return (
        <div className={`${props.name}-field ${labeltype}-label ${meta.value != "" ? 'Active' : ''} ${meta.touched && meta.error ? 'error Active' : ''}`}>
            <label htmlFor={props.id || props.name} className="input-label">
                {label}{props.required && props.required === true ? <span className="required">*</span> : ''}
            </label>
            <input {...field} {...props} />
            {meta.touched && meta.error ? <i className="icon-error"></i> : null}
            {showInstructions && instructionsText ? <div className="field-instructions">{instructionsText}</div> : null}
        </div>
    );
};

/**
 * @param {
 *      id - string, 
 *      name - string, 
 *      placeholder - string | text - same as label
 *      label - string | text  - same as placeholder
 *      labeltype - string | "float" or "fixed"
 *      onAnimationStart - function | required if using float label
 *      onAnimationEnd - function | required if using float label
 *      onFocus - function | required if using float label
 *      onBlur - function | required if using float label
 * }
 * @returns form email input element with error notification
 */
export const FormEmail = ({ label, labeltype, ...props }) => {
    const [field, meta] = useField(props);

    //logger.log('[FormEmailsss] label:%o meta:%o props:%o', label, meta, props);

    return (
        <div className={`${props.name}-field ${labeltype}-label ${meta.value != "" ? 'Active' : ''} ${meta.touched && meta.error ? 'error Active' : ''}`}>
            <label htmlFor={props.id || props.name} className="input-label">
                {label}
            </label>    
            <input type="email" {...field} {...props} />        
            {meta.touched && meta.error ? <i className="icon-error"></i> : null}       
        </div>
    );
};
 
export const FormSelect = ({ label, labeltype, ...props }) => {
    const [field, meta] = useField(props);
    // logger.log('[FormSelect] label:%o meta:%o props:%o field:%o', label, meta, props, field);

    return (
        <div className={`${props.name}-field ${labeltype}-label select-box ${ meta.touched && meta.error ? 'error Active' : ''}`}>
            <label htmlFor={props.id || props.name} className="input-label">
                {label}{props.required && props.required === true ? <span className="required">*</span> : ''}
            </label>
            <Select
                {...field}
                {...props}
                className="react-select-container"
                classNamePrefix="react-select"
                multi={false}
                isSearchable={false}
                value={props.options ? props.options.find(option => isEqual(option, field.value)) : ''}
                aria-live="off"
            />
            <div className={`select_down_arrow ${props.class === 'active' ? 'open' : 'closed'}`}></div>
            {meta.touched && meta.error ? <i className="icon-error"></i> : null}
        </div>
    );
};

/**
 * @param {
 *      id - string, 
 *      name - string, 
 *      placeholder - string | text - same as label
 *      label - string | text  - same as placeholder
 *      labeltype - string | "float" or "fixed"
 *      type - string value
 *      passwordshow - boolean | true or false
 *      instructions - string | "true" or "false" 
 *      onAnimationStart - function | required if using float label
 *      onAnimationEnd - function | required if using float label
 *      onFocus - function | required if using float label
 *      onBlur - function | required if using float label
 *      onTogglePassword - function | required if using show/hide password toggle
 * }
 * @returns form input element (password or text) with show/hide password toggle, instructions for password, and error notification
 */
export const FormPassword = ({ label, labeltype, passwordshow, onTogglePassword, ...props }) => {
    const [field, meta] = useField(props);
    let showInstructions = op.get(props, 'instructions', false) == "true" ? true : false;
    // logger.log('[FormPassword] label:%o labeltype:%o passwordshow:%o onTogglePassword:%o meta:%o props:%o', label, labeltype, passwordshow, onTogglePassword, meta, props);

    return (
        <>
            <div className={`${props.name}-field ${labeltype}-label ${meta.value != "" ? 'Active' : ''} ${meta.touched && meta.error ? 'error Active' : ''}`}>
                <label htmlFor={props.id || props.name} className="input-label">
                    {label}
                </label>
                <input {...field} {...props} />
                <i
					className={passwordshow ? 'icon-pwd-show' : 'icon-pwd-hide'}
					onClick={e => onTogglePassword()}></i>
                {meta.touched && meta.error ? <i className="icon-error"></i> : null}
            </div>
            {showInstructions === true ?
                field.value !== '' && !meta.error && meta.touched ? (
                    <div className="validation">
                        <i className="icon-valid"></i>Password meets requirements
                    </div>
                ) : ( 
                    <div className="instructions">Requires 8 characters, 1 uppercase, 1 lowercase, 1 number</div>
                ) 
            : null }
        </>
    );
};
 
export const FormConfirmPassword = ({ label, labeltype, passwordshow, onTogglePassword, ...props }) => {
    const [field, meta] = useField(props);
    //logger.log('[FormConfirmPassword] label:%o meta:%o props:%o', label, meta, props);
    
    return (
        <>
            <div className={`${props.name}-field ${labeltype}-label ${meta.value != "" ? 'Active' : ''} ${meta.touched && meta.error ? 'error Active' : ''}`}>
                <label htmlFor={props.id || props.name} className="input-label">
                    {label}
                </label>
                <input {...field} {...props} />
                <i
					className={passwordshow ? 'icon-pwd-show' : 'icon-pwd-hide'}
					onClick={e => onTogglePassword()}></i>
                {meta.touched && meta.error ? <i className="icon-error"></i> : null}
            </div>
            {!meta.error && meta.touched && field.value !== '' && props?.passwordvalue == field.value  ? (
                <div className="validation">
                    <i className="icon-valid"></i>Passwords match
                </div>
            ) : (
                <div className="validation">{/* preserving this space */}</div>
            )}
        </>
    );
};
 
export const FormBirthdate = ({ label, labeltype, ...props }) => {
    const [field, meta] = useField(props);
    // logger.log('[FormBirthdate] label:%o meta:%o props:%o', label, meta, props);

    return (
        <div
            className={`${props.name}-field ${labeltype}-label ${meta.touched && meta.error ? 'error Active' : ''}`}>
            <label htmlFor={props.id || props.name} className="input-label">
                {label}{props.required && props.required === true ? <span className="required">*</span> : ''}
            </label>
            <MaskedInput mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]} {...field} {...props} />
            
            {meta.touched && meta.error ? <i className="icon-error"></i> : null}
        </div>
    );
};
 
export const FormCheckBox = ({ children, ...props }) => {
    // We need to tell useField what type of input this is
    // since React treats radios and checkboxes differently
    // than inputs/select/textarea.
    const [field, meta] = useField({ ...props, type: 'checkbox' });

    /** for Accessibility - space bar to check the checkbox */
    const changeCheckbox = (keyCode) => {
        const spacebarKeyCode = 32;
        const item = document.getElementById(props.name);
        const checked = item.getAttribute("value");
        if (keyCode && keyCode !== spacebarKeyCode) {
          return;
        } else if (checked === "true") {
          item.setAttribute("aria-checked", "false");
          item.setAttribute("value", "false");
        } else {
          item.setAttribute("aria-checked", "true");
          item.setAttribute("value", "true");
        }
      }

    return (
        <div className={`${props.name} checkbox-option`}>
            <input type="checkbox" {...field} {...props} aria-checked="false" />
            <label htmlFor={props.name} onClick={() => {changeCheckbox}} onKeyDown={(e) => {changeCheckbox(event.keyCode)}}>{children}</label>
        </div>
    );
};

export const FormRadioButton = ({ children, ...props }) => {
	// We need to tell useField what type of input this is
	// since React treats radios and checkboxes differently
	// than inputs/select/textarea.
	const [field, meta] = useField({ ...props, type: 'radio' });
    logger.log('[FormRadioButton] props:%o', props);

     /** for Accessibility - space bar to check the checkbox */
     const changeCheckbox = (keyCode) => {
        const spacebarKeyCode = 32;
        const item = document.getElementById(props.name);
        const checked = item.getAttribute("value");
        if (keyCode && keyCode !== spacebarKeyCode) {
          return;
        } else if (checked === "true") {
          item.setAttribute("aria-checked", "false");
          item.setAttribute("value", "false");
        } else {
          item.setAttribute("aria-checked", "true");
          item.setAttribute("value", "true");
        }
      }
	return (
		<>
            <div className={`${props.name} radio-option`}>
				<input type="radio" {...field} {...props} />
				<label htmlFor={props.id} onClick={() => {changeCheckbox}} onKeyDown={(e) => {changeCheckbox(event.keyCode)}}>
                    <i className={`icon-radio-${props.checked ? 'checked' : 'unchecked'}`} />
                    {children}
                </label>
			</div>
		</>
	);
};
 
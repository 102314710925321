/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import deps from 'dependencies';

import op from 'object-path';
import axios from 'axios';
import EventsLink from 'shared/general/EventsLink';
import LoadingIndicator from 'shared/general/LoadingIndicator';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { FormEmail } from 'shared/forms/elements/FormFields';
import Button from 'shared/general/Button';
import { writeErrors } from 'shared/forms/elements/Utils';
import { isEmpty } from 'lodash';
import MeasurementUtils from 'appdir/lib/analytics';
import CmsMessageContent from 'shared/general/CmsMessageContent';


/**
 * -----------------------------------------------------------------------------
 * React Component: MagicLinkForm
 * -----------------------------------------------------------------------------
 */

const MagicLinkForm = props => {
  logger.log('[MagicLinkForm] props:%o', props);

  const messageSettings = useSelector(state => state['Config']?.staticData?.cmsMessages?.data);
  // const eventConfig = useSelector(state => state['Config']?.eventConfig?.data);
  const site = useSelector(state => state['Config']?.dataSettings?.site);
  // const magicToken = useSelector(state => state['Config']?.dataSettings?.magicToken);
  // logger.log('[MagicLinkForm] messageSettings:%o', messageSettings);

  // const [submitted, setSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({});
  const loadingRef = useRef();
  const [inputValue, SetInputValue] = useState({'': {}})

  const handleAutoFill = (event) => {
    SetInputValue({
      [event.target.name]: {
        ...inputValue,
        active: event.animationName === 'onAutoFillStart' ? true : false,
        autofill: event.animationName === 'onAutoFillStart' ? true : false,
      },
    }); 
  }

  const handleFocus = (event, val, formProps) => {
    setErrors({submit:null});

    if (formProps) {
      formProps.handleBlur(event);
    }

    if (event.target.value === '') {
      SetInputValue({
        [event.target.name]: {
          ...inputValue,
          focus: val,
        },
      }); 
    } else {
      SetInputValue({
        [event.target.name]: {
          ...inputValue,
          focus: true,
        },
      }); 
    }
  }

  let MagicLinkSchema = yup.object({
    email: yup
      .string()
      .email('Please supply a valid Email Address')
      .required('Email Address is required')
  });

  const handleMagicLink = (values, setSubmitting) => {
    logger.log('[MagicLinkForm] handleSubmit - values:%o', values);
    
    /** show loading indicator while everyone is working hard to say yes come in or no */
		if (loadingRef.current) {
			loadingRef.current.classList.add('show');
		}

    axios({
			method: 'post',
			url: props.ticketsData.generateMagicLink,
      headers: {'Content-Type': 'application/json'},
			data: {
				provider: props.ticketsData.provider,
				username: values.email,
			}
		})
    .then(res => {
      logger.log('[MagicLinkFormSubmit] handleSubmit res:%o', res);
      if (res.status == 200) {
        setSubmitSuccess(true); 
        setErrors({});
        if (loadingRef.current) {
          loadingRef.current.classList.remove('show');
        }

        setSubmitting(false)

        MeasurementUtils.dispatchMeasurementCall('MagicLinkFormSubmit', {
          response: 'success',
          responseData: res,
          site: site
        });
        
        props.onFormSubmit(true);
      				
      } else {
          setSubmitSuccess(true); 
          
          if (loadingRef.current) {
            loadingRef.current.classList.remove('show');
          }

          setSubmitting(false)

          MeasurementUtils.dispatchMeasurementCall('MagicLinkFormSubmit', {
            response: 'failure',
            responseData: res,
            site: site
          });
      }
    })
    .catch(error => {
      logger.error('[MagicLinkFormSubmit] handleSubmit error:%o', error);
      if (error.response) {
        // setSubmitSuccess(false);
        if(error.response.status == 403) {
          setErrors({
            error: {
              //...errors,
              submit: messageSettings['login.secure_login_link_unable_send'],
            },
          })
        }

        if(error.response.status == 400) {
          setErrors({
            error: {
              //...errors,
              submit: messageSettings['login.secure_login_link_unable_send'],
            },
          })
        }
        
        if (setSubmitting) {
          setSubmitting(false)
        }
        if (loadingRef.current) {
          loadingRef.current.classList.remove('show');
        }
        MeasurementUtils.dispatchMeasurementCall('MagicLinkFormSubmit', {
          response: 'failure',
          responseData: errors,
          site: site
        });
      }

      MeasurementUtils.dispatchMeasurementCall('MagicLinkFormSubmit', {
        response: 'error',
        responseData: errors,
        site: site
      });
    });
  }

  const getLoadingIndicator = () => {
    logger.log('[MagicLinkForm] getLoadingIndicator');
    return (
      <div className="fadeOverlay" ref={loadingRef}>
        <LoadingIndicator />
      </div>
    );
  };

  return op.get(props.EventsWindow, 'windowSize', false) && !isEmpty(props.flowData) ? (
    <div className="form-container forgot-password">
      <div className="form-content">
        <div className="forgot-password-container">
          <div className="form-title">{messageSettings['login.secure_login_header']}</div>

          <Formik
            initialValues={{email: ''}}
            validationSchema={MagicLinkSchema}
            onSubmit={(values, { setSubmitting }) => {
              if((values.email == '')) {
                setSubmitting(false);
              } else {
                setSubmitting(true);
                handleMagicLink(values, setSubmitting)
              }
            }}
            enableReinitialize={true}
            key="forgotPassword">
            {formProps => {
              // logger.log('[ForgotPasswordForm] render - formProps:%o', formProps);
              return (
                <Form id="forgotPasswordForm">
                  {writeErrors(formProps, errors.error)}

                  <div className="form-text">
                    <CmsMessageContent id='login.secure_login_info' />
                  </div>						
                  
                  <FormEmail
                    id="email"
                    name="email"
                    label="Email"
                    labeltype="float"
                    onAnimationStart={e => handleAutoFill(e)}
                    onAnimationEnd={e =>
                      e.animationName === 'onAutoFillStart'
                        ? (formProps.touched.email = true)
                        : (formProps.touched.email = false)
                    }
                    onFocus={e => handleFocus(e, true)}
                    onBlur={e => handleFocus(e, false, formProps)}
                  />

                  <div className="form-text">
                    If you require further assistance, please contact the Masters Ticket Office at (706) 667-6700.
                  </div>

                  <div className="page-actions">
                      <input 
                        type="submit" 
                        disabled={ (!formProps.isValid || !formProps.dirty || formProps.isSubmitting)}
                        className={`button submit 
                              ${props.EventsWindow.numericWindowSize < 2 ? 'full' : ''}
                              ${!formProps.isValid || formProps.isSubmitting ? 'disabled' :''}`}
                        value={messageSettings['login.secure_login_submit_button']} 
                      />

                    <div className="action-link-container">
                      <EventsLink style="action-link" to={props.flowData.actions.magicLink.cancel}>
                        Cancel
                      </EventsLink>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>

      {getLoadingIndicator()}
    </div>
  ) : getLoadingIndicator()
}

export default MagicLinkForm;
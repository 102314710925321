import deps from 'dependencies';

export default {
	mount: () => (dispatch, getState, store) => {
		return deps.services.Config.ensureConfigurationLoaded(dispatch, store).then(Config => {
			dispatch({ type: deps.actionTypes.WINDOWSIZE_MOUNT, data: { status: 'loaded' } });
		});
	},
	setWindowSize: data => (dispatch, getState, store) => {
		//logger.log('[WindowSize] actions setWindowSize, data:%o', data);
		dispatch({ type: deps.actionTypes.WINDOWSIZE_SETWINDOWSIZE, data: data });
	},
};

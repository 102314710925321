/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import Template from 'components/Template';
import MeasurementUtils from 'appdir/lib/analytics';
import ContentHero from 'shared/cms/ContentHero';
import CmsMessageContent from 'shared/general/CmsMessageContent';
import ApplicationForm from 'shared/forms/ApplicationForm';

/**
 * -----------------------------------------------------------------------------
 * React Component: ApplicationContent
 * -----------------------------------------------------------------------------
 */

export default class ApplicationContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// isReady: false,
			// successURL: '/en_US/index.html',
		};

		this.successURLUpdated = false;

		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Application',
			userID: this.props?.userData?.userID,
			site: this.props?.userData?.eventName,
		});
	}

	componentDidMount() {
		// load the config web file here.
		this.props.loadConfig();

		this.setState({
			isReady: false,
			successURL: this.props?.flowData?.actions?.general?.signin,
		});
	}

	componentDidUpdate() {
		// logger.log('[Application] componentDidUpdate - this:%o', this);
		// is config web loaded?
	}

	render() {
		// logger.log('[Application] render this:%o', this);

		if (this.props.eventConfig && this.props.eventDays && this.props.ticketsData && this.props.userData) {
			return (
				<Template>
					<section className="page-content">
						<section className="page-title">
							<h1><CmsMessageContent id="general.page_header_title" textOnly={true} /></h1>
						</section>
						<section className="hero">
							<ContentHero />
						</section>
						<section className="section-title-wrapper">
							<h2>
								<CmsMessageContent id="application.header" />
							</h2>
						</section>

						<section className="page-grid cols-1">
							<div className="page-grid-item">
								<CmsMessageContent id="application.ticket_entry_instructions" />

								<ApplicationForm
									eventConfig={this.props.eventConfig}
									eventDays={this.props.eventDays}
									schema={this?.props?.attributes?.ApplicationSchema}
									ticketsData={this.props.ticketsData}
									userData={this.props.userData}
									flowData={this.props.flowData}
								/>
							</div>
						</section>
					</section>
				</Template>
			);
		} else {
			return null;
		}
	}
}

ApplicationContent.defaultProps = {};

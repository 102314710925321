/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';
import { Redirect } from 'react-router';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import CmsMessageContent from 'shared/general/CmsMessageContent';

import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { showBirthdateHelp, showInfoToolTip, writeErrors } from 'shared/forms/elements/Utils';
import { FormInput, FormBirthdate } from 'shared/forms/elements/FormFields';
import Button from 'shared/general/Button';
import EventsLink from 'shared/general/EventsLink';
import isEqual from 'lodash/isEqual';

import LoadingIndicator from 'shared/general/LoadingIndicator';
import MeasurementUtils from 'appdir/lib/analytics';

 
/**
 * -----------------------------------------------------------------------------
 * Functional Component: const AccountInformationForm = (props) => {
 * -----------------------------------------------------------------------------
 */
 const mapStateToProps = (state, props) => {
	return {
        requiresPin: op.get(state['Config'], 'addressValidation.requiresPin', {}),
        site: state?.Config?.dataSettings?.site,
		...props
	};
};

const mapDispatchToProps = dispatch => ({
	updateUserData: data => dispatch(deps.actions.UserData.updateUserData(data))
});
class AccountPinForm extends Component {
    constructor(props) {
		super(props);
		this.state = {
            success: false,
            errors: {}
        };

        this.loadingRef = React.createRef();
        this.submit = false;
        this.handleAction = this.handleAction.bind(this);

        logger.log('[AccountPinForm] props:%o', props);
	}

    componentWillUnmount() {
        this.submit = false;

        this.setState({
            success: false,
            errors: {}
        }) 
    }

    handleAutoFill(e) {
		// logger.log('[AccountPinForm] handleAutofill - e:%o', e.currentTarget);

		this.setState({
			[e.target.name]: {
				...this.state[e.target.name],
				active: e.animationName === 'onAutoFillStart' ? true : false,
				autofill: e.animationName === 'onAutoFillStart' ? true : false,
			},
		});
	}

	handleFocus(e, val, formProps) {
		// logger.log('[AccountPinForm] handleFocus');
		if (formProps) {
			formProps.handleBlur(e);
		}

		if (e.target.value === '') {
			this.setState({
				[e.target.name]: {
					...this.state[e.target.name],
					focus: val,
				},
			});
		} else {
			this.setState({
				[e.target.name]: {
					...this.state[e.target.name],
					focus: true,
				},
			});
		}
	}

    componentDidUpdate(prevProps) {
        if((!isEqual(prevProps.userData, this.props.userData) ||
            isEqual(prevProps.userData, this.props.userData)) && 
            this.submit === true
        ) {
            this.submit = false;

            this.setState({
                success: true,
                errors: {}
            }, () => {
                MeasurementUtils.dispatchMeasurementCall('AccountPin', {
                    action: 'next',
                    site: this.props.site,
                    userID: this.props.userData.userID,
                });
            })
        }
    }

    handleSubmit = (values) => {
        logger.log('[AccountPinForm] handleSubmit - this:%o', this);
        logger.log('[AccountPinForm] handleSubmit - values:%o', values);

        /** show loading indicator while everyone is working hard to create an account */
		// if (this.loadingRef.current) {
		// 	this.loadingRef.current.classList.add('show');
		// }

        let birthdateTmp = values.birthdate.split('/');

        logger.log('[AccountPinForm] handleSubmit - birthdateTmp:%o', birthdateTmp);

        let updatedUserData;
            
        if(this.props.requiresPin[this.props.userData.country]) {
            updatedUserData = {
                ...this.props.userData,
                ssn4: values.ssn4, 
                birthMonth: parseInt(birthdateTmp[0]),
                birthDay: parseInt(birthdateTmp[1]),
                birthYear: parseInt(birthdateTmp[2])
            }
        } else {
            updatedUserData = {
                ...this.props.userData,
                birthMonth: parseInt(birthdateTmp[0]),
                birthDay: parseInt(birthdateTmp[1]),
                birthYear: parseInt(birthdateTmp[2])
            }
        }

        this.submit = true;

        logger.log('[AccountPinForm] handleSubmit - updatedUserData:%o', updatedUserData);

        this.props.updateUserData(updatedUserData);
    }

    handleAction = (which) => {
        MeasurementUtils.dispatchMeasurementCall('AccountPin', {
            action: which,
            site: this.props.site,
            userID: this.props.userData.userID,
        }); 
    }

    getLoadingIndicator = () => {
		return (
			<div className="fadeOverlay" ref={this.loadingRef}>
				<LoadingIndicator />
			</div>
		);
	};

    toggleHelp = (help) => {
    let displayBirthdayHelp;
    let displaySsn4Help;

    if (help == 'birthday') {
      displayBirthdayHelp = this.state.birthdateHelp ? false : true;
    }

		if (help == 'ssn4') {
      displaySsn4Help = this.state.ssn4Help ? false : true;
    }

		// logger.log('[AccountInformation] toggleBirthdateHelp - display:%o', display);

		// MeasurementUtils.dispatchMeasurementCall('AccountInformationBirthdateHelpToggle', {
		// 	webview: this.isWebview,
		// 	display,
		// });

		this.setState({
			birthdateHelp: displayBirthdayHelp,
      ssn4Help: displaySsn4Help,
		}, () => {
            MeasurementUtils.dispatchMeasurementCall('AccountPin', {
                action: 'toggleBirthday',
                value: displayBirthdayHelp,
                site: this.props.site,
                userID: this.props.userData.userID,
            });

        });
	};
 
    render() {
        logger.log('[AccountPinForm] render - this:%o', this);

        let { userData } = this.props;
        let _this = this;

        let birth_month = userData.birthMonth && userData.birthMonth !== '' ? userData.birthMonth : '0';
        let birth_day = userData.birthDay && userData.birthDay !== '' ? userData.birthDay : '0';
        let birth_year = userData.birthYear && userData.birthYear !== '' ? userData.birthYear : '0'

       // logger.log('[AccountPinForm] render - birth_month:%o', birth_month);

        if (birth_month.toString().length < 2) { birth_month = `0${birth_month}`; }
        if (birth_day.toString().length < 2) { birth_day = `0${birth_day}`; }
        if (birth_year.toString().length == 1) { birth_year = `000${birth_year}`; }
        if (birth_year.toString().length == 2) { birth_year = `00${birth_year}`; }
        if (birth_year.toString().length == 3) { birth_year = `0${birth_year}`; }
        let birth_date = [birth_month, birth_day, birth_year].join('/');

        if(birth_date == '00/00/0000') {
            birth_date = '';
        }

        let AccountPinSchema;

        if(this.props.requiresPin[userData.country]) {
            this.AccountPinValues = {
                ssn4: op.get(userData, 'ssn4') ? userData.ssn4 : '',
                ssn4confirm: op.get(userData, 'ssn4') ? userData.ssn4 : '',
                birthdate: birth_date,
            };

            AccountPinSchema = yup.object({
                ssn4: yup
                    .string()
                    .matches(new RegExp(this.props.ticketsData.ssnRegex), 'SSN4 should be 4 numbers')
                    .required('Last 4 Digits of SSN/SIN is required'),
                ssn4confirm: yup.string()
                    .required('Last 4 Digits of SSN/SIN (confirmation) is required')
                    .oneOf([yup.ref('ssn4'), null], 'Last 4 Digits of SSN/SIN must match'),
                birthdate: yup
                    .string()
				    .required('Birthdate is required')
                    .test('birthdate',this.errorMessage, function(value) {
                            let validDate = value ? true : false;
                            if (value) {
                                let tmp = value.split('/');
                                let birthDate = new Date(value);
                                let currentDate = new Date();
                                let bdayRegex = new RegExp(_this.props.ticketsData.birthdayRegex);

                                if(!bdayRegex.test(value)) {
                                    this.errorMessage = "Please format Birthday as MM/DD/YYYY";
                                    validDate = false;
                                }
                        
                                if (birthDate.getFullYear() != tmp[2] || birthDate.getMonth() + 1 != tmp[0] || birthDate.getDate() != tmp[1]) {	
                                    this.errorMessage = "Please enter a valid Birthday";
                                    validDate = false;
                                } 
                            
                                if(moment(currentDate).diff(moment(birthDate), 'years') < 21) {
                                    this.errorMessage = "You must be 21 or older to create an account";
                                    validDate = false;
                                }
                            }
                            return validDate === false
                                ? this.createError({
                                    message: this.errorMessage,
                                    path: 'birthdate', // Fieldname
                                    })
                                : true;
                    })
            });
        } else {
            this.AccountPinValues = {
                birthdate: birth_date,
            };

            AccountPinSchema = yup.object({
                birthdate: yup
                    .string()
				    .required('Birthdate is required')
                    .test('birthdate',this.errorMessage, function(value) {
                            let validDate = value ? true : false;
                            if (value) {
                                let tmp = value.split('/');
                                let birthDate = new Date(value);
                                let currentDate = new Date();
                                let bdayRegex = new RegExp(_this.props.ticketsData.birthdayRegex);

                                if(!bdayRegex.test(value)) {
                                    this.errorMessage = "Please format Birthday as MM/DD/YYYY";
                                    validDate = false;
                                }
                        
                                if (birthDate.getFullYear() != tmp[2] || birthDate.getMonth() + 1 != tmp[0] || birthDate.getDate() != tmp[1]) {	
                                    this.errorMessage = "Please enter a valid Birthday";
                                    validDate = false;
                                } 
                            
                                if(moment(currentDate).diff(moment(birthDate), 'years') < 21) {
                                    this.errorMessage = "You must be 21 or older to create an account";
                                    validDate = false;
                                }
                            }
                            return validDate === false
                                ? this.createError({
                                    message: this.errorMessage,
                                    path: 'birthdate', // Fieldname
                                    })
                                : true;
                    })
            });
        }

        if (this.state.success === true && op.get(this.props, 'flowData.actions.accountPin.next', null)) {
			return <Redirect push to={this.props.flowData.actions.accountPin.next} />;
		}

        return (
            <div className="form-container account-pin">
				<div className="form-content">
					<div className="account-pin-container">
                        {this.props.requiresPin[userData.country]
                        ?<h3><CmsMessageContent id="account.pin_dob_title" textOnly={true} /></h3>
                        :<h3>Date of Birth</h3>}

						<Formik
							initialValues={this.AccountPinValues}
							validationSchema={AccountPinSchema}
							onSubmit={this.handleSubmit}
							enableReinitialize={true}
							key="accountInformation">
							{formProps => {
								// logger.log('[AccountPinForm] render - formProps:%o', formProps);
								return (
									<Form id="accountPinForm">
										{writeErrors(formProps, this.state.errors)}

                                        {this.props.requiresPin[userData.country] ?
                                            <>
                                                <div className="two-columns long">
                                                  <div className="ssn4-container">
                                                    <FormInput
                                                        label="Last 4 Digits of SSN/SIN"
                                                        labeltype="float"
                                                        name="ssn4"
                                                        type="password"
                                                        required={true}
                                                        onAnimationStart={e => this.handleAutoFill(e)}
                                                        onAnimationEnd={e =>
                                                            e.animationName === 'onAutoFillStart'
                                                                ? (formProps.touched.ssn4 = true)
                                                                : (formProps.touched.ssn4 = false)
                                                        }
                                                        onFocus={e => this.handleFocus(e, true)}
                                                        onBlur={e => this.handleFocus(e, false, formProps)}
                                                    />
                                                    {this.state.ssn4Help === true ? showInfoToolTip({message: <CmsMessageContent id="account.ssn4_tooltip" textOnly={true} />}) : null}
                                                <i
                                                    className={`icon-help${this.state.ssn4Help === true ? ' active' : ''}`}
                                                    onClick={() => {
                                                        this.toggleHelp("ssn4");
                                                    }}></i>
                                                  </div>
                                                </div>
                                            
                                                <div className="two-columns long">
                                                    <FormInput
                                                        label="Last 4 Digits of SSN/SIN (confirmation)"
                                                        labeltype="float"
                                                        name="ssn4confirm"
                                                        type="password"
                                                        required={true}
                                                        onAnimationStart={e => this.handleAutoFill(e)}
                                                        onAnimationEnd={e =>
                                                            e.animationName === 'onAutoFillStart'
                                                                ? (formProps.touched.ssn4confirm = true)
                                                                : (formProps.touched.ssn4confirm = false)
                                                        }
                                                        onFocus={e => this.handleFocus(e, true)}
                                                        onBlur={e => this.handleFocus(e, false, formProps)}
                                                    />
                                                </div>
                                            </>
                                            : null
                                        }

                                        <div className="two-columns long">
                                            <div className="birthdate-container">
                                                <FormBirthdate
                                                    label="Birthday"
                                                    labeltype="fixed"
                                                    name="birthdate"
                                                    type="text"
                                                    required={true}
                                                    placeholder="MM/DD/YYYY"
                                                    onFocus={e => this.handleFocus(e, true)}
                                                    onBlur={e => this.handleFocus(e, false, formProps)}
                                                />
                                                {this.state.birthdateHelp === true ? showInfoToolTip({message: <CmsMessageContent id="account.dob_tooltip" textOnly={true} />}) : null}
                                                <i
                                                    className={`icon-help${this.state.birthdateHelp === true ? ' active' : ''}`}
                                                    onClick={() => {
                                                        this.toggleHelp("birthday");
                                                    }}></i>
                                            </div>
                                        </div>

                                        <div className="page-actions">
                                            <Button
                                                    style={this.props.numericWindowSize < 3 ? 'full' : ''}
                                                    buttonState={!formProps.isValid ? 'disabled' : ''}
                                                    onClick={() => {
                                                        if (formProps.isValid) {
                                                            formProps.submitForm();
                                                        }
                                                    }}
                                                    buttonText="Next" />
                                            <div className="action-link-container">
                                                <EventsLink style="action-link" onClick={() => this.handleAction('previous')} to={this.props.requiresPin[userData.country] ? op.get(this.props, 'flowData.actions.accountPin.previous', '') : op.get(this.props, 'flowData.actions.accountPin.previousIntl', '')}>
													Previous
												</EventsLink>
											</div>
                                            <div className="action-link-container">
                                                <EventsLink style="action-link" onClick={() => this.handleAction('cancel')} to={op.get(this.props, 'flowData.actions.accountPin.cancel', '')}>
													Cancel
												</EventsLink>
											</div>
                                        </div>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </div>
                </div>
                {this.getLoadingIndicator()}
            </div>
        );
    }
};
 
export default connect(mapStateToProps, mapDispatchToProps)(AccountPinForm);
 
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import JSXParser from 'react-jsx-parser';
import ErrorBoundary from 'shared/general/ErrorBoundary';

export const renderErrorItem = (err, i) => {
	const onError = (e) => {
		logger.error('[renderErrorItem] onError - e: %o', e);
		throw new Error("Unable to render this message.  Most likely a tag is not being replaced with data.  Check to see if props.data has the correct content");
	}

	return (
		<div className="error-item" key={`error-${i}`}>
			<i className="icon-error"></i>
			<span className="error-text">
				<ErrorBoundary message="Unable to render content.">
					<JSXParser 
						jsx={err}
						onError={(e) => onError(e)}
					/>
				</ErrorBoundary>
			</span>
		</div>
	);
};

export const fieldHasError = (field, formProps) => {
	if (formProps.errors[field] && (formProps.touched[field] === true || formProps?.touched?.[field]?.value === true)) {
		return true;
	} else {
		return false;
	}
};

const formHasErrors = (formProps) => {
	// logger.log('[Utils] formHasErrors - formProps%o', formProps);

	let hasErrors = false;

	if (formProps && !isEmpty(formProps)) {
		Object.keys(formProps?.errors).map((value, i) => {
			if (formProps.touched[value] === true || formProps?.touched?.[value]?.value === true) {
				hasErrors = true;
			}
		});
	}
	

	return hasErrors;
};

export const writeErrors = (formProps, pageErrors) => {
	//logger.log('[Utils] writeErrors - formProps%o, pageErrors:%o', formProps, pageErrors);
	if (formHasErrors(formProps) || !isEmpty(pageErrors)) {
		let errorList = [];

		if(formProps && !isEmpty(formProps) && formHasErrors(formProps)) {
			Object.keys(formProps?.errors).map((value, i) => {
				if (fieldHasError(value, formProps)) {
					errorList.push(formProps.errors[value]);
				}
			})
		}

		if(!isEmpty(pageErrors)) {
			Object.keys(pageErrors).map((value, i) => {
				errorList.push(pageErrors[value]);
		  	})
		}
		
		//logger.log('[Utils] writeErrors - errorList:%o', errorList);

		errorList = uniq(errorList);

		//logger.log('[Utils] writeErrors - errorList:%o', errorList);

		return (
			<div className="error-field">
				{
					errorList.map((error, i) => {
						return renderErrorItem(error, i);
					})
				}
			</div>
		);
	} else {
		return null;
	}
};

export const showInfoToolTip = (props) => {
	return (
		<div className="helpToolTip">
			{/* You must be 21 years of age or older to apply for tickets. */}
      <>{props.message}</>
			<div className="help-arrow"></div>
		</div>
	);
};

// export const showSsn4Help = () => {
// 	return (
// 		<div className="ssn4-help">
// 			You must be 21 years of age or older to apply for tickets.
// 			<div className="birthdate-help-arrow"></div>
// 		</div>
// 	);
// };

export const getUserAvatar = userData => {
	// logger.log('[Utils] - getUserAvatar - userData:%o', userData);
	let data = [];
	let src;

	if (!Array.isArray(userData)) {
		// logger.log('[Utils] - getUserAvatar - data is not an array');
		data.push(userData);

		// logger.log('[Utils] - getUserAvatar - data:%o', data);
	}

	if (data.length > 0) {
		src = data;
	} else {
		src = userData;
	}

	// logger.log('[Utils] - getUserAvatar - src:%o', src);

	let userAvatar = src.filter(function(r) {
		// logger.log('[Utils] - getUserAvatar - r:%o', r);
		return r.recordId === 'holeAvatar';
	});

	// logger.log('[Utils] - getUserAvatar - userAvatar:%o', userAvatar);

	return userAvatar;
};

export const getPlatformID = () => {
	let pid;

	//web
	if (!window.webview) {
		pid = 1;
	}
	//iOS
	if (window.webview && window.webkit) {
		pid = 2;
	}
	//android
	if (window.webview && window.JSLoginInterface) {
		pid = 3;
	}
	//appletv
	// if() {
	// 	pid = 4;
	// }

	return pid;
};
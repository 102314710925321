/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
 import React from 'react';
 import JSXParser from 'react-jsx-parser';
 import { useSelector } from 'react-redux';
 import ErrorBoundary from 'shared/general/ErrorBoundary';

 /**
 * -----------------------------------------------------------------------------
 * React Component: Account Information
 * -----------------------------------------------------------------------------
 */
 
const TicketApplication = (props) => {
	const userData = useSelector(state => state['UserData']?.userData);

	const getKeys = function(){
		return Object.keys(props.data[0]);
		}
	
	const getHeader = function(){
		var keys = getKeys();
		return keys.map((key, index)=>{
			return <th key={key+index} className={props.highlight.toUpperCase() == key.toUpperCase() ? 'highlight': ''}>{key.toUpperCase()}</th>
		})
	}

	const getTitle = () => {
		return (
			<th colSpan={4}>
				{props.title ? <span className='title'>{props.title}</span> : null}
				{props.subTitle ? <span className='subtitle'>{props.subTitle}</span> : null}
			</th>
		)
	}
	
	const getRowsData = function(){
		var items = props.data;
		var keys = getKeys();
		return items.map((row, index)=>{
		return <tr key={`tr-${index}`}><RenderRow data={row} keys={keys}/></tr>
		})
	}

	return (
		<div className="ticket-application">
			{/* {
			props.title && props.title !== '' 
				? <h4 className="ticket-title">{props.title}</h4>
				: null
			}

			{
			props.subTitle && props.subTitle !== '' 
				? <p>{props.subTitle}</p>
				: null
			} */}
			
			
			{
				userData.eventName == 'psb' ? (
					<table>
						<thead>
							{/* <tr className="titleRow">{getTitle()}</tr> */}
							<tr>{getHeader()}</tr>
						</thead>
						<tbody>
							{getRowsData()}
						</tbody>
					</table>
				) : (
					<table>
						<thead>
							<tr className="titleRow">{getTitle()}</tr>
							<tr>{getHeader()}</tr>
						</thead>
						<tbody>
							{getRowsData()}
						</tbody>
					</table>
				)
			}
			
		</div>
	);
}

export default TicketApplication; 

const RenderRow = (props) =>{
	return props.keys.map((key, index)=>{
		return <td key={'td-'+index}><ErrorBoundary message="Unable to render content."><JSXParser jsx={`${props.data[key]}`}></JSXParser></ErrorBoundary></td>
	})
}
		

 
/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
 import AccountProfileContent from './AccountProfileContent';
 import { connect } from 'react-redux';
 import deps from 'dependencies';
 import op from 'object-path';
 
 /**
  * -----------------------------------------------------------------------------
  * React Component: AccountProfileContent
  * -----------------------------------------------------------------------------
  */
 
 const mapStateToProps = (state, props) => {
	 return {
		 ...state.AccountProfileContent,
		 siteConfigStatus: state['Config'].status,
		 ticketsData: op.get(state['Config'], 'ticketsData', {}),
		 userData: op.get(state['UserData'], 'userData', {}),
		 tempUserData: op.get(state['UserData'], 'tempUserData', {}),
		 flowData: op.get(state['Config'], 'staticData.flow.data', {}),
		 countries: op.get(state['Config'], 'staticData.countries', {}),
		 formOptions: op.get(state['Config'], 'staticData.formOptions', {}),
		 messageSettings: op.get(state['Config'], 'staticData.cmsMessages.data'),
		 invalidStatusIds: op.get(state['Config'], 'addressValidation.invalidStatusIds', {}),
		 eventConfig: op.get(state['Config'], 'eventConfig.data', {}),
		 Router: state['Router'],
		 pageTitle: state?.Config?.otherData?.pageTitle,
		 site: state?.Config?.dataSettings?.site,
		 ...props,
	 };
 };
 
 const mapDispatchToProps = (dispatch, props) => ({
	 loadConfig: () => dispatch(deps.actions.Config.loadConfig()),
 });
 
 export default connect(mapStateToProps, mapDispatchToProps)(AccountProfileContent);
 
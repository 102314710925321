import deps from 'dependencies';

export default (state = {}, action) => {
	let newState;

	switch (action.type) {
		case deps.actionTypes.USERDATA_MOUNT: {
			newState = {
				...state,
				...action.data,
			};

			logger.log('[UserData] reducer - USERDATA_MOUNT: %o', newState);
			return newState;
		}
		case deps.actionTypes.USERDATA_UPDATE_USER_DATA: {
			logger.log('[UserData] reducer - USERDATA_UPDATE_USER_DATA: %o', action);

			newState = {
				...state,
				userData: {
					...state.userData,
					...action.data,
				}
			};

			newState.userData['email'] = newState.userData['email'].toLowerCase();

			if (action.pendingEmail && action['pendingEmail'].toLowerCase() !== newState.userData['email'].toLowerCase()) {
				newState["pendingEmail"] = action.pendingEmail.toLowerCase();
			}

			logger.log('[UserData] reducer - USERDATA_UPDATE_USER_DATA: %o', newState);
			return newState;
		}
		case deps.actionTypes.USERDATA_LOGOUT_USER: {
			// logger.log('[UserData] reducer - USERDATA_LOGOUT_USER: %o', action);

			newState = {
				...state,
				userData: {},
				tempUserData: null,
				pendingEmail: null
			};
			logger.log('[UserData] reducer - USERDATA_LOGOUT_USER: %o', newState);
			return newState;
		}
		case deps.actionTypes.USERDATA_UPDATE_TEMP_USER_DATA: {
			logger.log('[UserData] reducer - USERDATA_UPDATE_TEMP_USER_DATA: %o', action);

			newState = {
				...state,
				tempUserData: {
					...state.userData,
				},
			};

			logger.log('[UserData] reducer - USERDATA_UPDATE_TEMP_USER_DATA: %o', newState);
			return newState;
		}
		case deps.actionTypes.USERDATA_REFRESH: {
			logger.log('[UserData] reducer - USERDATA_REFRESH: %o', action);

			newState = {
				...state,
				userData: {
					...state.userData,
					...action.data,
				},
				tempUserData: {
					...state.userData,
					...action.data,
				}
			};

			logger.log('[UserData] reducer - USERDATA_REFRESH: %o', newState);
			return newState;
		}
		case deps.actionTypes.USERDATA_DELETE_TEMP_USER_DATA: {
			logger.log('[UserData] reducer - USERDATA_DELETE_TEMP_USER_DATA: %o', action);

			newState = {
				...state,
			};

			delete newState.tempUserData;

			logger.log('[UserData] reducer - USERDATA_DELETE_TEMP_USER_DATA: %o', newState);
			return newState;
		}
		default:
			return state;
	}
};

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import MastersImage from 'shared/general/MastersImage';

/**
 * -----------------------------------------------------------------------------
 * React Component: Hero
 * -----------------------------------------------------------------------------
 */

const ContentHero = props => {
	return (
		<section
			className={`hero-section content-page ${
				props.heroType ? props.heroType : ''
			} ${props.className ? props.className : ''}`}>
			<MastersImage imageConfig={props.imageConfig} />
		</section>
	);
}
ContentHero.defaultProps = {
	heroType: 'quarter-height',
	imageConfig:{
		image: {
			alt: 'this is the alt',
		},
		imageList: {
			small: '/assets/images/headers/header_patron_sm.jpg',
			medium: '/assets/images/headers/header_patron_qtr.jpg',
			high: '/assets/images/headers/header_patron_qtr.jpg',
			default: '/assets/images/headers/header_patron_qtr.jpg',
		},
	}
};
export default ContentHero;

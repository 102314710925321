import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router';
import Template from 'components/Template';
import LoadingIndicator from 'shared/general/LoadingIndicator';
import ContentHero from 'shared/cms/ContentHero';
import ContentInfo from 'shared/general/ContentInfo';
import cn from 'classnames';
import op from 'object-path';
import Button from 'shared/general/Button';
import { isEmpty } from 'lodash';
import SignatureForm from 'shared/forms/SignatureForm';
import MeasurementUtils from 'appdir/lib/analytics';
import CmsMessageContent from 'shared/general/CmsMessageContent';
import {getHomePage, getPaymentSteps } from 'shared/general/Util';
import StepIndicator from 'shared/general/StepIndicator';


export default class ContentPageContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			leadArticleCaption: false,
			// silvia:  i added this
			xmlStatus: 'notReady',
			privacyAccepted: false,
		};
		this.content = null;
		this.contentHero = null;
		this.printPage = this.printPage.bind(this);
		//this.showShareModal = this.showShareModal.bind(this);
	}

	printPage() {
		let category = op.get(this.props, 'Router.params.contentCategory', null);
		let action = op.get(this.props, 'Router.params.action', 'index');

		MeasurementUtils.dispatchMeasurementCall('printPage', {
			site: this.props.site,
			category,
			action,
			userID: this?.props?.userData?.userID,
		});

		var prtArticle = document.getElementById('print-content');
		var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
		WinPrint.document.write(
			'<html><head><style>@media print{h1{color:#006747;font-size:40px;}.hero{display:none;}h2{font-size:30px;color:#006747;} .printLogo{width:30%;}}</style><title></title>'
		);
		WinPrint.document.write('</head><body onload="window.print();window.close()">');
		WinPrint.document.write(prtArticle.innerHTML);
		WinPrint.document.write('</body> </html');
		WinPrint.document.close();
	}

	componentCleanup() {
		logger.log('[ContentPageContent] componentCleanup - state:%o', this.state);
		this.content = null;
		this.contentHero = null;
		//	this.props.unmount();
	}

	componentWillUnmount() {
		logger.log('[ContentPageContent] componentWilUnmount - state:%o', this.state);
		this.componentCleanup();
		window.removeEventListener('beforeunload', this.componentCleanup); // remove the event handler for normal unmounting
	}

	componentDidMount() {
		// load the config web file here.
		this?.props?.loadConfig();

		//logger.log('[ContentPageContent] componentDidMount - state:%o', this.state);
		//logger.log('[ContentPageContent] componentDidMount - props:%o', a);

		// not sure why this is here.  probably here because we didn't know what we were doing.
		window.addEventListener('beforeunload', this.componentCleanup);
	}

	componentDidUpdate(prevProps, prevState) {
		logger.log('[ContentPageContent] - componentDidUpdate this:%o', this);
		logger.log('[ContentPageContent] - componentDidUpdate siteConfigStatus:%o', this.props.siteConfigStatus);

		// silvia:  don't load xml until we know the config web is loaded
		if (this?.props?.siteConfigStatus == 'loaded' && this.state.xmlStatus == 'notReady') {
			logger.log('[ContentPageContent] - componentDidUpdate set xmlStatus:%o, time to load xml', this.state.xmlStatus);
			this.setState({
				xmlStatus: 'ready',
			});
		}

		// silvia:  xmlStatus changed from notReady to ready.  We are ready to load the xml now
		if (prevState.xmlStatus !== this.state.xmlStatus && this.state.xmlStatus == 'ready') {
			logger.log('[ContentPageContent] - componentDidUpdate xmlStatus:%o, time to load xml', this.state.xmlStatus);

			// which xml??  this is the category from the route.  shows up in the props
			if (this?.props?.Router?.params?.contentCategory) {
				logger.log(
					'[ContentPageContent] - componentDidUpdate cms category:%o',
					this?.props?.Router?.params?.contentCategory
				);
				let category = this?.props?.Router?.params?.contentCategory;
				let path = op.get(this.props, `cmsConfig.${category}`);
				logger.log('[ContentPageContent] - componentDidUpdate path:%o', path);

				// silvia:  let's fetch the right xml here.  see index.html for where loadContent is defined to props.
				this?.props?.loadContent(path);
			}
		}

		//  silvia:  check to see if user went to a different page (ie:  the category changed -- url)
		if (op.get(prevProps, 'Router.params.contentCategory') !== op.get(this.props, 'Router.params.contentCategory')) {
			this.setState({
				xmlStatus: 'notReady',
			});
		}
	}

	handleAccept = () => {
		let category = op.get(this.props, 'Router.params.contentCategory', null);
		let action = op.get(this.props, 'Router.params.action', 'index');
		let data = {
			privacyAgreement: true,
		};
		this.props.updatePatronData(data);

		this.setState(
			{
				privacyAccepted: true,
			},
			() => {
				MeasurementUtils.dispatchMeasurementCall('privacyAccept', {
					site: this.props.site,
					accept: this?.state?.privacyAccepted,
					category,
					action,
					userID: this?.props?.userData?.userID,
				});
			}
		);
	};

	goBackPage = () => {
		let category = op.get(this.props, 'Router.params.contentCategory', null);
		let action = op.get(this.props, 'Router.params.action', 'index');
		MeasurementUtils.dispatchMeasurementCall('goBack', {
			site: this?.props?.site,
			category,
			action,
			userID: this?.props?.userData?.userID,
		});
		history.back();
	};

	render() {
		logger.log('[ContentPageContent] render - this: %o', this);
		// logger.log('[ContentPageContent] render - this.state: %o', this.state);

		this.content = {} = this?.props?.content;
		this.contentHero = this?.props?.contentHero;
		let category = op.get(this.props, 'Router.params.contentCategory', null);
		let action = op.get(this.props, 'Router.params.action', 'index');
		let paymentSteps = getPaymentSteps(this?.props?.userData, this?.props?.messageSettings?.['system.mobile_verification'], this?.props?.eventConfig, this?.props?.requiresVerifyId);

		//logger.log('[ContentPageContent] render - category: %o, action:%o', category, action);

		if (this.state.privacyAccepted === true && action == 'agree' && !isEmpty(this?.props?.flowData)) {
			if (this?.props?.userData?.eventName == 'psb') {
				// go to psb user's home page
				let homePage = getHomePage(this?.props?.userData, this?.props?.eventConfig, this?.props?.flowData);
				//logger.log('[ContentPageContent] render - psb homePage:%o', homePage);
				return <Redirect push to={homePage} />;
			} else {
				return <Redirect push to={this?.props?.flowData?.actions?.privacyAgree?.accept} />;
			}
		} else if (action == 'agree' && !this.props?.userData?.token) {
			return <Redirect push to={this?.props?.flowData?.actions?.general?.signin} />;
		}

		const className = cn('page-content', {
			loading: !this.content.jsx,
			'print-page': action == 'index',
			'accept-page': action == 'agree',
		});

		return (
			<Template>
				{this.content.jsx && !isEmpty(this?.props?.flowData) ? (
					// <>
					<section className={className} id="print-content">
						<img className="printLogo" src="/assets/images/nav/masters_logo.jpg" />
						<section className="page-title">
							<h1><CmsMessageContent id="general.page_header_title" textOnly={true} /></h1>
						</section>
						<article>
							<section className="hero">
								<ContentHero heroType="quarter-height" imageConfig={this.contentHero} />
							</section>
							<div id="article-content" className="text centered extra_padding">
								{this?.props?.userData?.application?.isWinner && !this?.props?.userData?.application?.hasAcceptedTicketAgreement ? 
									<StepIndicator
									type="winner"
									showWinnerSplash={false}
									stepLabels={this?.props?.messageSettings?.[paymentSteps?.id]}
									currentStep={2}
									category={category}
									action={action}
									/>                
								: null }
								<ContentInfo contentZone="content-page-content" />
							</div>

							{category == 'privacy' ? (
								<div className="policy-buttons text centered">
									<Button buttonText="Print" 
                    // onClick={this.printPage} 
                    external={true}
                    to={this?.props?.flowData?.actions?.print?.privacy}
                  />
									{action == 'index' ? <Button buttonText="Back" onClick={this?.goBackPage} /> : null}
									{action == 'agree' && this.props?.userData?.token ? (
										<Button buttonText="Accept and Continue" onClick={this?.handleAccept} />
									) : null}
								</div>
							) : null}
							{category == 'terms' && action == 'index' ? (
								<div className="policy-buttons text centered">
                  <Button buttonText="Print" 
                    // onClick={this.printPage} 
                    external={true}
                    to={this?.props?.flowData?.actions?.print?.terms}
                  />
									{/* <Button buttonText="Back" onClick={this.goBackPage} /> */}
								</div>
							) : null}
							{category == 'terms' && action == 'agree' && this.props?.userData?.token ? (
								<SignatureForm />
							) : null}

							{category == 'ticketsInfo' || category == 'ticketsFAQ' || category == 'ticketsPolicies' ? (
								<div className="policy-buttons text centered">
									{(category == 'ticketsInfo' && this?.props?.userData?.token) ||
									category == 'ticketsFAQ' ||
									category == 'ticketsPolicies' ? (
										<Button buttonText="Back" onClick={this.goBackPage} />
									) : (
										<Button
											buttonText="Continue to Ticketing"
											to={this.props?.flowData?.actions?.general?.signin}
										/>
									)}
								</div>
							) : null}
						</article>
					</section>
				) : (
					// </>
					<LoadingIndicator />
				)}
			</Template>
		);
	}
}

ContentPageContent.defaultProps = {
	contentHero: {
		image: {
			alt: 'this is the alt',
		},
		imageList: {
			small: '/assets/images/headers/header_patron_sm.jpg',
			medium: '/assets/images/headers/header_patron_qtr.jpg',
			high: '/assets/images/headers/header_patron_qtr.jpg',
			default: '/assets/images/headers/header_patron_qtr.jpg',
		},
	},
};

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import isEmpty from 'lodash/isEmpty';

import op from 'object-path';
import LogInForm from 'shared/forms/LogInForm';
import CreateAccountForm from 'shared/forms/CreateAccountForm';
import MeasurementUtils from 'appdir/lib/analytics';
import LoadingIndicator from 'shared/general/LoadingIndicator';

/**
 * -----------------------------------------------------------------------------
 * React Component: LogInView
 * 
 * includes LogIn and CreateAccountForm subcomponents
 * -----------------------------------------------------------------------------
 */

 const mapStateToProps = (state, props) => {
	return {
		...props,
		site: state?.Config?.dataSettings?.site,
		numericWindowSize: op.get(state['WindowSize'], 'EventsWindow.numericWindowSize', {}),
		Router: state['Router'],
	};
};
class LogInView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentview: 'login',
			showLoading: false
		};
		
		logger.info('[LogInView] constructor - this:%o', this);
		this.showLoadingCallback = this.showLoadingCallback.bind(this);
	}

	changeView = (which) => {
		logger.log('[LogInView] changeView - which %o', which);

		this.setState({
			currentview: which,
		}, () => {
			MeasurementUtils.dispatchMeasurementCall('changeView', {
				site: this.props.site,
				currentView: this.state.currentview,
			});
		});
	}

	showLoadingCallback = (show) => {
		logger.log('[LogInView] showLoadingCallback - show %o', show);

		this.setState({
			showLoading: show
		})
	}

	render() {
		logger.log('[LogInView] render - this %o', this);
		const displayLoading = this?.props?.Router?.params?.homePage == 'login' && this?.state?.showLoading;
		logger.log('[LogInView] render - displayLoading %o', displayLoading);

		return op.get(this.props, 'numericWindowSize', false) >= 0 ?
		 	( <>
				<div style={displayLoading ? {visibility:"hidden"}: {}} className={`form-container log-in-view ${this.props.site == 'psb' ? 'patron' : ''}`}>
					<div className="form-content">
						
						{(this.props.numericWindowSize < 2 && this.state.currentview == 'login') || this.props.numericWindowSize > 1 ?
							<LogInForm 
								onViewChange={this.changeView} 
								ticketsData={this.props.ticketsData}
								flowData={this.props.flowData}
								showLoading = {this.showLoadingCallback}
							/>
							: null
						}

						{this.props.site !== 'psb' && this.props.numericWindowSize > 1 ?
							<div className="form-divider"></div>
							: null
						}

						{this.props.site !== 'psb' && ((this.props.numericWindowSize < 2 && this.state.currentview == 'createaccount') || this.props.numericWindowSize > 1) ?	
							<CreateAccountForm ticketsData={this.props.ticketsData} flowData={this.props.flowData} />
							: null
						}

					</div>
				</div>

				{displayLoading ? (
					<div className="fadeOverlay" ref={this.loadingRef}>
						<LoadingIndicator />
					</div>
				) : null}
			</>
			) :
			null
	}
}

export default connect(mapStateToProps)(LogInView);

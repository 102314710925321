/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React from 'react';
import EventsLink from 'shared/general/EventsLink';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: Back Link
 *
 * props - klass, path, linkText are all optional
 * -----------------------------------------------------------------------------
 */
const BackLink = props => {
	// logger.log('[BackLink] props:%o', props);
	let klass = props.klass ? props.klass : '';
	let path = props.path ? props.path : window.history.go(-1);
	let linkText = props.linkText ? props.linkText : 'Back';

	return (
		<section className={`back-link ${klass}`}>
			<div className="backlink">
				<EventsLink to={path}>{linkText}</EventsLink>
			</div>
		</section>
	);
};

export default BackLink;

BackLink.defaultProps = {
	// klass: 'page-grid col-1',
	// path: '/en_US/tournament/index.html',
	// linkText: 'Tournament Info'
};

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import ChangePasswordContent from './ChangePasswordContent';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';

/**
 * -----------------------------------------------------------------------------
 * React Component: ChangePasswordContent
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state.ChangePasswordContent,
		siteConfigStatus: state['Config'].status,
		ticketsData: op.get(state['Config'], 'ticketsData', {}),
		EventsWindow: op.get(state['WindowSize'], 'EventsWindow', {}),
		flowData: op.get(state['Config'], 'staticData.flow.data', {}),
		pageTitle: state?.Config?.otherData?.pageTitle,
		site: state?.Config?.dataSettings?.site,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	loadConfig: () => dispatch(deps.actions.Config.loadConfig()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordContent);

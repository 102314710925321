/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
 import React , { useState } from 'react';
 import JSXParser from 'react-jsx-parser';
 import { useSelector } from 'react-redux';

 import CmsMessageContent from 'shared/general/CmsMessageContent';
 import Button from 'shared/general/Button';
 import VisitButton from 'shared/general/VisitButton';
 import LogoutButton from 'shared/general/LogoutButton';
 import EventsLink from 'shared/general/EventsLink';
 /**
 * -----------------------------------------------------------------------------
 * React Component: Account Information
 * -----------------------------------------------------------------------------
 */
 
const SystemErrorContent = (props) => {
const flowData = useSelector(state => state['Config']?.staticData?.flow?.data);
const {data} = props;

const [detailsOpen, setDetailsOpen] = useState(false);

logger.log('[SystemErrorContent] - props:%o', props);
logger.log('[SystemErrorContent] - flowData:%o', flowData);

return (
    <div className="system-error-wrapper">
        <section className="section-title-wrapper">
            <h2>Uh Oh ... Something went wrong!</h2>
        </section>
        <section className="page-grid cols-1">
            <div className="page-grid-item">
                {flowData ? (
                    <>
                        <CmsMessageContent id="general.service_error" />
						<h3 onClick={() => setDetailsOpen(!detailsOpen)}>Details <i className={`icon-triangle-${detailsOpen ? 'up' : 'down'}`} /></h3>
						<section className={`error-section ${detailsOpen ? 'open': ""}`}>
							<div className="label">Page: <span className="value">{data?.route}</span></div>
							<div className="label">Message: <span className="value">{data?.error?.message}</span></div>
							<div className="label">Component: <span className="value">{"" + data?.info?.componentStack.split("(")[0]}</span></div>
						
							{/* <div className="label">Stack</div>
							<div className="value">{data?.error?.stack}</div> */}
						</section>
                        <div className="page-actions">
                            <VisitButton type="button" />
                            {/* removing Feedback ... not used on anwa/dcp */}
                            {/* {
                                flowData?.actions?.general?.feedback 
                                ? (
                                <div className="action-link-container">
                                    <EventsLink to={flowData.actions.general.feedback} style="action-link">
                                        Feedback
                                    </EventsLink>
                                </div>
                                )
                                : null
                            } */}
                            <div className="action-link-container">
                                <EventsLink to={flowData.actions.general.signin} style="action-link">
                                    Sign In
                                </EventsLink>
                            </div>
                        </div>
                    </>
                ) : null}
            </div>
        </section>
    </div>
)
}

export default SystemErrorContent; 

export const MENU_ITEMS = {
	news: 'News',
	scoresSchedule: 'Scores & Schedule',
	draws: 'Draws',
	players: 'Players',
	videoRadio: 'Video & Radio',
	visitTickets: 'Visit & Tickets',
	about: 'About',
	foundation: 'Foundation',
	shop: 'Shop',
};

export const externalLinkRegex = /^(http|https|www)/;
//add config for year
export const dcpLinkRegex = /^(?:http?:\/\/.*(2019|2020|2021|www|ashe|secure)\.?drivechipandputt.com)?/i;
export const webviewRegex = /^\/webview\//;
export const playerRegex = /players\/overview\/([a-z0-9]*)\.html/;
export const newsRegex = /\/en_US\/news\/articles\/.*.html/;
export const contentRegex = /\/en_US\/(about_dcp|jobs|foundation|tickets|visit_and_tickets|media|learning|your_visit|foodanddrink|museum_and_tours|atoz).*/;
export const galleryRegex = /\/en_US\/news\/galleries\/.*.html/;
export const statsRegex = /\/en_US\/scores\/stats\/(\d*)\.html/;
export const videoRegex = /\/en_US\/video\/media\/(.*).html/;

export const getCleanLink = url => {
	// make dcp links relative
	url = url.replace(dcpLinkRegex, '');
	// check if still external
	let external = url.match(externalLinkRegex) && url.match(externalLinkRegex).length > 0;

	return {
		url: url,
		external: external,
	};
};

// export const getScrollPos = () => {
// 	let supportPageOffset = window.pageXOffset !== undefined;
// 	let isCSS1Compat = ((document.compatMode || '') === 'CSS1Compat');
// 	let scroll = {
// 		x: supportPageOffset ? window.pageXOffset : isCSS1Compat ? document.documentElement.scrollLeft : document.body.scrollLeft,
// 		y: supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop
// 	};

// 	return scroll;
// };

// //determine image size to render based on device application is running on
// export const getPlatformImageSize = (platform) => {
//     switch(platform) {
//         case 'mobile':
//             return 'medium';
//             break;
//         case 'tablet':
//             return 'large'
//             break;
//         case 'browser':
//             return 'xlarge'
//             break;
//         default:
//             return 'large';
//     }
// };

// //resusable data validation function for checking if right data type is returned
export const validateData = (data, dataType) => {
	//dataType map for returning true or false if first function parameter (data) matches second parameter (data type)
	let validDataMap = {
		array: {
			validate: d => {
				return Array.isArray(d);
			},
		},
		json: {
			validate: d => {
				let jsonData = typeof d !== 'string' ? JSON.stringify(d) : d;
				try {
					JSON.parse(jsonData);
					return true;
				} catch (e) {
					return false;
				}
			},
		},
		string: {
			validate: d => {
				return typeof d === 'string';
			},
		},
		int: {
			validate: d => {
				return typeof d == 'number' && d % 1 === 0;
			},
		},
		float: {
			validate: d => {
				return typeof d == 'number' && d % 1 !== 0;
			},
		},
	};
	//return validation boolean of data and its assumed data type
	return validDataMap[dataType].validate(data);
};

export const getAppLink = (url, title = '') => {
	let playerMatch = url.match(playerRegex);
	let articleMatch = url.match(newsRegex);
	let contentMatch = url.match(contentRegex);
	let galleryMatch = url.match(galleryRegex);
	let slamtrackerMatch = url.match(statsRegex);
	let videoMatch = url.match(videoRegex);

	// match player url
	if (playerMatch) {
		//logger.info('[Util] playerRegex - match:%o', playerMatch);
		return {
			url: `http://dcp/player?id=${playerMatch[1]}`,
			external: false,
		};
	}

	// article match
	else if (articleMatch) {
		return {
			url: `http://dcp/article?url=/webview${articleMatch[0]}&shareUrl=${articleMatch[0]}&title=${title}`,
			external: false,
		};
	}

	// slamtracker match
	else if (slamtrackerMatch) {
		return {
			url: `http://dcp/match?id=${slamtrackerMatch[1]}`,
			external: false,
		};
	}

	// photo match
	else if (false) {
		return {
			url: url,
			external: false,
		};
	}

	// gallery match
	else if (false) {
		return {
			url: url,
			external: false,
		};
	}

	// video match
	else if (videoMatch) {
		return {
			url: `http://dcp/video?id=${videoMatch[1]}`,
			external: false,
		};
	}

	// if a content link which can be handled by webview
	else if (contentMatch) {
		return {
			url: '/webview'.concat(url),
			external: false,
		};
	}

	// if not handled by app or content webview, link externally
	else {
		return {
			url: url,
			external: true,
		};
		// if (!url.match(webviewRegex) && !url.match(externalLinkRegex)) {
		// 	return {
		// 		url: '/webview'.concat(url),
		// 		external: false
		// 	};
		// }
		// else {
		// 	return {
		// 		url: url,
		// 		external: false
		// 	};
		// }
	}
};

export const restHeaders = () => {
	return {};
};

/**
 * get parsed querystring object
 */
export const getQuerystringValues = loc => {
	let search = loc ? loc : location.search.replace(/^\?/, '');
	//logger.log('[Util] getQuerystringValues - loc: %o: search:%o', loc, '{"' + search.replace(/&/g, '","').replace(/=/g,'":"') + '"}');

	let params = {};
	search.split('&').forEach(function(pair) {
		pair = pair.split('=');
		if (pair[1] !== undefined) {
			let key = decodeURIComponent(pair[0]);
			let val = decodeURIComponent(pair[1]);
			val = val ? val.replace(/\++/g, ' ').trim() : '';

			if (key.length === 0) {
				return;
			}
			if (params[key] === undefined) {
				params[key] = val;
			} else {
				if ('function' !== typeof params[key].push) {
					params[key] = [params[key]];
				}
				params[key].push(val);
			}
		}
	});

	return params;
};

// export const isNumeric = (data) => {
// 	return !isNaN(parseFloat(data)) && isFinite(data)
// }

// export const windowSizeVals = {
//     MOBILE_WINSIZE:0,
//     LANDSCAPE_WINSIZE:1,
//     SMTABLET_WINSIZE:2,
//     TABLET_WINSIZE:3,
//     SMDESKTOP_WINSIZE:4,
//     DESKTOP_WINSIZE:5,
//     LGDESKTOP_WINSIZE:6
// }

/*
Share link
http://dcp/share?url=<shareurl>
*** no native in apps

Player Profile
http://dcp/player?id=<playerid>
*** done

News Article
http://dcp/article?url=<shareurl>
http://dcp/news?url=<shareurl>
*** done

Single Photo
http://dcp/photo?url=<photourl>
*** not doing

Gallery​​​​​​​
http://dcp/gallery?id=<gallery content id>
*** not doing

Video​​​​​​​​​​​​​​
http://dcp/video?id=<video content id>

Slamtracker
http://dcp/match?id=<match id>

Map
http://dcp/map

Reload (for font size change)
http://dcp/fontSizeChange

Metrics
http://dcp/track?text=<metrics text to send>
http://dcp/metrics?text=<metrics text to send>
*/

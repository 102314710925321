/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';
import { Redirect } from 'react-router'; 
import { Formik, Form } from 'formik';
import { writeErrors } from 'shared/forms/elements/Utils';
import { FormSelect } from 'shared/forms/elements/FormFields';
import Button from 'shared/general/Button';
import EventsLink from 'shared/general/EventsLink';
import CmsMessageContent from 'shared/general/CmsMessageContent';


import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import LoadingIndicator from 'shared/general/LoadingIndicator';
import MeasurementUtils from 'appdir/lib/analytics';
import { forEach } from 'lodash';
import { getApplicationPhase, difference, accountChanged } from 'shared/general/Util';

 

/**
 * -----------------------------------------------------------------------------
 * Functional Component: const ApplicationForm
 * -----------------------------------------------------------------------------
 */
 const mapStateToProps = (state, props) => {
	return {
		...props,
        site: state?.Config?.dataSettings?.site,
        messageSettings: op.get(state['Config'], 'staticData.cmsMessages.data', {}),
	};
};

const mapDispatchToProps = dispatch => ({
	updateUserData: data => dispatch(deps.actions.UserData.updateUserData(data))
});

class ApplicationForm extends Component {
    constructor(props) {
		super(props);
		this.state = {
            success: false,
            // successURL: "/en_US/application/review.html",
            userData: {}
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.linkPrefix = window.webview ? '/webview' : '';
        this.loadingRef = React.createRef();
        this.initialValuesSet = false;

        // logger.log('[ApplicationForm] props:%o', props);
	}

    componentWillUnmount() {
        this.submit = false;

        this.setState({
            success: false,
            errors: {},
            userData: {}
        });
    }

    componentDidUpdate(prevProps, prevState) {
        // logger.log('[ApplicationForm] prevState:%o, state:%o', prevState, this.state);

        if(!isEqual(prevProps.userData, this.props.userData) && this.submit === true && !this.state.submitError) {
            this.submit = false;

            if (this.loadingRef.current) {
                this.loadingRef.current.classList.remove('show');
            }

            this.setState({
                success: true,
                errors: {}
            }, () => {
                MeasurementUtils.dispatchMeasurementCall('Application', {
                    action: 'Next',
                    site: this.props.site,
                    userID: this.props.userData.userID,
                });
            });
        }
    }

    setOpenClass = (which, value) => {
		this.setState({
			[which]: value,
		}, () => {
            if (value == true) {
                MeasurementUtils.dispatchMeasurementCall('Application', {
                    action: 'menuOpen',
                    menu: which,
                    site: this.props.site,
                    userID: this.props.userData.userID,
                });
            }
        });
	};

    handleSubmit = (values, formProps) => {
        logger.log('[ApplicationForm] handleSubmit - values:%o, formProps:%o', values, formProps);

        let applicationData = {...this.props.userData.application};  

        let isValid = false;  // this is for checking if all fields have a valid value
        Object.keys(values).map((v) => {
            //logger.log('[ApplicationForm] handleSubmit - map v:%o', v);
            if (!isValid) {
                isValid = values[v].value > 0
            }
            applicationData[`${v}Requested`] = values[v].value;
        });

		let applicationDiff = difference(this.props.userData.application, applicationData);
        logger.log('[ApplicationForm] handleSubmit isValid:%o,  applicationDiff:%o',isValid, applicationDiff)

        if (isValid) {
            logger.log('[ApplicationForm] handleSubmit applicationData:%o', applicationData)

            let data = {
                application: applicationData
            }

            let errors = this.state.errors;
            if (errors) {
                delete errors.submitError;
            }
            this.setState({
                errors,

            }, () => {
                this.submit = true;
                if (isEmpty(applicationDiff)) {
                    this.setState({
                        success: true
                    })
                } else {
                    this.props.updateUserData(data);
                }
                this.handleAction('Next');
            })
        } else {
            this.submit = false;
            formProps.setSubmitting(false);
            this.setState({
                errors: {submitError: this.props?.messageSettings?.['application.error_zero_tickets'] || "Please select tickets for at least 1 day."}
            })
        }
    }

    getLoadingIndicator = () => {
		return (
			<div className="fadeOverlay" ref={this.loadingRef}>
				<LoadingIndicator />
			</div>
		);
	};

    getDayField = (which, formProps) => {
        let { eventConfig } = this.props;
        let { eventDays } = this.props;
        let fieldName = `day${which}`;

        let fieldData = {
            label: eventDays[fieldName].day,
            place:eventDays[fieldName].location,
            name: fieldName,
            options: [],
            price: eventConfig[`${fieldName}Price`]
        };
        
        let max = parseInt(eventConfig[`${fieldName}MaxTickets`]);

        for(var i=0; i<max+1; i++) {
            fieldData.options.push({label: `${i}`, value: i});
        }

        return (
            <div className="application-item" key={`key-${fieldData.name}`}>
                <FormSelect
                    label={fieldData.label}
                    labeltype="fixed"
                    name={fieldData.name}
                    placeholder=""
                    options={fieldData.options}
                    required={true}
                    isClearable={true}
                    bottomLabel={fieldData.price}
                    onMenuOpen={() => {
                        logger.log('[ApplicationForm] onChange onOpen - fieldData.name: %o',fieldData);

                        this.setOpenClass(`${fieldData.name}_open`, true);
                    }}
                    onMenuClose={() => {
                        logger.log('[ApplicationForm] onChange onClose - fieldData: %o',fieldData);

                        formProps.setFieldTouched(fieldData.name, true);
                        this.setOpenClass(`${fieldData.name}_open`, false);
                    }}
                    onChange={option => {
                        logger.log('[ApplicationForm] onChange - fieldData.name: %o, option:%o',fieldData.name, option);
                        formProps.setFieldValue(fieldData.name, option);
                        blur();
                    }}
                    class={op.get(this.state, `${fieldData.name}_open`) ? 'active' : ''}
                />
                {`$${fieldData.price}.00 each`}
                {this.props.eventConfig.eventName=='anwa' ? (<p className="event-place">{fieldData.place}</p>) : null}
            </div>
        );
    }

    handleAction(which) {
		let category = op.get(this.props, 'match.params.category', false);

		MeasurementUtils.dispatchMeasurementCall('Application',
			{
				action: which,
				userID: this.props.userData.userID,
				site: this.props?.site,
			}
		);
	}

    render() {
        logger.log('[ApplicationForm] render - this:%o', this);
        let _this = this;
        let { userData } = this.props;

        if (this.state.success === true && op.get(this.props, 'flowData.actions.ticketApplication.next', null)) {
			return <Redirect push to={this.props.flowData.actions.ticketApplication.next} />;
		}

        if(op.get(this.props,'eventDays', false) && op.get(this.props, 'eventConfig', false) && op.get(this.props, 'ticketsData') && !isEmpty(this.props.userData) && !isEmpty(this.props.flowData)) {

            if(!this.initialValuesSet) {
                this.initialValuesSet = true;

                this.ApplicationValues = {};
                let ticketsSelected = Object.keys(this.props.eventDays).filter((eventDay) => {
                    return this.props?.userData?.application?.[`${eventDay}Requested`] !== 0
                })
                logger.log('[ApplicationForm] render - ticketsSelected:%o', ticketsSelected);

                Object.keys(this.props.eventDays).map((eventDay) => {
                    let initValue = '';

                    if(op.get(this.props, `userData.application.${eventDay}Requested`) >= 0 && ticketsSelected.length > 0 || 
                        (op.get(this.props, `userData.application.${eventDay}Requested`) === 0 && 
                        op.get(this.props, 'userData.application.applicationStatus') === 1)
                    ) {
                        initValue = {};
                        initValue.label = this?.props?.userData?.application?.[`${eventDay}Requested`]?.toString();
                        initValue.value = this.props.userData.application[`${eventDay}Requested`];
                    } else {
                        initValue = {};
                        //initValue.label = '';
                        initValue.label = this?.props?.userData?.application?.[`${eventDay}Requested`]?.toString();
                        initValue.value = '';
                    }

                    logger.log('[ApplicationForm] render - initValue:%o', initValue);
                    this.ApplicationValues[eventDay] = initValue;
                });
            }

            return (
                <div className="form-container application-form">
                    <div className="form-content">
                        <div className="application-container">
                            <Formik
                                initialValues={this.ApplicationValues}
                                validationSchema={_this.props.schema}
                                onSubmit={(values, formProps) => {
                                    //logger.log('[ApplicationForm] onSubmit - values:%o', values);
                                    this.handleSubmit(values, formProps)
                                }}
                                enableReinitialize={true}
                                validateOnChange={false}
                                validateOnBlur={false}
                                //validateOnMount={true}
                                key="application">

                                {formProps => {
                                    logger.log('[ApplicationForm] render - formProps:%o', formProps);
                                    return (
                                        <Form id="applicationForm">
                                            {writeErrors(formProps, this.state.errors)}

                                            {this.props.eventConfig.eventName=='masters' ? (
                                                <div className="application-content">    
                                                    <div className="application-header">
                                                        <span className="bold-text">Practice Rounds Tickets</span> (maximum 4 per day)
                                                    </div>
                                                    <div className="four-columns">
                                                        {
                                                            Object.keys(this.props.eventDays).map((eventDay, i) => {
                                                                if(this.props.eventDays[eventDay].isTourn === false) {
                                                                    return this.getDayField((i+1), formProps);
                                                                } 
                                                            })
                                                        }

                                                    </div>
                                                    <div className="application-header">
                                                        <span className="bold-text">Daily Tournament Tickets</span> (maximum 2 per day)
                                                        <br/><CmsMessageContent id="application.ineligible_parties" textOnly={true} />
                                                    </div>
                                                    <div className="four-columns">
                                                        {
                                                            Object.keys(this.props.eventDays).map((eventDay, i) => {
                                                                if(this.props.eventDays[eventDay].isTourn === true) {
                                                                    return this.getDayField((i+1), formProps);
                                                                }
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                ) : null 
                                            }

                                            {this.props.eventConfig.eventName=='anwa' ? (
                                                <div className="application-content">
                                                    <div className="four-columns">
                                                        {
                                                            Object.keys(this.props.eventDays).map((eventDay, i) => {
                                                            
                                                                    return this.getDayField((i+1), formProps);
                                                                
                                                            })
                                                        }

                                                    </div>
                                                </div>
                                                ) : null
                                            }

                                            {this.props.eventConfig.eventName=='dcp' ? (
                                                <div className="application-content">    
                                                    <div className="application-header">
                                                        <span className="bold-text">Drive, Chip & Putt Tickets</span> (maximum 4 per day)
                                                    </div>
                                                    <div className="four-columns">
                                                        {
                                                            Object.keys(this.props.eventDays).map((eventDay, i) => {
                                                                    return this.getDayField((i+1), formProps); 
                                                            })
                                                        }

                                                    </div>
                                                </div>
                                                ) : null 
                                            }     

                                            <div className="page-actions">
                                                <Button
                                                    style={this.props.numericWindowSize < 3 ? 'full' : ''}
                                                    // buttonState={(!formProps.dirty 
                                                    //     && isEmpty(formProps))
                                                    //     || !formProps.isValid 
                                                    //     || Object.keys(formProps.values).every(key => formProps.values[key].value === 0 || !formProps.values[key].value)
                                                    //         ? 'disabled' : ''}

                                                    buttonState= {formProps.isSubmitting || Object.keys(formProps.values).every(key => formProps.values[key].value === '') ? 'disabled' : ''}
                                                    onClick={() => {
                                                        //this.handleAction("Next");
                                                        //if (formProps.isValid) {
                                                        formProps.submitForm();
                                                        //}
                                                    }}
                                                    //to={this.props.flowData.actions.ticketApplication.next}
                                                    buttonText="Next" />
                                                                
                                                <div className="action-link-container">
                                                    <EventsLink style="action-link" onClick={()=> this.handleAction("Cancel")} to={this.props.flowData.actions.ticketApplication.cancel}>
                                                        Cancel
                                                    </EventsLink>
                                                </div>
                                            </div>
                                        </Form>
                                    )
                                }}

                            </Formik>
                        </div>
                    </div>
                    {this.getLoadingIndicator()}
                </div>
            );
        } else {
            return null;
        }
    }
};
 
export default connect(mapStateToProps, mapDispatchToProps)(ApplicationForm);
 
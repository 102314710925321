/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import Template from 'components/Template';
import MeasurementUtils from 'appdir/lib/analytics';
import ContentHero from 'shared/cms/ContentHero';
import ChangePasswordForm from 'shared/forms/ChangePasswordForm';
import CmsMessageContent from 'shared/general/CmsMessageContent';
import Button from 'shared/general/Button';
import isEmpty from 'lodash/isEmpty';
import op from 'object-path';
import LoadingIndicator from 'shared/general/LoadingIndicator';

/**
 * -----------------------------------------------------------------------------
 * React Component: ChangePasswordContent
 * -----------------------------------------------------------------------------
 */
export default class ChangePasswordContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			submitSuccess: false,
			mounted: false,
		};
	}

	componentWillUnmount() {
		// logger.log('[ChangePasswordContent] componentWillUnmount');

		this.setState = {
			submitSuccess: false,
		};
	}

	componentDidMount() {
		// logger.log('[ChangePasswordContent] - componentDidMount this:%o', this);
		if (this.props.siteConfigStatus !== 'loaded') {
			this.props.loadConfig();
		} else {
			this.setState({
				mounted: true,
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.siteConfigStatus !== this.props.siteConfigStatus && this.props.siteConfigStatus == 'loaded') {
			this.setState({
				mounted: true,
			});
		}

		if (this.state.mounted !== prevState.mounted && this.state.mounted == true) {
			MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
				pageTitle: 'ChangePassword',
				site: this.props.site,
			});
		}
	}

	changePasswordSubmit = val => {
		// logger.log('[ChangePasswordContent] changePasswordSubmit - val:%o', val);
		this.setState({
			submitSuccess: val,
		});
	};

	render() {
		logger.log('[ChangePasswordContent] render this:%o', this);

		return this.props.siteConfigStatus == 'loaded' &&
			op.get(this.props, 'EventsWindow') &&
			!isEmpty(this.props.ticketsData) ? (
			<Template>
				<section className="page-content">
					<section className="page-title">
						<h1>
							<CmsMessageContent id="general.page_header_title" textOnly={true} />
						</h1>
					</section>
					<section className="hero">
						<ContentHero />
					</section>
					{this.state.submitSuccess === true ? (
						<div className="form-container">
							<div className="form-content">
								<div className="form-title">Change Password</div>

								<CmsMessageContent id="login.change.confirm" />
								<div className="page-actions">
									<Button
										style={this.props.EventsWindow.numericWindowSize < 2 ? 'full' : ''}
										to={this.props.flowData.actions.changePassword.signin}
										buttonText="Sign In"
									/>
								</div>
							</div>
						</div>
					) : (
						<ChangePasswordForm
							ticketsData={this.props.ticketsData}
							eventsWindow={this.props.EventsWindow}
							flowData={this.props.flowData}
							onSubmit={this.changePasswordSubmit}
						/>
					)}
				</section>
			</Template>
		) : (
			<Template>
				<section className="page-content">
					<section className="page-title">
						<h1>
							<CmsMessageContent id="general.page_header_title" textOnly={true} />
						</h1>
					</section>
					<section className="hero">
						<ContentHero />
					</section>
					<div className="form-container">
						<div className="form-content">
							<div className="form-title">Change Password</div>
							<div className="fadeOverlay" ref={this.loadingRef}>
								<LoadingIndicator />
							</div>
						</div>
					</div>
				</section>
			</Template>
		);
	}
}

ChangePasswordContent.defaultProps = {};

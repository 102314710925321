import React, { Component, Fragment } from 'react';
import { Plugins } from 'dotix/components/Plugable';
import ErrorBoundary from 'shared/general/ErrorBoundary';

import JSXParser from 'react-jsx-parser';

const TroubleShootCMS = ({ components, bindings, jsx }) => {
	// logger.log('[ContentPage] - TroubleShootCMS: ', {
	//     components,
	//     bindings,
	//     jsx,
	// });
	return null;
};

export default class ContentInfo extends Component {
	render() {
		const { bindings, jsx, contentZone } = this.props;

		if (!jsx) return null;

		return (
			<Plugins zone={contentZone} passThrough={true}>
				<ErrorBoundary message="Unable to render content.">
					<JSXParser
						bindings={bindings}
						jsx={jsx}
						renderInWrapper={false}
						showWarnings={true}
					/>
				</ErrorBoundary>
				<TroubleShootCMS bindings={bindings} jsx={jsx} />
			</Plugins>
		);
	}
}

ContentInfo.defaultProps = {
	jsx: false,
	contentZone: 'jsx-content-page',
};

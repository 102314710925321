/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
 import React, { Component } from 'react';
 import Template from 'components/Template';
 import MeasurementUtils from 'appdir/lib/analytics';
 import ContentHero from 'shared/cms/ContentHero';
 import CmsMessageContent from 'shared/general/CmsMessageContent';
 import AccountProfileForm from 'shared/forms/AccountProfileForm';
 import isEmpty from 'lodash/isEmpty';
 import op from 'object-path';
 import LoadingIndicator from 'shared/general/LoadingIndicator';
 import { getApplicationPhase, isAddressEditable, fetchDataFromPaths} from 'shared/general/Util';
import { isPaidWinner } from '../../general/Util';
 
 /**
  * -----------------------------------------------------------------------------
  * React Component: AccountProfileContent
  * -----------------------------------------------------------------------------
  */
 
 export default class AccountProfileContent extends Component {
	 constructor(props) {
		 super(props);
		 this.state = {
			 errors: {},
			 countryList: [],
			 prefixList: [],
			 suffixList: [],
			 suiteNameList: [],
			 mounted: false,
		 };
 
		 this.countriesLoaded = false;
		 this.formOptionsLoaded = false;
		 this.otherAppsLoaded = false;
 
		 MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			 pageTitle: 'AccountProfile',
			 site: this.props.userData.eventName,
		 });
	 }
 
	 componentDidMount() {
		 logger.log('[AccountProfileContent] - componentDidMount this.props:%o', this.props);
 
		 this.props.loadConfig();
	 }
 
	 componentDidUpdate(prevProps, prevState) {
		 logger.log('[AccountProfileContent] - componentDidUpdate this:%o', this);
 
		 if (this.props.countries.status == 'loaded' && !this.countriesLoaded) {
			 this.countriesLoaded = true;
			 this.getCountryList();
		 }
 
		 if (this.props.formOptions.status == 'loaded' && !isEmpty(this.props.formOptions) && !this.formOptionsLoaded) {
			 this.formOptionsLoaded = true;
			 this.getFormOptions();
		 }

		 if (!this.otherAppsLoaded && this?.props?.ticketsData?.applicationUrlList) {
			this.otherAppsLoaded = true;
			fetchDataFromPaths(this?.props?.ticketsData?.applicationUrlList, this?.props?.userData?.token)
				.then((result) => {
						this.setState({
							applicationData: result,
							editable: isAddressEditable(this?.props?.userData, this?.props?.messageSettings, result),
							paidWinner: isPaidWinner(this?.props?.userData, result)
						})
				})
				.catch((error) => {

				})
		}
	 }
 
	 getFormOptions() {
		 let _this = this;
 
		 let prefixObj = this.props.formOptions.data.prefixes.find(function(p) {
			 return p.label === _this.props.userData.prefix;
		 });
 
		 let suffixObj = this.props.formOptions.data.suffixes.find(function(s) {
			 return s.label === _this.props.userData.suffix;
		 });
 
		 let suiteNameObj = this.props.formOptions.data.suiteNames.find(function(n) {
			 //return n.label === _this.props.userData.suiteName;
			 return n.label === _this.props.userData.suiteName || n.value === _this.props.userData.suiteName;
		 });
 
		 this.setState({
			 prefixList: this.props.formOptions.data.prefixes,
			 currentPrefix: prefixObj && prefixObj.label != '' ? prefixObj : '',
 
			 suffixList: this.props.formOptions.data.suffixes,
			 currentSuffix: suffixObj && suffixObj.label != '' ? suffixObj : '',
 
			 suiteNameList: this.props.formOptions.data.suiteNames,
			 currentSuiteName: suiteNameObj && suiteNameObj.label != '' ? suiteNameObj : '',
		 });
	 }
 
	 getCountryList() {
		 let _this = this;
		 let countryObj = this.props.countries.data.countries.find(function(c) {
			 return c.value === _this.props.userData.country;
		 });
 
		 if (!countryObj) {
			 countryObj = this.props.countries.data.countries.find(function(c) {
				 return c.value === 'US';
			 });
		 }
 
		 this.setState({
			 countryList: this.props.countries.data.countries,
			 currentCountry: countryObj ? countryObj : '',
		 });
	 }
 
	 setOpenClass = (which, value) => {
		 this.setState(
			 {
				 [which]: value,
			 },
			 () => {
				 if (value == true) {
					 MeasurementUtils.dispatchMeasurementCall('AccountProfile', {
						 action: 'menuOpen',
						 menu: which,
						 site: this.props.site,
						 userID: this.props.tempUserData.userID,
					 });
				 }
			 }
		 );
	 };
 
	 render() {
		 logger.log('[AccountProfileContent] render this:%o,',this);

		 if (
			 this.props.siteConfigStatus == 'loaded' &&
			 !isEmpty(this.props.ticketsData) &&
			 this.state.prefixList.length > 0 &&
			 this.state.suffixList.length > 0 &&
			 this.state.suiteNameList.length > 0 &&
			 this.state.countryList.length > 0
		 ) {
			 let prefixProps = {
				 initialValue: this.state.currentPrefix,
				 options: this.state.prefixList,
				 class: this.state.prefix_open ? 'active' : '',
			 };
 
			 let suffixProps = {
				 initialValue: this.state.currentSuffix,
				 options: this.state.suffixList,
				 class: this.state.suffix_open ? 'active' : '',
			 };
 
			 let countryProps = {
				 initialValue: this.state.currentCountry,
				 options: this.state.countryList,
				 class: this.state.country_open ? 'active' : '',
			 };
 
			 let suiteNameProps = {
				 initialValue: this.state.currentSuiteName,
				 options: this.state.suiteNameList,
				 class: this.state.suiteName_open ? 'active' : '',
			 };
 
			 let addressValidationStatusId =
				 op.get(this.props, 'Router.state.addressValidationStatusId') ||
				 op.get(this.props, 'userData.addressValidationStatusId');
 
			 let applicationPhase = getApplicationPhase(this.props?.userData?.application, this.props.eventConfig);
 
			 return (
				 <Template>
					 <section className="page-content">
						 <section className="page-title">
							 <h1><CmsMessageContent id="general.page_header_title" textOnly={true} /></h1>
						 </section>
						 <section className="hero">
							 <ContentHero />
						 </section>
						 <section className="section-title-wrapper">
							 <h2>Account Information</h2>
						 </section>
						 <section className="page-grid cols-1">
							 <div className="page-grid-item">
								 {!this.props.tempUserData.addressHash && this.props.userData.eventName !== 'psb' ? (
									 <CmsMessageContent id="account.account_entry_instructions" />
								 ) : (
									 <>
										{this?.props?.userData?.application?.isWinner ? (
											// If changeOfAddressEnabled and user is a winner somewhere.  
											<>
											{this.state.editable ? (
												 this.props?.eventConfig?.allowPayment || (!this.props?.eventConfig?.allowPayment && this.state.paidWinner)
												 ? <CmsMessageContent id="account.coa_edit_instructions" />
												 : <CmsMessageContent id="account.account_edit_instructions" />
											) :
												// this messages says users account is on hold
												this?.props?.userData?.addressValidationStatusId == 12 
													? <CmsMessageContent id="account.coa_edit_instructions_12" />
													: <CmsMessageContent id="account.coa_edit_disabled_instructions" />
											}
											</>
										) :
											<CmsMessageContent id="account.account_edit_instructions" />
										}  
									 </>
								 )}
 
								 {op.get(this.props, 'Router.state.errors.response', false) ? (
									 <div className="return-error">
										{this?.props?.messageSettings?.[this.props.Router.state.errors.response] ? (
										 	<CmsMessageContent id={this.props.Router.state.errors.response} data={[this?.props?.Router?.state?.errors?.errorCode ? this?.props?.Router?.state?.errors?.errorCode : '']} />
										) : (
											<CmsMessageContent id={`general.service_error`} data={['PR1']} />
										)}
									 </div>
								 ) : null}
 
								 {addressValidationStatusId &&
								 this.props.invalidStatusIds[addressValidationStatusId] &&
								 !op.get(this.props, 'Router.state.errors.response', false) &&
								 op.get(this.props, 'messageSettings') ? (
									 <div className="return-error">
										 <CmsMessageContent id="account.validation.failure_summary" />
									 </div>
								 ) : null}
 
								 <AccountProfileForm
									 ticketsData={this.props.ticketsData}
									 eventConfig={this.props.eventConfig}
									 userData={this.props.userData}
									 flowData={this.props.flowData}
									 prefixProps={prefixProps}
									 suffixProps={suffixProps}
									 countryProps={countryProps}
									 suiteNameProps={suiteNameProps}
									 onMenuOpen={this.setOpenClass}
									 errors={op.get(this.props, 'Router.state.errors.response', false)}
								 />
							 </div>
						 </section>
					 </section>
				 </Template>
			 );
		 } else {
			 return (
				 <Template>
					 <section className="page-content">
						 <section className="page-title">
						 	<h1><CmsMessageContent id="general.page_header_title" textOnly={true} /></h1>
						 </section>
						 <section className="hero">
							 <ContentHero />
						 </section>
						 <section className="section-title-wrapper">
							 <h2>Account Information</h2>
						 </section>
						 <div className="fadeOverlay" ref={this.loadingRef}>
							 <LoadingIndicator />
						 </div>
					 </section>
				 </Template>
			 );
		 }
	 }
 }
 
 AccountProfileContent.defaultProps = {};
 
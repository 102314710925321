import deps from 'dependencies';

export default (state = {}, action) => {
    let newState;

    switch (action.type) {
        case deps.actionTypes.MASTERSIMAGE_MOUNT:
            newState = {
                ...state,
                ...action.data,
            };
            // logger.log(
            //     '[MastersImage] actionTypes.MASTERSIMAGE_MOUNT - newState:%o',
            //     newState
            // );
            return newState;
            break;
        default:
            return state;
    }
};

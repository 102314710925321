import deps from 'dependencies';

export default {
    mount: data => (dispatch, getState, store) => {
        logger.log('[StubBoxCms] actions.mount - data:%o', data);

        return deps.services.Config.ensureConfigurationLoaded(dispatch, store)
        .then(Config => {
            let stubPath = Config.cmsData.stubPages;

            deps.services.StubBoxCms.fetch(stubPath).then( results => {
                logger.log('[StubBoxCms] fetch - results:%', results);
                
                dispatch({
                    type: deps.actionTypes.STUBBOXCMS_MOUNT, 
                    data: results
                });
            });

        });
         
    }
};

/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import CancelRegistrationContent from './CancelRegistrationContent';
import { connect } from 'react-redux';
import deps from 'dependencies';
import op from 'object-path';

/**
 * -----------------------------------------------------------------------------
 * React Component: CancelRegistrationContent
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state.CancelRegistrationContent,
		Router: state['Router'],
		siteConfigStatus: state['Config'].status,
		eventConfig: op.get(state['Config'], 'eventConfig.data', {}),
		userData: op.get(state['UserData'], 'userData', {}),
		tempUserData: op.get(state['UserData'], 'tempUserData'),
		pageTitle: state?.Config?.otherData?.pageTitle,
		flowData: op.get(state['Config'], 'staticData.flow.data', {}),
		flowStatus: op.get(state['Config'], 'staticData.flow.status'),
		site: state?.Config?.dataSettings?.site,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	loadConfig: () => dispatch(deps.actions.Config.loadConfig()),
	updateUserData: data => dispatch(deps.actions.UserData.updateUserData(data)),
	logout: () => dispatch(deps.actions.UserData.logoutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CancelRegistrationContent);
